import { gql, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { T8EvaluationFragment } from '../../../graphql/types';
import { ClientTable } from './components/ClientTable';
import { T8AnswersTable } from './components/T8/T8AnswersTable';
import { T8AnswersTable2 } from './components/T8/T8AnswersTable2';
import { T8ResultsTable } from './components/T8/T8ResultsTable';


export const t8EvaluationFragment = gql`
  fragment T8EvaluationFragment on T8Evaluation {
    versionA {
      totalRoughScore
      degree
      percentile
    }
    versionB {
      totalRoughScore
      degree
    }
  }
`;

const answersQuery = gql<T8AnswersResultsGQL>`
  query T8AnswersResults($token: String!) {
    t8Diagnostics(token: $token) {
      id: originalToken
      v1 {
        questionGroups {
          table1 {
            questions {
              id
              task
            }
          }
        }
      }
      v2 {
        questionGroups {
          table1 {
            questions {
              id
              task
            }
          }
          table2 {
            questions {
              id
              task
            }
          }
        }
      }
      answers {
        v1 {
          answersTable1 {
            id
            radioValue
            answer
          }
        }
        v2 {
          answersTable1 {
            id
            radioValue
            answer
          }
          answersTable2 {
            id
            match
            notMatch
          }
        }
      }
    }
  }
`;

interface Props {
  token: string;
  client: {
    fullName: string;
    dateOfBirth: Date;
    gender: string;
    testingDate: Date;
  };
  data: T8EvaluationFragment;
}

export function T8Result({ client, data, token }: Props) {
  const { data: answersData } = useQuery(answersQuery, { variables: { token } });

  if (data == null) return <Loading />;

  const v1Answers = answersData?.t8Diagnostics?.answers?.v1;
  const v2Answers = answersData?.t8Diagnostics?.answers?.v2;

  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Klient</Typography>
      </Box>
      <ClientTable data={client} />
      <Box mt={2}>
        <Typography variant="h4">Výsledky</Typography>
      </Box>

      {data.versionB == null ? (
        <>
          <T8AnswersTable
            data={v1Answers?.answersTable1 ?? []}
            questions={answersData?.t8Diagnostics?.v1.questionGroups.table1.questions ?? []}
          />
          <T8ResultsTable
            version="v1"
            degree={data.versionA.degree}
            percentile={data.versionA.percentile}
            score={data.versionA.totalRoughScore}
            time={undefined}
          />
        </>
      ) : (
        <>
          <T8AnswersTable
            data={v2Answers?.answersTable1 ?? []}
            questions={answersData?.t8Diagnostics?.v2.questionGroups.table1.questions ?? []}
          />
          <T8AnswersTable2
            data={v2Answers?.answersTable2 ?? []}
            questions={answersData?.t8Diagnostics?.v2.questionGroups.table2.questions ?? []}
          />
          <Box mt={2} mb={2}>
            <Typography variant="h6">Tabuľka 1</Typography>
          </Box>
          <T8ResultsTable
            version="v1"
            degree={data.versionA.degree}
            percentile={data.versionA.percentile}
            score={data.versionA.totalRoughScore}
            time={undefined}
          />
          <Box mt={2} mb={2}>
            <Typography variant="h6">Tabuľka 2</Typography>
          </Box>
          <T8ResultsTable
            version="v2"
            degree={data.versionB.degree}
            percentile={undefined}
            score={data.versionB.totalRoughScore}
            time={undefined}
          />
        </>
      )}
    </>
  );
}
