import { SubmitButton } from '@everlutionsk/ui-formik';
import { useNavigate } from '@everlutionsk/ui-router';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';

export function FormButton({
  backTo,
  label,
  disabled
}: {
  backTo?: any;
  label?: string;
  disabled?: boolean;
}) {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <SubmitButton
        style={{
          width: '200px'
        }}
        color="secondary"
        disabled={disabled}
        variant="contained"
      >
        {label ? label : 'Uložiť zmeny'}
      </SubmitButton>
      {backTo && (
        <>
          <Box my={1}>
            <Typography variant="caption">alebo</Typography>
          </Box>
          <Button
            style={{
              width: '150px'
            }}
            color="secondary"
            variant="outlined"
            onClick={() => navigate(backTo)}
          >
            Späť
          </Button>
        </>
      )}
    </Box>
  );
}
