import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { routingPatterns } from '../../../Routes';
import { AllForms } from './AllForms';
import { IndividualForm } from './IndividualForm';
import { groupTestCodes, remoteTestCodes } from '../../../helpers';
import { RemoteForm } from './RemoteForm';
import { GroupForm } from './GroupForm';
import { Forbidden } from '../../../components/AuthenticatedRoute';

export function PrepareDiagnostic() {
  const location = useLocation();
  const currentTest = location.pathname.split('/')[2];

  return (
    <>
      <Routes>
        <Route path={routingPatterns.diagnostic.prepare.all} element={<AllForms />} />
        <Route path={routingPatterns.diagnostic.prepare.individual} element={<IndividualForm />} />
        <Route
          path={routingPatterns.diagnostic.prepare.remote}
          element={remoteTestCodes.includes(currentTest) ? <RemoteForm /> : <Forbidden />}
        />
        <Route
          path={routingPatterns.diagnostic.prepare.group.pattern}
          element={groupTestCodes.includes(currentTest) ? <GroupForm /> : <Forbidden />}
        />
      </Routes>
    </>
  );
}
