import { Box, styled } from '@mui/material';
import React from 'react';
import logos from './logos.png';

export function FooterContent() {
  return (
    <Wrapper>
      <img style={{ width: '700px', margin: '70px auto' }} src={logos} alt="" />
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  @media print {
    display: none;
  }
`;
