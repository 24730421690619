import { gql } from '@apollo/client';
import { LocaleDate } from '@everlutionsk/ui';
import { Table } from '../../components/Table';
import { useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { useParams } from '@everlutionsk/ui-router';
import { Box, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import { ContentHeader } from '../../components/ContentHeader';
import { TableArrowForward } from '../../components/TableArrowForward';
import { TestingStatus } from '../../graphql/types';
import { routing } from '../../Routes';
import { ToStudentOneNameLink } from '../Common/ToStudentOneNameLink';
import { getAge, prettifyGender } from './utils';
import { useIdentity } from '../../components/hooks/useIdentity';
import { SearchRounded } from '@mui/icons-material';

export function DiagnosticResults() {
  const identity = useIdentity();
  if (identity == null) throw Error('Access Denied! Missing valid identity.');

  const { code } = useParams(['code']);
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const { data, pagination, error } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      input: {
        pagination: { limit, offset },
        code,
        term: searchTerm,
        status: TestingStatus.readonly
      }
    }),
    fetchPolicy: 'no-cache'
  });

  return (
    <>
      <ContentHeader
        title="Výsledky testu"
        breadcrumbs={[
          { title: 'Domov' },
          { title: 'Testy', path: routing.diagnostics.list },
          { title: `Test ${code}`, path: routing.diagnostic.prepare(code) },
          { title: 'Výsledky testu' }
        ]}
      />
      <Box mb={3} display="flex" justifyContent="flex-end">
        <Box width="50%">
          <TextField
            size="small"
            name="search"
            label="Hľadať"
            placeholder="Vyhľadávanie"
            variant="outlined"
            onChange={e => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRounded />
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Box>
      <Table
        pagination={pagination}
        items={data?.testingMany.items}
        error={error}
        columns={[
          {
            label: 'Dátum',
            render: item => <LocaleDate date={item.createdAt} variant="dateTime" />
          },
          {
            label: 'Meno žiaka',
            render: item => ToStudentOneNameLink(item.student)
          },
          {
            label: 'Vek',
            render: item => getAge(item.student.dateOfBirth)
          },
          {
            label: 'Pohlavie',
            render: item =>
              item.student && item.student.gender ? prettifyGender(item.student.gender) : '-'
          },
          {
            label: 'Škola',
            render: item => item.student.school
          },
          {
            label: 'Diagnostika',
            render: item => item.student.diagnosis?.content
          },
          {
            label: 'Odborník',
            render: item => item.student.owner?.fullName
          },
          {
            label: 'Výsledky',

            render: item => {
              return (
                item.user.id === identity.id && (
                  <TableArrowForward
                    to={routing.diagnostic.result(item.code, item.originalToken)}
                  />
                )
              );
            }
          }
        ]}
      />
    </>
  );
}

export const query = gql<DiagnosticResultsQueryGQL>`
  query DiagnosticResultsQuery($input: TestingFilterInput!) {
    testingMany(input: $input) {
      total
      items {
        id
        code
        originalToken
        student {
          id
          firstName
          lastName
          fullName
          dateOfBirth
          gender
          school
          diagnosis {
            content
          }
          owner {
            id
            fullName
          }
        }
        user {
          id
        }
        createdAt
      }
    }
  }
`;
