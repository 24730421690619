import {
  ConditionalForm,
  createFormSpec,
  customRadioValue,
  Fields,
  RadioField,
  SelectField,
  TextField
} from '@everlutionsk/ui-formik';
import { Box, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { FormButton } from '../../components/FormButton';

interface Props {
  onSubmit: ({
    lastName,
    firstName,
    gender,
    birthIdentificationNumber,
    dateOfBirth,
    school,
    line1,
    city,
    postCode,
    fullName,
    email,
    phoneNumber,
    language
  }) => void;
  returnUrl: string;
}

export function AddStudentForm({ onSubmit, returnUrl }: Props) {
  const formSpec = useMemo(() => {
    return createFormSpec({
      firstName: yup.string().nullable().required(`Meno žiaka je povinný údaj`),
      language: yup.string().nullable().required(`Materinský jazyk je povinný údaj`),
      lastName: yup.string().nullable().required(`Priezvisko žiaka je povinný údaj`),
      gender: yup.string().required('Vyberte pohlavie klienta'),
      dateOfBirth: yup.string().nullable().required(`Dátum narodenia je povinný údaj`),
      birthIdentificationNumber: yup
        .string()
        .nullable()
        .required(`Rodné číslo žiaka je povinný údaj`),
      school: yup.string().nullable().required(`Škola, ktorú žiak navštevuje je povinný údaj`),
      line1: yup.string().nullable().required(`Ulica je povinný údaj`),
      city: yup.string().nullable().required(`Mesto je povinný údaj`),
      postCode: yup.string().nullable().required(`PSČ je povinný údaj`),
      fullName: yup
        .string()
        .nullable()
        .required(`Meno a priezvisko zákonného zástupcu je povinný údaj`),
      phoneNumber: yup.string().required(`Telefóne číslo zákonného zástupcu je povinný údaj`),
      email: yup
        .string()
        .nullable()
        .required(`Email zákonného zástupcu je povinný údaj`)
        .email('Formát emailu je nesprávny')
    });
  }, []);

  return (
    <Formik
      {...formSpec({
        firstName: '',
        lastName: '',
        gender: undefined,
        birthIdentificationNumber: '',
        dateOfBirth: null,
        school: '',
        line1: '',
        city: '',
        postCode: '',
        fullName: '',
        email: '',
        phoneNumber: '',
        language: ''
      })}
      onSubmit={({
        lastName,
        firstName,
        gender,
        birthIdentificationNumber,
        dateOfBirth,
        school,
        line1,
        city,
        postCode,
        fullName,
        email,
        phoneNumber,
        language
      }) =>
        onSubmit({
          lastName,
          firstName,
          gender,
          birthIdentificationNumber,
          dateOfBirth,
          school,
          line1,
          city,
          postCode,
          fullName,
          email,
          phoneNumber,
          language
        })
      }
    >
      <ConditionalForm>
        <Fields>
          <Box my={3}>
            <Typography variant="h5">Základné informácie</Typography>
          </Box>
          <TextField name="firstName" label="Meno*" />
          <TextField name="lastName" label="Priezvisko*" />
          <SelectField name="gender" label="Pohlavie*" options={genderOptions} />
          <TextField name="dateOfBirth" label="Dátum narodenia*" type="date" />
          <TextField name="birthIdentificationNumber" label="Rodné číslo*" />
          <TextField name="school" label="Škola*" />
          <RadioField
            options={[
              { label: 'slovenský', value: 'slovenský' },
              { label: 'maďarský', value: 'maďarský' },
              { label: 'rómsky', value: 'rómsky' },
              {
                label: 'iné',
                value: customRadioValue({
                  size: 'small',
                  type: 'text'
                })
              }
            ]}
            name="language"
            label="Materinský jazyk*"
          />

          <Box my={3}>
            <Typography variant="h5">Adresa trvalého pobytu</Typography>
          </Box>

          <TextField name="line1" label="Ulica + čislo*" />
          <TextField name="city" label="Mesto*" />
          <TextField name="postCode" label="PSČ*" />

          <Box my={3}>
            <Typography variant="h5">Informácie o zakonnom zástupcovi</Typography>
          </Box>

          <TextField name="fullName" label="Meno a priezvisko*" />
          <TextField name="phoneNumber" label="Telefón*" />
          <TextField name="email" label="Email*" />
        </Fields>

        <Box my={3}>
          <FormButton backTo={returnUrl} />
        </Box>
      </ConditionalForm>
    </Formik>
  );
}

const genderOptions = [
  { value: 'male', label: 'Mužské' },
  { value: 'female', label: 'Ženské' }
];
