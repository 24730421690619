import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { intervalToDuration } from 'date-fns';
import React from 'react';
import { T202EvaluationFragment } from '../../../graphql/types';
import { T202ResultsTable1 } from './components/T202/T202ResultsTable1';
import { T202ResultsTable2 } from './components/T202/T202ResultsTable2';
import { T202ResultsTable3 } from './components/T202/T202ResultsTable3';
import { T202ResultsTable5 } from './components/T202/T202ResultsTable5';
import { T202ResultsTable4 } from './components/T202/T202ResultTable4';
import { TwoColumnClientTable } from './components/TwoColumnClientTable';

export const t202EvaluationFragment = gql`
  fragment T202EvaluationFragment on T202Evaluation {
    articleId
    firstMinuteWord
    mistakesFirstMinute
    scoreFirstMinute
    mistakesPercentageFirstMinute
    secondMinuteWord
    mistakesSecondMinute
    scoreSecondMinute
    mistakesPercentageSecondMinute
    thirdMinuteWord
    mistakesThirdMinute
    scoreThirdMinute
    mistakesPercentageThirdMinute
    mistakesTwoLetterWords
    mistakesThreeLetterWords
    sten
    evaluationMistakesFirstMinute
    evaluationMistakesPercentageFirstMin
    readingLocalizationOne
    readingLocalizationTwo
    readingLocalizationThree
    readingLocalizationFour
    readingLocalizationFive
    readingLocalizationSix
    readingLocalizationSeven
    readingReproduction
    readingReproductionScale
    readingSense
    wayOfReading
    t202duration: duration
    durationEntry
    errorShapes
  }
`;

interface Props {
  client: {
    fullName: string;
    dateOfBirth: Date;
    gender: string;
    testingDate: Date;
    details: {
      ratingSlovakLanguage?: number;
      ratingMaths?: number;
      ratingForeignLanguage?: number;
      motherEducation?: number;
      fatherEducation?: number;
      postponement?: number;
      grade?: number;
      repeatedGrade?: number;
      readingEvaluation?: number;
    };
  };
  data: T202EvaluationFragment;
}

export function T202Result({ client, data }: Props) {
  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Klient</Typography>
      </Box>
      <TwoColumnClientTable
        data={{ ...client, details: { ...client.details, articleId: data.articleId } }}
      />
      <Box my={2}>
        <Typography variant="h4">Výsledky</Typography>
      </Box>
      <T202ResultsTable1 data={data} />
      <T202ResultsTable2 data={data} />
      <T202ResultsTable5
        label={'Dĺžka trvania testu'}
        value={prettifyDuration(data.t202duration)}
      />

      <T202ResultsTable3 data={data} />
      <T202ResultsTable4 data={data} />

      <T202ResultsTable5 label={'Tvary chýb'} value={prettifyErrorShapes(data.errorShapes)} />
      <T202ResultsTable5 label={'Zmysel chýb'} value={prettifyReadingSense(data.readingSense)} />
    </>
  );
}

function prettifyDuration(duration?: number | null): string {
  if (duration == null) return 'Minút: 0\nSekúnd: 0';
  const { minutes, seconds } = intervalToDuration({ start: 0, end: duration * 1000 });
  return `Minút: ${minutes}\nSekúnd: ${seconds}`;
}

function prettifyErrorShapes(values?: number[] | null): string {
  const finalErrors: string[] = [];

  if (values == null) return '';

  if (0 in values) finalErrors.push('Zámeny tvar. podob. písmen');
  if (1 in values) finalErrors.push('Zámeny zvuk. podob. písmen');
  if (2 in values) finalErrors.push('Zámeny oboch podob.');
  if (3 in values) finalErrors.push('Nepodobné písmená');
  if (4 in values) finalErrors.push('Inverzia písmen');
  if (5 in values) finalErrors.push('Inverzia slabík');
  if (6 in values) finalErrors.push('Vynechanie písmen');
  if (7 in values) finalErrors.push('Vynechanie slabík');
  if (8 in values) finalErrors.push('Vynechanie slov');
  if (9 in values) finalErrors.push('Pridanie písmena');
  if (10 in values) finalErrors.push('Pridanie slabiky');
  if (11 in values) finalErrors.push('Pridanie slova');

  return finalErrors.join(', ');
}

function prettifyReadingSense(value?: number | null): string {
  if (value === 0) return 'Nešpecifikovaný';
  if (value === 1) return 'Odvodené';
  if (value === 2) return 'Blízke';
  if (value === 3) return 'Vzdialené';
  if (value === 4) return 'Nezmyselné';

  return '';
}
