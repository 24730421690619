import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props {
  label: string;
  data: Array<{ label: string; value: string | number }>;
}

const borderStyle = '1px solid #d6d6d6';

export function T239ResultsTable({ label, data }: Props) {
  return (
    <Box mt={2}>
      <Typography variant="h6">{label}</Typography>
      <Box display="flex" flexDirection="column" width="100%" boxShadow={2}>
        {data.map(({ label, value }, index) => (
          <Row key={index} label={label} value={value} />
        ))}
      </Box>
    </Box>
  );
}

function Row({ label, value }: { label: string; value: string | number }) {
  return (
    <Box>
      <Box display="flex" sx={{ borderBottom: borderStyle }}>
        <Box
          width="30%"
          textAlign="left"
          sx={{
            bgcolor: '#f8f8f8'
          }}
          p={1}
        >
          <Typography variant="body2">{label}</Typography>
        </Box>
        <Box width="70%" textAlign="right" sx={{ bgcolor: '#ffffff' }} p={1}>
          <Typography variant="body2"> {value}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
