import { Table } from '@app/admin/src/components/Table';
import { Box, styled } from '@mui/material';
import React from 'react';

interface Props {
  questions: Array<{
    id: string;
    task: string;
  }>;
  data: Array<{
    id: string;
    radioValue: number;
  }>;
}

export function T216AnswersTable({ data, questions }: Props) {
  return (
    <TableWrapper mt={2}>
      <Table
        items={data}
        columns={[
          {
            label: 'Otázka',
            render: ({ id }) => {
              const question = questions.find(item => item.id === id);

              return `${id}. ${question?.task}`;
            }
          },
          {
            label: 'Odpoveď',
            render: ({ radioValue }) => (radioValue === 1 ? 'Áno' : 'Nie')
          }
        ]}
      />
    </TableWrapper>
  );
}

const TableWrapper = styled(Box)`
  thead {
    background-color: #f8f8f8;
  }
`;
