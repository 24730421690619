import { Route, Routes } from '@everlutionsk/ui-router';
import React from 'react';
import { routingPatterns } from '../../Routes';
import { ResultMany } from './ResultMany';
import { ResultOne } from './ResultOne';


export function TestResults() {
  return (
    <>
      <Routes>
        <Route path={routingPatterns.testResults.list} element={<ResultMany />} />
        <Route path={routingPatterns.testResults.detail} element={<ResultOne />} />
      </Routes>
    </>
  );
}
