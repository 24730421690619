import React from 'react';
import { EditPosition } from './EditPosition';
import { StandardisationSettings } from './StandardisationSettings';
import { Box, Card, Typography } from '@mui/material';
import { PreventionSettings } from './PreventionSettings';

export function Accesses({ userId }: { userId: string }) {
  return (
    <Card>
      <Box p={3}>
        <Typography variant="h3">Portál:</Typography>
        <Card sx={{ padding: 3 }}>
          <EditPosition userId={userId} />
        </Card>

        <Typography mt={3} variant="h3">
          Štandardizácia:
        </Typography>
        <Card sx={{ padding: 3 }}>
          <StandardisationSettings />
        </Card>

        <Typography mt={3} variant="h3">
          Prevencia:
        </Typography>
        <Card sx={{ padding: 3 }}>
          <PreventionSettings />
        </Card>
      </Box>
    </Card>
  );
}
