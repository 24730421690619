import { gql, useMutation, useQuery } from '@apollo/client';
import { projection } from '@everlutionsk/helpers';
import { RouterLink, useNavigate } from '@everlutionsk/ui-router';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  AppBar,
  Box,
  Container,
  Divider,
  Hidden,
  IconButton,
  Link,
  Menu,
  MenuItem,
  styled,
  SvgIcon,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { Role } from '../graphql/types';
import { DisabledPrint } from './DisabledPrint';
import { useIdentity } from './hooks/useIdentity';
import { sso } from '../sso';

interface Props {
  readonly onMobileNavOpen: () => void;
}

export function Header({ onMobileNavOpen }: Props) {
  const identity = useIdentity();
  if (identity == null) throw Error('Access Denied! Missing valid identity.');
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data } = useQuery(query, {
    variables: {
      id: identity.id,
      input: {
        code: 'prirucka',
        limit: 1
      }
    }
  });

  const [getManualUrl] = useMutation(manualUrlQuery);

  return (
    <DisabledPrint>
      <StyledAppBar position="sticky">
        <Toolbar variant="dense">
          <Container maxWidth="xl">
            <Box
              display={{ xs: 'flex', md: 'block' }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Hidden mdUp>
                <StyledIconButton onClick={onMobileNavOpen}>
                  <SvgIcon>
                    <MenuIcon />
                  </SvgIcon>
                </StyledIconButton>
              </Hidden>
              <Box display="flex" justifyContent="flex-end">
                <Tooltip title="Profil">
                  <StyledIconButton onClick={handleClick}>
                    <SvgIcon>
                      <SettingsIcon />
                    </SvgIcon>
                  </StyledIconButton>
                </Tooltip>
              </Box>
            </Box>
          </Container>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
          >
            <Box pl={2} py={1} pr={10}>
              <Box>
                <Typography variant="body2">Ste prihlasený ako:</Typography>
              </Box>
              <Box mt={1}>
                <Box>
                  {data?.userOne &&
                  ['admin', 'analyst', 'superAdmin'].includes(data?.userOne.role) ? (
                    <Typography variant="body1">
                      {data?.userOne.fullName}
                      {/* {prettifyRole(data.userOne.role as any)} */} {/* WHY?  */}
                    </Typography>
                  ) : (
                    <Typography variant="body1">{data?.userOne.fullName}</Typography>
                  )}
                </Box>
              </Box>

              {(identity.role === 'psychologist' || identity.role === 'owner') && (
                <Box mt={3} display="flex" flexDirection="column">
                  <Box>
                    <Link to="my-profile/details" component={RouterLink} color="primary">
                      Môj profil
                    </Link>
                  </Box>
                </Box>
              )}
              <Box mt={1}>
                {data && (
                  <Link
                    component="button"
                    onClick={async () => {
                      const response = await getManualUrl({
                        variables: { path: data.manualMany[0].path }
                      });

                      if (response.data == null) return;
                      downloadUrlTarget(response.data.manualUrl);
                    }}
                    color="primary"
                  >
                    <Typography variant="body1">Manuál</Typography>
                  </Link>
                )}
              </Box>

              {/* {['admin', 'superAdmin'].includes(identity.role) && (
                <Box mt={1} display="flex" flexDirection="column">
                  <Box>
                    <Link to="my-profile/password-change" component={RouterLink} color="primary">
                      Zmena hesla
                    </Link>
                  </Box>
                </Box>
              )} */}
            </Box>

            <Box px={2}>
              <Divider orientation="horizontal" variant="fullWidth" />
            </Box>

            <Box py={1}>
              <MenuItem
                onClick={() => {
                  handleClose();
                  sso.signOut();
                  navigate('/');
                }}
              >
                Odhlásiť
              </MenuItem>
            </Box>
          </Menu>
        </Toolbar>
      </StyledAppBar>
    </DisabledPrint>
  );
}

const StyledAppBar = styled(AppBar)`
  ${({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
    padding: '0px 0px',
    margin: '0px',
    fontSize: '10px'
  })}
`;

const StyledIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.common.white};
`;

const query = gql<UserOneMenuInfoGQL>`
  query UserOneMenuInfo($id: ID!, $input: ManualManyInput!) {
    userOne(id: $id) {
      id
      fullName
      role
    }
    manualMany(input: $input) {
      id
      code
      name
      path
    }
  }
`;

const manualUrlQuery = gql<MenuManualUrlGQL>`
  mutation MenuManualUrl($path: String!) {
    manualUrl(path: $path)
  }
`;

export const prettifyRole = projection({
  [Role.admin]: 'Admin',
  [Role.analyst]: 'Analytik',
  [Role.superAdmin]: 'Super admin'
});

function downloadUrlTarget(url: string) {
  const element = document.createElement('a');
  element.href = url;
  element.target = '_blank';
  element.download = 'export.pdf';
  element.click();
}
