import { Table } from '@app/admin/src/components/Table';
import { Box, styled } from '@mui/material';
import React from 'react';

interface Props {
  questions: Array<{
    id: string;
    task: string;
  }>;
  data: Array<{
    id: string;
    radioValue: number;
  }>;
}

export function T8AnswersTable({ data, questions }: Props) {
  return (
    <TableWrapper mt={2}>
      <Table
        items={data}
        columns={[
          {
            label: 'Úloha',
            render: ({ id }) => {
              const question = questions.find(item => item.id === id);

              return `${question?.task}`;
            }
          },
          {
            label: 'Odpoveď',
            render: ({ radioValue }) => toAnswers(radioValue)
          }
        ]}
      />
    </TableWrapper>
  );
}

const TableWrapper = styled(Box)`
  thead {
    background-color: #f8f8f8;
  }
`;

function toAnswers(value: number): number {
  switch (value) {
    case 0:
      return 0;
    case 1:
      return 0.5;
    case 2:
      return 1;
    default:
      return 0;
  }
}
