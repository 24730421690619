import { gql, useQuery } from '@apollo/client';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { CheckboxField, createFormSpec, RadioField, SubmitButton } from '@everlutionsk/ui-formik';
import { useParams } from '@everlutionsk/ui-router';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import * as yup from 'yup';

export function PreventionSettings() {
  const { id } = useParams(['id']);
  const { data } = useQuery(accessQuery, { variables: { userId: id } });
  const { data: accessOptions } = useQuery(rolesManyQuery);

  const [editAccess] = useFlashMutation(editAccessMutation, {
    successMsg: 'Rola pre prevenciu bola úspešne upravená.',
    errorMsg: 'Rolu pre prevenciu sa nepodarilo upraviť.'
  });

  const formSpec = useMemo(() => {
    return createFormSpec({
      access: yup.boolean().required(),
      role: yup.string().when('access', {
        is: true,
        then: schema =>
          schema
            .oneOf(
              accessOptions ? accessOptions.userPreventionAccessMany.map(({ value }) => value) : []
            )
            .required('Pre prístup k prevencii je potrebné zvoliť rolu.'),
        otherwise: schema => schema.nullable()
      })
    });
  }, [accessOptions]);

  if (data == null || accessOptions == null) {
    return (
      <>
        {[...new Array(3)].map((_, index) => (
          <Grid key={index} container>
            <Grid item xs={12}>
              <Skeleton height={40} />
            </Grid>
          </Grid>
        ))}
      </>
    );
  }

  return (
    <>
      <Formik
        enableReinitialize
        {...formSpec({
          access: data.userPreventionAccessOne.role != null,
          role: data?.userPreventionAccessOne.role ?? undefined
        })}
        onSubmit={({ access, role }) =>
          editAccess({
            variables: {
              input: {
                userId: id,
                role: access ? role : undefined
              }
            }
          })
        }
      >
        {({ values }) => {
          return (
            <Form>
              <CheckboxField name="access" label="prístup k prevencii" />
              {values.access && (
                <Box>
                  <Typography variant="body1">Vyberte rolu:</Typography>
                  <RadioField name="role" options={accessOptions.userPreventionAccessMany ?? []} />
                </Box>
              )}
              <Box>
                <SubmitButton>Uložiť</SubmitButton>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

const accessQuery = gql<PreventionAccessOneQueryGQL>`
  query PreventionAccessOneQuery($userId: ID!) {
    userPreventionAccessOne(userId: $userId) {
      role
    }
  }
`;

const rolesManyQuery = gql<PreventionAccessManyQueryGQL>`
  query PreventionAccessManyQuery {
    userPreventionAccessMany {
      label
      value
    }
  }
`;

const editAccessMutation = gql<PreventionAccessEditGQL>`
  mutation PreventionAccessEdit($input: PreventionAccessInput!) {
    userPreventionAccessEdit(input: $input) {
      id
    }
  }
`;
