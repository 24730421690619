import { gql, useMutation, useQuery } from '@apollo/client';
import { call } from '@everlutionsk/helpers';
import { Button, Loading } from '@everlutionsk/ui';
import { Link, useNavigate, useParams } from '@everlutionsk/ui-router';
import { Box, Grid, IconButton, styled, Typography, useTheme } from '@mui/material';
import Hls from 'hls.js';
import { uniqBy } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { ContentHeader } from '../components/ContentHeader';
import { videoList } from '../helpers';
import { Identity, useIdentity } from '../components/hooks/useIdentity';
import {
  ArrowBackIosRounded,
  ArrowForward,
  ArrowForwardIosRounded,
  EventAvailable
} from '@mui/icons-material';

export function VideoList() {
  const identity = useIdentity();
  const videoIds = useUserGroupVideoIds(identity);

  const availableSections = videoIds.map(item => {
    const section = item.split('/')[1];
    if (section.startsWith('0')) return section.replace(/0/g, '');

    return section;
  });

  const filteredVideoList = videoList.filter(item => availableSections?.includes(item.section));

  return (
    <>
      <ContentHeader
        title="Videometodiky a prípadové štúdie"
        breadcrumbs={[{ title: 'Domov' }, { title: 'Videometodiky' }]}
      />
      {filteredVideoList.length === 0 ? (
        <p>Potrebné priradenie ku skupine.</p>
      ) : (
        <Grid container spacing={3}>
          {filteredVideoList.map((item, index) => {
            return (
              <Grid item xs={12} md={4} key={index}>
                <VideoListItem
                  description={item.description}
                  label={item.label}
                  section={item.section}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
}

function VideoListItem({
  label,
  description,
  section
}: {
  label: string;
  description: string;
  section: string;
}) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <HoverBox
      onClick={() => navigate(`/video-list/${section}`)}
      pt={3}
      pb={1}
      px={3}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      border={`1px solid ${theme.palette.primary.main}`}
      position="relative"
      minHeight="350px"
    >
      <Box>
        <EventAvailable
          style={{
            color: '#f2f2f281',
            fontSize: '100px',
            position: 'absolute',
            top: '-4px',
            right: 0
          }}
        />
        <Box mb={1}>
          <Typography color="primary" variant="h6">
            {label}
          </Typography>
        </Box>

        <Box mb={1}>
          <Typography variant="body2">{description}</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Link to={`/video-list/${section}`}>
          <ArrowForward style={{ color: theme.palette.secondary.main }} />
        </Link>
      </Box>
    </HoverBox>
  );
}

const HoverBox = styled(Box)`
  background-color: #fff;
  &:hover,
  &:focus {
    background-color: #f7f7f78d;
    cursor: pointer;
    transform: scale3d(1.016, 1.016, 1);
  }
`;

export function VideoDetail() {
  const identity = useIdentity();
  if (identity == null) throw Error('Access Denied! Missing valid identity.');

  const { id } = useParams(['id']);
  const videoIds = useUserGroupVideoIds(identity);

  const currentSection = videoList.find(item => item.section === id);
  const [currentVideo, setCurrentVideo] = useState<number>(0);

  if (currentSection == null) return <Loading />;

  const filteredVideos = currentSection.videos.filter(item => videoIds.includes(item.path));

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Box mb={4}>
          <ContentHeader
            title="Videometodika"
            breadcrumbs={[
              { title: 'Videometodiky', path: '/video-list' },
              {
                title: currentSection?.label
              }
            ]}
          />
          <Typography variant="body2">{currentSection.description}</Typography>
        </Box>

        <Typography variant="body1" fontWeight="bold">
          {filteredVideos[currentVideo].label}
        </Typography>

        <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row">
          <IconButton
            disabled={currentVideo === 0}
            onClick={() => {
              if (currentVideo !== 0) setCurrentVideo(currentVideo - 1);
            }}
          >
            <ArrowBackIosRounded />
          </IconButton>
          <Box>
            <Player path={filteredVideos[currentVideo].path} userId={identity.id} />
          </Box>
          <IconButton
            disabled={currentVideo === filteredVideos.length - 1}
            onClick={() => {
              if (currentVideo !== filteredVideos.length - 1) setCurrentVideo(currentVideo + 1);
            }}
          >
            <ArrowForwardIosRounded />
          </IconButton>

          <Box
            display="flex"
            flexDirection="column"
            minWidth={250}
            maxWidth={250}
            height={500}
            style={{ overflow: 'hiddien', overflowY: 'scroll' }}
            border="1 px solid black"
          >
            {filteredVideos.map((item, index) => (
              <Button
                style={{ marginBottom: 5 }}
                variant={currentVideo === index ? 'contained' : 'outlined'}
                // color={currentVideo === index ? 'secondary' : 'default'} // TODO:
                onClick={() => {
                  setCurrentVideo(index);
                }}
                key={index}
              >
                <Typography>{item.label}</Typography>
              </Button>
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export function Player({ path, userId }: { path: string; userId: string }) {
  let interval: any;

  const ref = useRef<HTMLVideoElement | null>(null);
  const [action, setAction] = useState<'play' | 'stop'>('stop');
  const hls = React.useRef<Hls>(new Hls());

  const { data } = useQuery(query, { variables: { path, userId }, fetchPolicy: 'no-cache' });
  const [mutate] = useMutation(mutation);

  useEffect(() => {
    if (data == null) return;
    if (ref.current == null) return;

    if (Hls.isSupported()) {
      hls.current.loadSource(ref.current.src);
      hls.current.attachMedia(ref.current);

      ref.current.currentTime = data.userVideoDataOne.time;
    }
  }, [data]);

  useEffect(() => {
    if (data == null) return;

    if (action === 'play') {
      let { time } = data.userVideoDataOne;

      interval = setInterval(() => {
        if (ref.current == null) return;
        time = Number(ref.current.currentTime.toFixed());

        mutate({ variables: { path, userId, time } });
      }, 20_000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [data, action]);

  return (
    <video
      onPlay={() => setAction('play')}
      onPause={() => setAction('stop')}
      onEnded={() => setAction('stop')}
      ref={player => (ref.current = player)}
      id="player"
      width="100%"
      height="100%"
      src={data?.videoSecretKey}
      controls
    />
  );
}

const query = gql<VideoSecretKeyGQL>`
  query VideoSecretKey($path: String!, $userId: ID!) {
    videoSecretKey(path: $path)
    userVideoDataOne(userId: $userId, path: $path) {
      id
      time
    }
  }
`;

const mutation = gql<UserStoreVideoDataGQL>`
  mutation UserStoreVideoData($userId: ID!, $path: String!, $time: Int!) {
    userStoreVideoData(userId: $userId, path: $path, time: $time)
  }
`;

function useUserGroupVideoIds(identity: Identity | null): string[] {
  if (identity == null) throw Error('Access Denied! Missing valid identity.');

  const { data } = useQuery(userQuery, { variables: { id: identity.id } });

  if (identity.role === 'admin' || identity.role === 'superAdmin')
    return videoList.flatMap(item => item.videos.map(item => item.path));

  const videoIds = call(() => {
    const positionVideoIds = data?.userOne.position.flatMap(item => item.videoIds) ?? [];

    if (data?.userOne.group != null) {
      const ids = data?.userOne.group?.videoIds;

      const enabledVideos = positionVideoIds.filter(item => ids.includes(item));

      return uniqBy(id => id, enabledVideos);
    }

    if (data?.userOne.organisation?.group != null) {
      const ids = data?.userOne.organisation?.group?.videoIds;

      const enabledVideos = positionVideoIds.filter(item => ids.includes(item));

      return uniqBy(id => id, enabledVideos);
    }

    if (positionVideoIds.length > 0) {
      return positionVideoIds;
    }

    return [];
  });

  return videoIds;
}

const userQuery = gql<UserOneGroupsGQL>`
  query UserOneGroups($id: ID!) {
    userOne(id: $id) {
      id
      organisation {
        id
        group {
          id
          videoIds
        }
      }
      group {
        id
        videoIds
      }
      position {
        id
        videoIds
      }
    }
  }
`;
