import { MenuItem, Select as MiuSelect } from '@mui/material';
import React from 'react';

interface Props {
  value: any;
  onSelect: (value: any) => void;
  items: Array<{ value: string; label: string }>;
  defaultValue: string;
}

export function Select({ value, onSelect, items, defaultValue }: Props) {
  return (
    <>
      <MiuSelect
        value={value ?? 'all'}
        onChange={event => {
          const { value } = event.target;

          onSelect(value !== 'all' ? value : undefined);
        }}
      >
        <MenuItem value={'all'}>{defaultValue}</MenuItem>
        {items.map((item, idx) => (
          <MenuItem key={idx} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </MiuSelect>
    </>
  );
}
