import { gql, useMutation } from '@apollo/client';
import { Button, Loading } from '@everlutionsk/ui';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import {
  ConditionalForm,
  Fields,
  RadioField,
  SelectField,
  TextField,
  createFormSpec,
  customRadioValue
} from '@everlutionsk/ui-formik';
import { useParams } from '@everlutionsk/ui-router';
import { Box, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { isEmpty } from 'ramda';
import React, { useState } from 'react';
import * as yup from 'yup';
import { routing } from '../../Routes';
import { ContentHeader } from '../../components/ContentHeader';
import { CustomChip } from '../../components/CustomChip';
import { DateField } from '../../components/DateFIeld';
import { FormButton } from '../../components/FormButton';
import { FormWrapper } from '../../components/FormWrapper';
import { ReassignmentStudentDialog } from '../../components/ReassignmentStudentDialog';
import { useCountries } from '../../components/countries';
import { StudentOneDetailQuery, StudentStatus } from '../../graphql/types';

export function DetailStudent({ data }: { data: StudentOneDetailQuery | undefined }) {
  const { id } = useParams(['id']);
  const countries = useCountries();
  const [open, setOpen] = useState(false);

  const [editStudent] = useFlashMutation(mutation, {
    successMsg: 'Zmeny boli uložené',
    errorMsg: 'Zmeny neboli uložené'
  });

  const [reassign] = useFlashMutation(reassignmentMutation, {
    successMsg: 'Žiak bol úspešne preradený',
    errorMsg: ({ message }) => message
  });

  const [toggle] = useMutation(toggleMutation);

  if (data == null) return <Loading />;

  return (
    <>
      <ContentHeader
        title={data.studentOne.fullName}
        breadcrumbs={[
          { title: 'Domov' },
          { title: 'Žiaci', path: routing.students.list },
          {
            title: `${data.studentOne.firstName} ${data.studentOne.lastName}`,
            path: routing.student.detail(id)
          },
          { title: 'Profil' }
        ]}
      />

      <Grid container>
        <Grid item xs={12} sm={12} md={10}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Box>
              Status:{' '}
              <CustomChip
                color={data.studentOne.status === StudentStatus.active ? 'success' : 'disabled'}
                label={data.studentOne.status === StudentStatus.active ? 'Aktívni' : 'Archivovaní'}
              />
            </Box>

            <Box>
              <Button
                style={{ marginRight: '12px' }}
                color="secondary"
                variant="outlined"
                onClick={() => setOpen(true)}
              >
                Preradiť žiaka
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  toggle({
                    variables: {
                      id,
                      status:
                        data.studentOne.status === StudentStatus.active
                          ? StudentStatus.inactive
                          : StudentStatus.active
                    },
                    update: cache => {
                      cache.evict({ id: 'ROOT_QUERY', fieldName: 'studentMany' });
                    }
                  });
                }}
              >
                {data.studentOne.status === StudentStatus.active ? 'Archivovať' : 'Aktivovať'}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <FormWrapper>
        <Formik
          {...formSpec({
            firstName: data.studentOne.firstName,
            lastName: data.studentOne.lastName,
            gender: data.studentOne.gender,
            birthIdentificationNumber: data.studentOne.birthIdentificationNumber,
            dateOfBirth: new Date(data.studentOne.dateOfBirth),
            school: data.studentOne.school,
            line1: data.studentOne.address?.line1,
            city: data.studentOne.address?.city,
            postCode: data.studentOne.address?.postCode,
            fullName: data.studentOne.legalRepresentative?.fullName,
            email: data.studentOne.legalRepresentative?.email,
            phoneNumber: data.studentOne.legalRepresentative?.phoneNumber,
            grade: data.studentOne.grade,
            nationality: data.studentOne.nationality,
            otherData: data.studentOne.otherData,
            language: data.studentOne.language
          })}
          onSubmit={({
            lastName,
            firstName,
            gender,
            birthIdentificationNumber,
            dateOfBirth,
            school,
            line1,
            city,
            postCode,
            fullName,
            email,
            phoneNumber,
            grade,
            nationality,
            otherData,
            language
          }) =>
            editStudent({
              variables: {
                input: {
                  id,
                  firstName,
                  lastName,
                  gender,
                  birthIdentificationNumber,
                  dateOfBirth,
                  school,
                  line1,
                  city,
                  postCode,
                  fullName,
                  email,
                  phoneNumber,
                  grade: isEmpty(grade) ? undefined : parseInt(grade),
                  otherData,
                  nationality,
                  language
                }
              },
              update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'studentMany' });
              }
            })
          }
        >
          <ConditionalForm>
            <Fields>
              <Box my={3}>
                <Typography variant="h5">Základné informácie</Typography>
              </Box>
              <TextField name="firstName" label="Meno*" />
              <TextField name="lastName" label="Priezvisko*" />
              <SelectField name="gender" label="Pohlavie*" options={genderOptions} />
              <DateField name="dateOfBirth" label="Dátum narodenia*" type="date" />
              <TextField name="birthIdentificationNumber" label="Rodné číslo*" />
              <TextField name="school" label="Škola*" />
              <RadioField
                options={[
                  { label: 'slovenský', value: 'slovenský' },
                  { label: 'maďarský', value: 'maďarský' },
                  { label: 'rómsky', value: 'rómsky' },
                  {
                    label: 'iné',
                    value: customRadioValue({
                      size: 'small',
                      type: 'text'
                    })
                  }
                ]}
                name="language"
                label="Materinský jazyk*"
              />
              <TextField type="number" name="grade" label="Ročník" />
              <SelectField name="nationality" label="Národnosť" options={countries} />
              <TextField name="otherData" label="Iné údaje" />
              <Box my={3}>
                <Typography variant="h5">Adresa trvalého pobytu</Typography>
              </Box>
              <TextField name="line1" label="Ulica + čislo*" />
              <TextField name="city" label="Mesto*" />
              <TextField name="postCode" label="PSČ*" />
              <Box my={3}>
                <Typography variant="h5">Informácie o zakonnom zástupcovi</Typography>
              </Box>
              <TextField name="fullName" label="Meno a priezvisko*" />
              <TextField name="phoneNumber" label="Telefón*" />
              <TextField name="email" label="Email*" />
            </Fields>

            <Box my={3}>
              <FormButton backTo={routing.students.list} />
            </Box>
          </ConditionalForm>
        </Formik>
      </FormWrapper>
      <ReassignmentStudentDialog
        onSubmit={async ownerId => {
          await reassign({ variables: { studentId: id, userId: ownerId } });
        }}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

const formSpec = createFormSpec({
  firstName: yup.string().nullable().required(`Meno žiaka je povinný údaj`),
  language: yup.string().nullable().required(`Materinský jazyk je povinný údaj`),
  lastName: yup.string().nullable().required(`Priezvisko žiaka je povinný údaj`),
  gender: yup.string().required('Vyberte pohlavie klienta'),
  dateOfBirth: yup.string().nullable().required(`Dátum narodenia je povinný údaj`),
  birthIdentificationNumber: yup.string().nullable().required(`Rodné číslo žiaka je povinný údaj`),
  school: yup.string().nullable().required(`Škola, ktorú žiak navštevuje je povinný údaj`),
  line1: yup.string().nullable().required(`Ulica je povinný údaj`),
  city: yup.string().nullable().required(`Mesta je povinný údaj`),
  postCode: yup.string().nullable().required(`PSČ je povinný údaj`),
  fullName: yup
    .string()
    .nullable()
    .required(`Meno a priezvisko zákonného zástupcu je povinný údaj`),
  phoneNumber: yup.string().required(`Telefóne číslo zákonného zástupcu je povinný údaj`),
  email: yup
    .string()
    .nullable()
    .required(`Email zákonného zástupcu je povinný údaj`)
    .email('Formát emailu je nesprávny'),
  nationality: yup.string().nullable(),
  otherData: yup.string().nullable(),
  grade: yup
    .number()
    .nullable()
    .max(9, 'Ročník musí byť menší alebo rovný 9.')
    .min(0, 'Ročník musí byť kladné číslo.')
});

export const studentOneFragment = gql`
  fragment StudentOneFragment on Student {
    id
    firstName
    lastName
    fullName
    gender
    status
    birthIdentificationNumber
    dateOfBirth
    grade
    otherData
    language
    nationality
    address {
      line1
      city
      postCode
    }
    school
    legalRepresentative {
      fullName
      phoneNumber
      email
    }
  }
`;

const mutation = gql<EditStudentMutationGQL>`
  mutation EditStudentMutation($input: EditStudentInput!) {
    editStudent(input: $input) {
      ...StudentOneFragment
    }
  }
  ${studentOneFragment}
`;

const toggleMutation = gql<StudentStatusMutationGQL>`
  mutation StudentStatusMutation($id: ID!, $status: StudentStatus!) {
    updateStudentStatus(id: $id, status: $status) {
      ...StudentOneFragment
    }
  }
  ${studentOneFragment}
`;

const genderOptions = [
  { value: 'male', label: 'Mužské' },
  { value: 'female', label: 'Ženské' }
];

const reassignmentMutation = gql<ReassignmentStudentGQL>`
  mutation ReassignmentStudent($studentId: ID!, $userId: ID!) {
    reassignmentStudent(studentId: $studentId, userId: $userId) {
      id
    }
  }
`;
