import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { T252EvaluationFragment } from '../../../graphql/types';
import { ClientTable } from './components/ClientTable';
import { T252ResultsTable } from './components/T252/ResultsTable';

export const t252EvaluationFragment = gql`
  fragment T252EvaluationFragment on T252Evaluation {
    ho {
      id
      label
      roughScore
      degree
      stenScore
      percentile
    }
    po {
      id
      label
      roughScore
      degree
      stenScore
      percentile
    }
    mo {
      id
      label
      roughScore
      degree
      stenScore
      percentile
    }
  }
`;

interface Props {
  client: {
    fullName: string;
    dateOfBirth: Date;
    gender: string;
    testingDate: Date;
  };
  data: T252EvaluationFragment;
}

export function T252Result({ data, client }: Props) {
  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Klient</Typography>
      </Box>
      <ClientTable data={client} />
      <Box mt={2}>
        <Typography variant="h4">Výsledky</Typography>
      </Box>
      <T252ResultsTable
        label="Hodnotové orientácie"
        firstColumnName="Hodnotové orientácie"
        data={data.ho}
      />
      <T252ResultsTable
        label="Postoje k hodnotám"
        firstColumnName="Hodnotové orientácie"
        data={data.po}
      />
      <T252ResultsTable
        label="Motivačné prostriedky"
        firstColumnName="Motivačné prostriedky"
        data={data.mo}
      />
    </>
  );
}
