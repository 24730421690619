import { gql, useQuery } from '@apollo/client';
import { useParams } from '@everlutionsk/ui-router';
import DashboardIcon from '@mui/icons-material/Dashboard';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routing, routingPatterns } from '../../Routes';
import { DetailStudent, studentOneFragment } from './DetailStudent';
import { Diagnostics } from './Diagnostics';
import { NavigationLayer, useNavigationLayer } from '@everlutionsk/ui-admin';

function navigationLayer(studentId: string, title: string): NavigationLayer {
  return {
    title: `${title}`,
    items: [
      {
        title: 'Výsledky žiaka',
        icon: () => <DashboardIcon />,
        path: routing.student.diagnostics(studentId),
        exact: true
      },
      {
        title: 'Profil žiaka',
        icon: () => <DashboardIcon />,
        path: routing.student.detail(studentId),
        exact: true
      }
      // {  https://github.com/everlutionsk/e-poradenstvo/issues/559
      //   title: 'Diagnóza',
      //   icon: () => <DashboardIcon />,
      //   path: routing.student.diagnosis(studentId),
      //   exact: true
      // },
    ]
  };
}

export function Student() {
  const { id } = useParams(['id']);
  const { data } = useQuery(query, { variables: { id } });

  useNavigationLayer(navigationLayer(id, data?.studentOne.fullName ?? ''));

  return (
    <>
      <Routes>
        <Route path={routingPatterns.student.detail} element={<DetailStudent data={data} />} />
        {/* <Route path={routingPatterns.student.diagnosis} element={<Diagnosis />} /> https://github.com/everlutionsk/e-poradenstvo/issues/559 */}
        <Route
          path={routingPatterns.student.diagnostics}
          element={<Diagnostics student={data?.studentOne} />}
        />
      </Routes>
    </>
  );
}

const query = gql<StudentOneDetailGQL>`
  query StudentOneDetail($id: ID!) {
    studentOne(id: $id) {
      ...StudentOneFragment
    }
  }
  ${studentOneFragment}
`;
