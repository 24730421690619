import { gql, useQuery } from '@apollo/client';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { Fields, SubmitButton, TextField, createFormSpec } from '@everlutionsk/ui-formik';
import { useNavigate, useParams } from '@everlutionsk/ui-router';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { ContentHeader } from '../../components/ContentHeader';
import { FormWrapper } from '../../components/FormWrapper';
import { Dictionary } from '@everlutionsk/ui-admin';

export function AcceptOrganisationRequest() {
  const { id } = useParams(['id']);
  const navigate = useNavigate();

  const { data } = useQuery(query, { variables: { id } });
  const [update] = useFlashMutation(updateMutation, {
    successMsg: 'Organizácia bola úspešne upravená.',
    errorMsg: 'organizáciu sa nepodarilo upraviť.'
  });

  const formSpec = useMemo(() => {
    return createFormSpec({
      name: yup.string().nullable(),
      line1: yup.string().nullable(),
      city: yup.string().nullable(),
      region: yup.string().nullable(),
      district: yup.string().nullable()
    });
  }, []);

  return (
    <>
      <ContentHeader
        title="Žiadosť o zmenu"
        breadcrumbs={[{ title: 'Domov' }, { title: 'Upraviť organizáciu' }]}
      />
      <Dictionary>
        {[
          ['Názov organizácie', data?.organisationOne.name],
          ['Mesto', data?.organisationOne.city],
          ['Ulica', data?.organisationOne.line1],
          ['Kraj', data?.organisationOne.region],
          ['Okres', data?.organisationOne.district]
        ]}
      </Dictionary>
      <FormWrapper>
        <Formik
          enableReinitialize
          {...formSpec({
            name: data?.organisationOne.requestedChanges?.name ?? '',
            city: data?.organisationOne.requestedChanges?.city ?? '',
            line1: data?.organisationOne.requestedChanges?.line1 ?? '',
            region: data?.organisationOne.requestedChanges?.region ?? '',
            district: data?.organisationOne.requestedChanges?.district ?? ''
          })}
          onSubmit={async ({ name, line1, city, region, district }) => {
            await update({
              variables: {
                input: {
                  id,
                  name,
                  city,
                  district,
                  line1,
                  region
                }
              },
              update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'organisationMany' });
              }
            });

            navigate(-1);
          }}
        >
          <Form>
            <Fields>
              <TextField name="name" label="Názov organizácie*" />
              <TextField name="city" label="Mesto*" />
              <TextField name="line1" label="Ulica*" />
              <TextField name="region" label="Kraj*" />
              <TextField name="district" label="Okres*" />
            </Fields>

            <Box my={3} display="flex" justifyContent="flex-end" flexDirection="row">
              <SubmitButton loading={false}>Schváliť zmeny</SubmitButton>
            </Box>
          </Form>
        </Formik>
      </FormWrapper>
    </>
  );
}

const query = gql<OrganisationOneAcceptRequestGQL>`
  query OrganisationOneAcceptRequest($id: ID!) {
    organisationOne(id: $id) {
      id
      name
      line1
      city
      region
      district
      requestedChanges {
        name
        line1
        city
        region
        district
      }
    }
  }
`;

const updateMutation = gql<AcceptOrganisationNameChangeGQL>`
  mutation AcceptOrganisationNameChange($input: AcceptOrganisationChangesInput!) {
    acceptOrganisationNameChange(input: $input) {
      id
      name
      requestedChanges {
        name
        city
        line1
        region
        district
      }
    }
  }
`;
