import { useParams } from '@everlutionsk/ui-router';
import DashboardIcon from '@mui/icons-material/Dashboard';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routing, routingPatterns } from '../../Routes';
import { DiagnosticResultOne } from './DiagnosticResultOne';
import { DiagnosticResults } from './DiagnosticResults';
import { PrepareDiagnostic } from './Prepare';
import { SendDiagnostic } from './Send';
import { NavigationLayer, useNavigationLayer } from '@everlutionsk/ui-admin';

function navigationLayer(code: string): NavigationLayer {
  return {
    title: `Test ${code}`,
    items: [
      {
        title: 'Testovať',
        icon: () => <DashboardIcon />,
        path: routing.diagnostic.prepare(code)
      },
      {
        title: 'Výsledky testu',
        icon: () => <DashboardIcon />,
        path: routing.diagnostic.results(code),
        exact: true
      }
    ]
  };
}

export function Diagnostic() {
  const { code } = useParams(['code']);

  useNavigationLayer(navigationLayer(code));

  return (
    <>
      <Routes>
        <Route path={routingPatterns.diagnostic.results} element={<DiagnosticResults />} />
        <Route path={routingPatterns.diagnostic.result} element={<DiagnosticResultOne />} />
        <Route path={routingPatterns.diagnostic.prepare.pattern} element={<PrepareDiagnostic />} />
        <Route path={routingPatterns.diagnostic.send.pattern} element={<SendDiagnostic />} />
      </Routes>
    </>
  );
}
