import { gql, useQuery } from '@apollo/client';
import { populated } from '@everlutionsk/helpers';
import { Loading } from '@everlutionsk/ui';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { T216EvaluationFragment } from '../../../graphql/types';
import { ClientTable } from './components/ClientTable';
import { T216AnswersTable } from './components/T216/T216AnswersTable';
import { T216ResultsTable } from './components/T216/T216ResultsTable';

export const t216EvaluationFragment = gql`
  fragment T216EvaluationFragment on T216Evaluation {
    data {
      label
      roughScore
      sten
    }
  }
`;

const answersQuery = gql<T216AnswersResultsGQL>`
  query T216AnswersResults($token: String!) {
    t216Diagnostics(token: $token) {
      id: originalToken
      questions {
        id
        task
      }
      answers {
        id
        radioValue
      }
    }
  }
`;

interface Props {
  client: {
    fullName: string;
    dateOfBirth: Date;
    gender: string;
    testingDate: Date;
    details:
      | {
          ratingSlovakLanguage: string;
          ratingMaths: string;
          ratingTechnicalEducation: string;
          ratingArtEducation: string;
        }
      | undefined;
  };
  token: string;
  data: T216EvaluationFragment;
}

export function T216Result({ token, client, data }: Props) {
  const { data: answers } = useQuery(answersQuery, { variables: { token } });

  if (!populated(answers)) return <Loading />;

  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Klient</Typography>
      </Box>
      <ClientTable data={client} />
      <Box mt={2}>
        <Typography variant="h4">Výsledky</Typography>
      </Box>
      <T216ResultsTable data={data} />
      <T216AnswersTable
        data={answers.t216Diagnostics?.answers ?? []}
        questions={answers.t216Diagnostics?.questions ?? []}
      />
    </>
  );
}
