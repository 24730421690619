import { environment } from '../../environment';
import { Gender } from '../../graphql/types';

const { frontendDomain } = environment;

export function getAge(dateString: string) {
  const today = new Date();
  const dateOfBirth = new Date(dateString);

  let age = today.getFullYear() - dateOfBirth.getFullYear();
  const m = today.getMonth() - dateOfBirth.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < dateOfBirth.getDate())) {
    age--;
  }

  return age < 0 ? 0 : age;
}

export function prettifyGender(gender: Gender): string {
  return (
    {
      [Gender.male]: 'Muž',
      [Gender.female]: 'Žena'
    }[gender] ?? gender
  );
}

export function slugify(str: string): string {
  str = str.replace(/^\s+|\s+$/g, '').toLowerCase();

  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  return str
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '');
}

export function toDiagnosticUrl(id: string, code: string): string {
  if (environment.stage === 'dev') return `http://${frontendDomain}/${slugify(code)}/${id}`;

  return `https://${frontendDomain}/${slugify(code)}/${id}`;
}
