import { gql } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { useFlashMutation, useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { ConditionalForm, createFormSpec, Fields, TextField } from '@everlutionsk/ui-formik';
import { useParams } from '@everlutionsk/ui-router';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { ContentHeader } from '../../../components/ContentHeader';
import { FormButton } from '../../../components/FormButton';
import { FormWrapper } from '../../../components/FormWrapper';
import { TestingStatus } from '../../../graphql/types';
import { toDiagnosticUrl } from '../utils';

export function SendGroupForm() {
  const { id } = useParams(['code', 'id']);

  const { data } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      input: { pagination: { limit, offset }, groupTestingId: id, status: TestingStatus.ready }
    })
  });

  const [send] = useFlashMutation(mutation, {
    successMsg: 'Link bol odoslaný',
    errorMsg: 'Link nebol odoslaný'
  });

  if (data == null) return <Loading />;

  if (data.testingMany.items.length === 0) {
    console.error('No tests found!');
    return <></>;
  }

  const expiresAt = new Date(data.testingMany.items[0].expiresAt).toLocaleDateString();

  return (
    <>
      <ContentHeader title={'Poslať email'} />
      <FormWrapper>
        <Formik
          {...formSpec({
            from: null,
            to: null,
            message: `Napíš správu sem...

  Link k Tvojmu testu:\n${data.testingMany.items
    .map(({ id, code, student }) => `${student.fullName} - ${toDiagnosticUrl(id, code)}\n`)
    .join('')}

  Platnost linku: ${expiresAt}`
          })}
          onSubmit={({ to, from, message }) =>
            send({ variables: { input: { from, to, message } } })
          }
        >
          <ConditionalForm>
            <Fields>
              <TextField name="to" label="Komu" type="email" />
              <TextField name="from" label="Od koho" type="email" />

              <TextField name="message" label="Správa" rows={7} multiline />
            </Fields>

            <Box my={3}>
              <FormButton label="Odoslať" />
            </Box>
          </ConditionalForm>
        </Formik>
      </FormWrapper>
    </>
  );
}

const formSpec = createFormSpec({
  to: yup
    .string()
    .email('Emailová adresa nie je validná')
    .nullable()
    .required(`Email komu je povinný údaj`),
  from: yup
    .string()
    .email('Emailová adresa nie je validná')
    .nullable()
    .required(`Email od koho  je povinný údaj`),
  message: yup.string().nullable().required(`Správa je povinný údaj`)
});

const mutation = gql<SendGroupTestingMutationGQL>`
  mutation SendGroupTestingMutation($input: SendTestingInput!) {
    sendTesting(input: $input)
  }
`;

export const query = gql<GroupTestingQueryGQL>`
  query GroupTestingQuery($input: TestingFilterInput!) {
    testingMany(input: $input) {
      total
      items {
        id
        code
        expiresAt
        student {
          id
          fullName
        }
      }
    }
  }
`;
