import { gql, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { useNavigate, useParams } from '@everlutionsk/ui-router';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routing, routingPatterns } from '../../../Routes';
import { ChangeUserData } from '../../../components/ChangeUserData';
import { AddStudent } from './AddStudent';
import { Profile, userOneFragment } from './Profile';
import { Students } from './Students';
import { NavigationLayer, useNavigationLayer } from '@everlutionsk/ui-admin';

function navigationLayer(userId: string, userFullName: string): NavigationLayer {
  return {
    title: `${userFullName}`,
    items: [
      {
        title: 'Profil Zamestnanca',
        icon: () => <i className="icon-overview" />,
        path: routing.user.profile(userId),
        exact: true
      },
      {
        title: 'Žiaci',
        icon: () => <i className="icon-overview" />,
        path: routing.user.students(userId),
        exact: true
      }
    ]
  };
}

export function User() {
  const { id } = useParams(['id']);
  const navigate = useNavigate();
  const { data } = useQuery(query, {
    variables: {
      id
    }
  });
  useNavigationLayer(navigationLayer(id, data?.userOne.fullName ?? ''));

  const [acceptUserChange] = useFlashMutation(acceptUserChangeMutation, {
    successMsg: 'Zmeny boli schválené.',
    errorMsg: 'Nepodarilo sa schváliť zmeny.',
    onCompleted: () => navigate(routing.users.list)
  });

  const [declineUserChange] = useFlashMutation(declineUserChangeMutation, {
    successMsg: 'Zmeny boli zamietnuté.',
    errorMsg: 'Nepodarilo sa zamietnuť zmeny.',
    onCompleted: () => navigate(routing.users.list)
  });

  if (data == null) return <Loading />;

  return (
    <Routes>
      <Route path={routingPatterns.user.profile} element={<Profile data={data} />} />
      <Route path={routingPatterns.user.students} element={<Students />} />
      <Route path={routingPatterns.user.addStudent} element={<AddStudent />} />
      <Route
        path={routingPatterns.user.changeData}
        element={
          <ChangeUserData
            buttonTitle="Schváliť zmeny"
            breadcrumbs={{
              id: data.userOne.id,
              fullName: data.userOne.fullName
            }}
            onSubmit={() =>
              acceptUserChange({
                variables: { userId: data.userOne.id },
                update: cache => {
                  cache.evict({ id: 'ROOT_QUERY', fieldName: 'userMany' });
                }
              })
            }
            onDecline={() =>
              declineUserChange({
                variables: { userId: data.userOne.id },
                update: cache => {
                  cache.evict({ id: 'ROOT_QUERY', fieldName: 'userMany' });
                }
              })
            }
            data={{
              id: data.userOne.id,
              firstName: data.userOne.requestedChanges?.firstName,
              lastName: data.userOne.requestedChanges?.lastName,
              phoneNumber: data.userOne.requestedChanges?.phoneNumber,
              title: data.userOne.requestedChanges?.title
            }}
          />
        }
      />
    </Routes>
  );
}

const query = gql<UserOneProfileGQL>`
  query UserOneProfile($id: ID!) {
    userOne(id: $id) {
      requestedChanges {
        firstName
        lastName
        title
        phoneNumber
      }
      ...UserOneFragment
    }
  }
  ${userOneFragment}
`;

const acceptUserChangeMutation = gql<AcceptUserChangeGQL>`
  mutation AcceptUserChange($userId: ID!) {
    acceptUserChange(userId: $userId) {
      id
      fullName
      createdAt
      organisation {
        id
        name
        district
        region
        city
      }
      position {
        id
        name
      }
      requestedPosition {
        id
        name
      }
      requestedChanges {
        firstName
        lastName
        title
        phoneNumber
      }
      status
    }
  }
`;

const declineUserChangeMutation = gql<DeclineUserChangeGQL>`
  mutation DeclineUserChange($userId: ID!) {
    declineUserChange(userId: $userId) {
      id
      fullName
      firstName
      lastName
      requestedChanges {
        firstName
        lastName
        title
        phoneNumber
      }
    }
  }
`;
