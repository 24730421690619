import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { T21EvaluationFragment } from '../../../graphql/types';
import { ClientTable } from './components/ClientTable';
import { T21ResultsTable } from './components/T21/T21ResultsTable';

export const t21EvaluationFragment = gql`
  fragment T21EvaluationFragment on T21Evaluation {
    score {
      p
      e
      n
      l
      i
      s
    }
    percentile {
      p
      e
      n
      l
      i
      s
    }
  }
`;

interface Props {
  client: {
    fullName: string;
    dateOfBirth: Date;
    gender: string;
    testingDate: Date;
  };
  data: T21EvaluationFragment;
}

export function T21Result({ client, data }: Props) {
  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Klient</Typography>
      </Box>
      <ClientTable data={client} />
      <Box mt={2} mb={2}>
        <Typography variant="h4">Výsledky</Typography>
      </Box>
      <T21ResultsTable data={data} />
    </>
  );
}
