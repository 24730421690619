import { constants, useDelayedTrue, useLatestNonNilValue } from '@everlutionsk/ui';
import { AutocompleteOption } from '@everlutionsk/ui-formik';
import {
  LoadingSpinner,
  mergeOptions,
  normalizeLabel
} from '@everlutionsk/ui-formik/dist/esm/src/autocomplete/common';
import { Autocomplete, Chip, TextField, TextFieldProps } from '@mui/material';
import React, { FocusEvent, useEffect, useMemo, useRef, useState } from 'react';

export interface MultiAutocompleteInputProps<Value, AllowCustom extends boolean | undefined> {
  /**
   * Input label.
   */
  readonly label: string;

  /**
   * Available options for suggestion list.
   */
  readonly options: Array<AutocompleteOption<Value, AllowCustom>> | undefined;

  /**
   * A list of already selected options.
   */
  readonly selected: Array<AutocompleteOption<Value, AllowCustom>> | undefined;

  /**
   * Debounced callback which will be triggered when search term changes.
   */
  readonly onTermChange?: (term: string) => void;

  /**
   * Called when user selects option from suggestions list.
   */
  readonly onSelect: (option: AutocompleteOption<Value, AllowCustom>) => void;

  /**
   * Called when user removes already selected option.
   */
  readonly onRemove: (option: AutocompleteOption<Value, AllowCustom>) => void;

  /**
   * Called when user removes all selected options by clicking on X button.
   */
  readonly onClear?: () => void;

  /**
   * Allows user to add custom option when provided options are not enough.
   *
   * Renders `Add ${term}` at the end of the suggestion list.
   *
   * When set to TRUE, selected option may have no value, only label.
   */
  readonly allowCustom?: AllowCustom;

  /**
   * Renders error message when provided.
   */
  readonly error?: string;

  /**
   * If `true`, the `input` element will be focused during the first mount.
   */
  readonly autoFocus?: boolean;

  readonly onBlur?: (event: FocusEvent<HTMLDivElement>) => void;
  readonly placeholder?: string;
  readonly disabled?: boolean;
  readonly TextFieldProps?: TextFieldProps;
  /**
   * Allows user to add color to given Chip based on a value of option.
   */
  readonly coloredValues: string[];
}

/**
 * Autocomplete input with multi-selection support.
 *
 * To use it with Formik, please use MultiAutocompleteField.
 */
export function MultiAutocompleteInput<Value, AllowCustom extends boolean | undefined = undefined>(
  props: MultiAutocompleteInputProps<Value, AllowCustom>
) {
  const [term, setTerm] = useState('');
  const [hasFocus, setFocusState] = useState(false);
  const [serveFromCache, setServeFromCache] = useState(false);

  const cache = useRef(
    new Map<string, Array<AutocompleteOption<Value, AllowCustom>> | undefined>()
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      props.onTermChange?.(term);
      setServeFromCache(false);
    }, constants.searchDebounce);
    return () => clearTimeout(timeout);
  }, [term]);

  const selected = useLatestNonNilValue(props.selected);
  const rawOptions = useLatestNonNilValue(serveFromCache ? cache.current.get(term) : props.options);
  const loading = props.selected == null || props.options == null;
  const showLoading = useDelayedTrue(hasFocus && loading, constants.loadingDelay);

  const options = useMemo(
    () =>
      prepareOptions(
        rawOptions ?? [],
        selected ?? [],
        term,
        loading,
        serveFromCache,
        props.allowCustom
      ),
    [rawOptions, selected, term, loading, serveFromCache, props.allowCustom]
  );

  if (props.options != null) {
    cache.current.set(term, props.options);
  }

  return (
    <Autocomplete
      options={options}
      loading={showLoading}
      onChange={(event, _, reason, details) => {
        if (reason === 'clear') {
          props.onClear?.();
        }

        const option = details?.option;
        if (option == null) return;

        if (reason === 'selectOption') {
          if (option.label.trim() === '') return;

          props.onSelect(option);
          setTerm('');
        }

        if (reason === 'removeOption') {
          props.onRemove(option);
        }
      }}
      inputValue={term}
      multiple
      value={selected}
      filterSelectedOptions
      disabled={props.disabled}
      onFocus={() => setFocusState(true)}
      onBlur={event => {
        cache.current.clear();
        setFocusState(false);
        props.onBlur?.(event);
      }}
      onInputChange={(_, value) => {
        setServeFromCache(true);
        setTerm(value);
      }}
      getOptionLabel={({ label }) => label.trim()}
      // TODO: problem with not selecting options when rendered as chip,
      // some props differences probably with renderTags chips

      // renderOption={(props, option, state) => {
      //   console.log({ option });
      //   return <Chip label={option.label.trim()} key={option.value as string} />;
      // }}

      // renderOption={(props, option, state, ownerState) => {
      //   // console.log(JSON.stringify(option, null, 2));
      //   // console.log(props, option, state);
      //   // console.log(ownerState.getOptionLabel(option));
      //   // console.log(ownerState.getOptionKey?.(option));
      //   // console.log(props);
      //   // console.log({ there: option.label.trim(), id: props.id, id2: option.value });
      //   return (
      //     <Chip
      //       // onClick={() => {
      //       //   console.log(option.value as string, props.key);
      //       // }}
      //       label={option.label.trim()}
      //       key={option.value as string}
      //     />
      //   );
      //   // return option.key;
      //   // return `Add ${JSON.stringify(option)}`;
      // }}

      getOptionDisabled={option => option.disabled ?? false}
      renderTags={(options: Array<AutocompleteOption<Value, AllowCustom>>, getTagProps) =>
        options.map((option, index) => {
          const isCustomOption = props.coloredValues.includes(option.value as string);
          return (
            <Chip
              label={option.label.trim()}
              color={isCustomOption ? 'secondary' : undefined}
              {...getTagProps({ index })}
              key={option.value as string}
            />
          );
        })
      }
      renderInput={params => (
        <TextField
          {...params}
          type="text"
          label={props.label}
          autoFocus={props.autoFocus}
          error={props.error != null}
          helperText={props.error}
          InputProps={{
            ...params.InputProps,
            endAdornment: showLoading ? <LoadingSpinner /> : params.InputProps.endAdornment
          }}
          placeholder={props.placeholder}
          {...props.TextFieldProps}
        />
      )}
    />
  );
}

/**
 * We need to merge available options with selected options
 * to avoid errors when selected options are missing from `options` list.
 *
 * Also, if `allowCustom` is enabled, we need to inject the custom option too.
 */
function prepareOptions<Value, AllowCustom extends boolean | undefined>(
  options: Array<AutocompleteOption<Value, AllowCustom>>,
  selected: Array<AutocompleteOption<Value, AllowCustom>>,
  term: string,
  loading: boolean,
  serveFromCache: boolean,
  allowCustom: boolean | undefined
) {
  const all = mergeOptions(options, selected);

  const normalizedTerm = normalizeLabel(term);
  if (
    !loading &&
    !serveFromCache &&
    allowCustom &&
    normalizedTerm !== '' &&
    all.every(({ label }) => normalizeLabel(label) !== normalizedTerm)
  ) {
    all.push({ label: term, value: undefined as any });
  }

  return all;
}
