import { gql, useQuery } from '@apollo/client';
import { IdentityQuery } from '../../graphql/types';
import { useErrorPage } from '../../pages/components/errorPageProvider';

export type Identity = IdentityQuery['identity'];

export function useIdentity() {
  const { setErrorPage } = useErrorPage();
  const { data } = useQuery(identityQuery, {
    fetchPolicy: 'cache-first',
    onError: () => setErrorPage('403') // TODO: this handles only insufficient permissions
  });
  if (data == null) return;

  return data.identity;
}

const identityQuery = gql<IdentityGQL>`
  query Identity {
    identity {
      id
      role
      firstName
      lastName
      fullName
      email
      status
      standardisationRole
      preventionRole
    }
  }
`;
