import { gql } from '@apollo/client';
import { call } from '@everlutionsk/helpers';
import { Table } from '../../components/Table';
import { useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { Link } from '@everlutionsk/ui-router';
import { AppBar, Box, Grid, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { routing } from '../../Routes';
import { ContentHeader } from '../../components/ContentHeader';
import { CustomChip } from '../../components/CustomChip';
import { SearchBar } from '../../components/SearchBar';
import { TabPanel } from '../../components/TabPanel';
import { TableArrowForward } from '../../components/TableArrowForward';
import { StudentStatus } from '../../graphql/types';
import { ToStudentOneNameLink } from '../Common/ToStudentOneNameLink';
import { prettifyGender } from '../Diagnostic/utils';
import { useIdentity } from '../../components/hooks/useIdentity';

export function StudentsList() {
  const identity = useIdentity();

  if (identity == null) throw Error('Access Denied! Missing valid identity.');

  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [value, setValue] = useState<number>(0);

  const { data, pagination, error } = useOffsetPaginatedQuery(studentsListQuery, {
    variables: ({ limit, offset }) => ({
      input: {
        pagination: { limit, offset },
        term: searchTerm,
        status: value === 0 ? StudentStatus.active : StudentStatus.inactive
      },
      userId: identity.id
    }),
    total: data => data.newStudentMany.total
  });

  const displayAddStudent = call(() => {
    const unauthorizedPositions = data?.userOne.position.find(position =>
      ['8195e772-1d53-47d7-9e61-e734a6b24518', '1d305633-f752-4581-9548-0cb139377923'].includes(
        position.id
      )
    );

    if (unauthorizedPositions != null) return false;

    if (['superAdmin', 'admin', 'psychologist', 'owner'].includes(identity.role)) return true;

    return false;
  });

  return (
    <>
      <ContentHeader title="Žiaci" breadcrumbs={[{ title: 'Domov' }, { title: 'Žiaci' }]} />
      <Box mb={3}>
        <Grid container>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={8}>
            <SearchBar
              buttonTitle="+ Pridať žiaka"
              withButton={displayAddStudent}
              navigateTo={routing.students.add}
              onSearch={value => setSearchTerm(value)}
            />
          </Grid>
        </Grid>
      </Box>
      <AppBar
        position="static"
        style={{
          backgroundColor: '#fff',
          borderBottom: '1px solid #ddd'
        }}
      >
        <Tabs
          value={value}
          onChange={(_, newValue) => setValue(newValue)}
          aria-label="simple tabs example"
          indicatorColor="primary"
        >
          <Tab
            label={`Aktívni ${value === 0 && data != null ? `(${data.newStudentMany.total})` : ''}`}
          />
          <Tab label="Archivovaní" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Table
          pagination={pagination}
          items={data?.newStudentMany.items}
          error={error}
          columns={[
            {
              label: 'Meno žiaka',
              render: item => ToStudentOneNameLink(item)
            },
            {
              label: 'Pohlavie',
              render: item => (item.gender ? prettifyGender(item.gender) : '-')
            },
            {
              label: 'Škola',
              render: item => item.school
            },
            {
              label: 'Status',
              render: ({ status }) => (
                <CustomChip
                  color="success"
                  label={status === StudentStatus.active ? 'Aktívni' : '-'}
                />
              )
            },
            {
              label: 'Odborník',
              render: item =>
                item.owner ? (
                  <Link to={routing.user.profile(item.owner.id)} color="secondary">
                    {item.owner.fullName}
                  </Link>
                ) : (
                  '-'
                )
            },
            {
              label: 'Prejsť na testy',
              render: () => <TableArrowForward to={routing.diagnostics.list} />
            }
          ]}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Table
          pagination={pagination}
          items={data?.newStudentMany.items}
          error={error}
          columns={[
            {
              label: 'Meno žiaka',
              render: item => ToStudentOneNameLink(item)
            },
            {
              label: 'Pohlavie',
              render: item => (item.gender ? prettifyGender(item.gender) : '-')
            },
            {
              label: 'Škola',
              render: item => item.school
            },
            {
              label: 'Status',
              render: ({ status }) => (
                <CustomChip
                  color="disabled"
                  label={status === StudentStatus.inactive ? 'Archivovaní' : '-'}
                />
              )
            },
            {
              label: 'Odborník',
              render: item =>
                item.owner ? (
                  <Link to={routing.user.profile(item.owner.id)} color="secondary">
                    {item.owner.fullName}
                  </Link>
                ) : (
                  '-'
                )
            },
            {
              label: 'Prejsť na testy',
              render: ({ id }) => <TableArrowForward to={routing.diagnostics.list} />
            }
          ]}
        />
      </TabPanel>
    </>
  );
}

export const studentsListQuery = gql<StudentManyQueryGQL>`
  query StudentManyQuery($input: StudentFilterInput!, $userId: ID!) {
    userOne(id: $userId) {
      id
      position {
        id
      }
    }
    newStudentMany(input: $input) {
      total
      items {
        id
        fullName
        gender
        school
        status
        owner {
          id
          fullName
        }
      }
    }
  }
`;
