import { Link, useNavigate } from '@everlutionsk/ui-router';
import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { routing } from '../Routes';
import { toShortDescription } from '../toDescription';
import { useTheme } from '../theme';
import { ArrowForward, EventAvailable } from '@mui/icons-material';
import { environment } from '../environment';

export function TestItem({ code, label }: { code: string; label: string }) {
  const theme = useTheme({ isProd: environment.isProd });
  const navigate = useNavigate();

  return (
    <HoverBox
      onClick={() => navigate(routing.diagnostic.prepare(code))}
      pt={3}
      pb={1}
      px={3}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      border={`1px solid ${theme.palette.primary.main}`}
      position="relative"
      minHeight="350px"
    >
      <Box>
        <EventAvailable
          style={{
            color: '#f2f2f281',
            fontSize: '100px',
            position: 'absolute',
            top: '-4px',
            right: 0
          }}
        />
        <Box mb={1}>
          <Typography color="primary" variant="h6">
            {code}
          </Typography>
        </Box>
        <Box mb={3} position="relative">
          <Typography variant="subtitle2">{label}</Typography>
        </Box>
        <Box
          mb={1}
          style={{
            whiteSpace: 'pre-line'
          }}
        >
          <Typography variant="body2">{toShortDescription({ code })}</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Link to={routing.diagnostic.prepare(code)}>
          <ArrowForward style={{ color: theme.palette.secondary.main }} />
        </Link>
      </Box>
    </HoverBox>
  );
}

const HoverBox = styled(Box)`
  background-color: #fff;
  &:hover,
  &:focus {
    background-color: #f7f7f78d;
    cursor: pointer;
    transform: scale3d(1.016, 1.016, 1);
  }
`;
