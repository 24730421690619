import { createUiTheme, UiTheme } from '@everlutionsk/ui';
import { createUiAdminTheme, UiAdminTheme } from '@everlutionsk/ui-admin';
import { createUiApolloTheme, UiApolloTheme } from '@everlutionsk/ui-apollo';
import { createUiFormikTheme, UiFormikTheme } from '@everlutionsk/ui-formik';
import { createTheme, Slide, Theme } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { TransitionProps } from '@mui/material/transitions';
import { skSK } from '@mui/material/locale';
import React, { useMemo } from 'react';

export function useTheme({ isProd, dark = false }: { isProd: boolean; dark?: boolean }) {
  const spacing = 8;

  return useMemo(() => {
    const muiTheme: Theme = createTheme(
      {
        palette: {
          background: {
            default: '#f7f7f7'
          },
          primary: {
            main: 'rgb(145,200,67)'
          },
          secondary: {
            main: '#7158E2'
          },
          success: {
            light: green[300],
            main: '#76aa2c',
            dark: green[700],
            contrastText: '#fff'
          },
          error: {
            light: red[300],
            main: red[500],
            dark: red[700],
            contrastText: '#fff'
          },
          text: {
            primary: '#1E272E',
            disabled: '#A4B0BE'
          }
        },
        spacing,
        typography: {
          h1: {
            fontSize: '3rem',
            marginBottom: spacing * 2
          },
          h2: {
            fontSize: '2rem',
            marginBottom: spacing * 2
          },
          h3: {
            fontSize: '1.5rem',
            marginBottom: spacing
          },
          h6: {
            fontSize: '1.25rem',
            fontWeight: 600,
            lineHeight: 1.6
          },
          subtitle2: {
            fontSize: '1.175rem',
            fontWeight: 400,
            lineHeight: 1.57
          },
          fontFamily: ['Roboto', 'sans-serif'].join(',')
        },
        components: {
          MuiTextField: {
            defaultProps: { variant: 'outlined', fullWidth: true }
          },
          MuiSnackbar: {
            defaultProps: { TransitionComponent: SlideTransition }
          },
          MuiButton: {
            styleOverrides: {
              contained: {
                backgroundColor: '#7158E2',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#573ab7'
                },
                boxShadow: 'none'
              },
              outlined: {
                borderColor: '#7158E2',
                color: '#7158E2',
                backgroundColor: 'transparent',
                '&:hover': {
                  borderColor: '#573ab7',
                  color: '#573ab7'
                }
              },
              text: {
                backgroundColor: 'transparent',
                color: '#7158E2',
                '&:hover': {
                  color: 'rgb(145,200,67)',
                  backgroundColor: 'rgb(145,200,67, 0.08)'
                }
              }
            }
          },
          MuiTable: {
            styleOverrides: {
              root: {
                border: '1px solid rgba(224, 224, 224, 1)',
                backgroundColor: '#FFFFFF'
              }
            }
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                paddingTop: '10px',
                paddingBottom: '10px',
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
              },
              head: {
                backgroundColor: '#ffffff',
                fontWeight: 'bold'
              }
            }
          }
        }
      },
      skSK
    );

    function SlideTransition(props: TransitionProps) {
      return (
        <Slide {...props} direction="up">
          <></>
        </Slide>
      );
    }

    const formikTheme = createUiFormikTheme(muiTheme, {
      Fields: { fieldStyle: { marginBottom: `${spacing * 3}px` } } // muiTheme.spacing(3) px
    });

    const uiTheme = createUiTheme(muiTheme, {
      LocaleDate: {
        defaultProps: {},
        formatters: {
          dateTime: new Intl.DateTimeFormat(['en-GB'], {
            dateStyle: 'short',
            timeStyle: 'short'
          }),
          date: new Intl.DateTimeFormat(['en-GB'], {
            dateStyle: 'short'
          })
        }
      }
    });

    const uiApolloTheme = createUiApolloTheme(muiTheme, {
      pagination: {
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 20, 50]
      }
    });

    const adminTheme = createUiAdminTheme(muiTheme, {
      NavigationPanel: {
        layerStyle: {}
      }
    });

    const theme: Theme = {
      ...muiTheme,
      ...uiTheme,
      ...formikTheme,
      ...adminTheme,
      ...uiApolloTheme
    };

    return theme;
  }, [dark, isProd]);
}

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends UiTheme, UiAdminTheme, UiApolloTheme, UiFormikTheme {}

  interface Palette {
    accent: Palette['primary'];
  }

  interface PaletteOptions {
    accent?: PaletteOptions['primary'];
  }
}
