import { call } from '@everlutionsk/helpers';
import { Button } from '@everlutionsk/ui';
import { ConditionalForm, Fields, TextField, createFormSpec } from '@everlutionsk/ui-formik';
import { useNavigate } from '@everlutionsk/ui-router';
import { Box, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { routing } from '../Routes';
import { ContentHeader } from './ContentHeader';
import { FormButton } from './FormButton';
import { PhoneNumberField } from './PhoneNumberField';
import { useIdentity } from './hooks/useIdentity';

interface ChangeUserDataProps {
  breadcrumbs?: { id: string; fullName: string };
  buttonTitle: string;
  onDecline?: (userId: string) => void;
  onSubmit: (values: {
    firstName?: string;
    lastName?: string;
    title?: string;
    phoneNumber?: string;
  }) => void;
  data?: {
    id: string;
    firstName: string | undefined | null;
    lastName: string | undefined | null;
    title: string | undefined | null;
    phoneNumber: string | undefined | null;
    requestedChanges?: boolean | undefined | null;
  };
  disableButton?: ({
    firstName,
    lastName,
    phoneNumber,
    title
  }: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    title: string;
  }) => boolean;
}

export function ChangeUserData({
  onSubmit,
  onDecline,
  data,
  buttonTitle,
  breadcrumbs,
  disableButton
}: ChangeUserDataProps) {
  const formSpec = useMemo(() => {
    return createFormSpec({
      firstName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      title: yup.string().nullable(),
      phoneNumber: yup
        .string()
        .min(9, 'Telefónne číslo musí obsahovať 9 číslic')
        .max(9, 'Telefónne číslo musí obsahovať 9 číslic')
        .nullable()
    });
  }, []);
  const identity = useIdentity();
  if (identity == null) throw new Error('Invalid identity');

  const backTo = ['admin', 'superAdmin'].includes(identity.role) ? null : routing.users.list;
  const navigate = useNavigate();

  return (
    <>
      {breadcrumbs != null && (
        <ContentHeader
          title={'Zmena údajov'}
          breadcrumbs={[
            { title: 'Domov' },
            { title: 'Zamestnanci', path: routing.users.list },
            {
              title: `${breadcrumbs.fullName}`,
              path: routing.user.profile(breadcrumbs.id)
            },
            call(() => {
              if (!['admin', 'superAdmin'].includes(identity.role)) return { title: '' };
              return { title: 'Zmena údajov' };
            })
          ]}
        />
      )}
      <Formik
        {...formSpec({
          firstName: data?.firstName ?? '',
          lastName: data?.lastName ?? '',
          title: data?.title ?? '',
          phoneNumber: data?.phoneNumber ?? ''
        })}
        onSubmit={({ firstName, lastName, phoneNumber, title }) => {
          return onSubmit({ firstName, lastName, phoneNumber, title });
        }}
      >
        {({ values: { firstName, lastName, phoneNumber, title } }) => (
          <ConditionalForm>
            <Fields>
              <TextField name="firstName" label="Meno" />
              <TextField name="lastName" label="Priezvisko" />
              <TextField name="title" label="Titul" />
              <PhoneNumberField />
            </Fields>
            <Box my={3} display="flex" flexDirection="column" alignItems="center">
              {data?.requestedChanges && <Box mb={3}>Žiadosť bola odoslaná na posúdenie.</Box>}
              <FormButton
                label={buttonTitle}
                backTo={backTo}
                disabled={
                  disableButton && disableButton({ firstName, lastName, phoneNumber, title })
                }
              />
              {['admin', 'superAdmin'].includes(identity.role) && (
                <>
                  <Box my={1}>
                    <Typography variant="caption">alebo</Typography>
                  </Box>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (onDecline != null && data?.id != null) return onDecline(data.id);
                    }}
                  >
                    Zamietnuť zmeny
                  </Button>
                  <Button
                    style={{
                      marginTop: '30px',
                      width: '150px'
                    }}
                    color="secondary"
                    variant="outlined"
                    onClick={() => navigate(routing.users.list)}
                  >
                    Späť
                  </Button>
                </>
              )}
            </Box>
          </ConditionalForm>
        )}
      </Formik>
    </>
  );
}
