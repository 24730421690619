import {
  ConditionalForm,
  createFormSpec,
  Fields,
  RadioField,
  TextField
} from '@everlutionsk/ui-formik';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { FormButton } from '../../../components/FormButton';
import { OrganisationOneQuery, OrganisationStatus } from '../../../graphql/types';
import { routing } from '../../../Routes';

interface SubmitProps {
  name: string;
  status?: OrganisationStatus;
  city: string;
  line1: string;
  region: string;
  district: string;
}

export function OrganisationForm({
  buttonText,
  onSubmit,
  data
}: {
  buttonText: string;
  onSubmit: (value: SubmitProps) => void;
  data?: OrganisationOneQuery['organisationOne'];
}) {
  return (
    <Formik
      enableReinitialize
      {...formSpec({
        name: data?.name ?? '',
        status: data?.status,
        city: data?.city ?? '',
        line1: data?.line1 ?? '',
        region: data?.region ?? '',
        district: data?.district ?? ''
      })}
      onSubmit={({ name, status, line1, city, region, district }) =>
        onSubmit({ name, status, city, line1, region, district })
      }
    >
      <ConditionalForm>
        <Fields>
          <TextField name="name" label="Názov organizácie*" />
          <TextField name="city" label="Mesto*" />
          <TextField name="line1" label="Ulica*" />
          <TextField name="region" label="Kraj*" />
          <TextField name="district" label="Okres*" />
          {data && (
            <RadioField
              name="status"
              options={[
                {
                  label: 'aktívna',
                  value: OrganisationStatus.enable
                },
                {
                  label: 'neaktívna',
                  value: OrganisationStatus.disabled
                }
              ]}
            />
          )}
        </Fields>

        <Box my={3}>
          <FormButton label={buttonText} backTo={routing.organisations.list} />
        </Box>
      </ConditionalForm>
    </Formik>
  );
}

const formSpec = createFormSpec({
  name: yup.string().nullable().required(`Názov organizácie je povinný údaj`),
  line1: yup.string().nullable().required(`Ulica je povinný údaj`),
  city: yup.string().nullable().required(`Mesto je povinný údaj`),
  region: yup.string().nullable().required(`Kraj je povinný údaj`),
  district: yup.string().nullable().required(`Okres je povinný údaj`),
  status: yup.string()
});
