import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

interface Props {
  data: {
    fullName: string;
    dateOfBirth: Date;
    gender: string;
    testingDate: Date;
    details: {
      ratingSlovakLanguage?: number;
      ratingMaths?: number;
      ratingForeignLanguage?: number;
      motherEducation?: number;
      fatherEducation?: number;
      postponement?: number;
      grade?: number;
      repeatedGrade?: number;
      articleId?: string;
      readingEvaluation?: number;
    };
  };
}

const borderStyle = '1px solid #d6d6d6';

export function TwoColumnClientTable({ data }: Props) {
  return (
    <Box display="flex" flexDirection="row" width="100%" boxShadow={2}>
      <Box display="flex" flexDirection="column" width="50%" height="100%">
        <Row label="Identifikátor klienta:" value={data.fullName} />
        <Row label="Narodený:" value={formatToDateOfBirth(data.dateOfBirth)} />
        <Row label="Pohlavie:" value={data.gender === 'male' ? 'Muž' : 'Žena'} />
        <Row label="Odklad školskej dochádzky:" value={yesNoPrettify(data.details.postponement)} />
        <Row
          label="Známka zo Slovenčiny:"
          value={ratingPrettify(data.details.ratingSlovakLanguage)}
        />
        <Row
          label="Známka z Cudzieho jazyka:"
          value={ratingPrettify(data.details.ratingForeignLanguage)}
        />
        <Row label="Vzdelanie matky:" value={educationPrettify(data.details.motherEducation)} />
      </Box>
      <Box display="flex" flexDirection="column" width="50%" height="100%">
        <Row label="Dátum testovania:" value={formatDate(data.testingDate)} />
        <Row label="Čítaný text:" value={data.details.articleId ?? '-'} />
        <Row label="Trieda:" value={data.details.grade ?? '-'} />
        <Row label="Opakoval klient triedu:" value={yesNoPrettify(data.details.repeatedGrade)} />
        <Row label="Známka z Matematiky:" value={ratingPrettify(data.details.ratingMaths)} />
        <Row
          label="Hodnotenie učiteľom:"
          value={readingEvaluationPrettify(data.details.readingEvaluation)}
        />
        <Row label="Vzdelanie otca:" value={educationPrettify(data.details.fatherEducation)} />
      </Box>
    </Box>
  );
}

function Row({ label, value }: { label: string; value: string | number }) {
  return (
    <Box>
      <Box display="flex" sx={{ borderBottom: borderStyle }}>
        <Box
          width="30%"
          textAlign="left"
          sx={{
            bgcolor: '#f8f8f8'
          }}
          p={1}
        >
          <Typography variant="body2">{label}</Typography>
        </Box>
        <Box width="70%" textAlign="right" sx={{ bgcolor: '#ffffff' }} p={1}>
          <Typography variant="body2"> {value}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

function formatToDateOfBirth(value: Date) {
  return format(value, 'LLLL yyyy');
}

function formatDate(value: Date) {
  return format(value, 'dd. MM. yyyy');
}

function educationPrettify(value: number | undefined): string {
  if (value === 1) return 'základné, neúplné';
  if (value === 2) return 'základné, nevyučený';
  if (value === 3) return 'základné, vyučený';
  if (value === 4) return 'stredné, maturita';
  if (value === 5) return 'vysokoškolské';

  return '-';
}

function yesNoPrettify(value: number | undefined): string {
  if (value === 0) return 'nie';
  if (value === 1) return 'áno';

  return '-';
}

function ratingPrettify(value: number | undefined): string {
  if (value === 1) return '1';
  if (value === 2) return '2';
  if (value === 3) return '3';
  if (value === 4) return '4';
  if (value === 5) return '5';

  return '-';
}

function readingEvaluationPrettify(value: number | undefined): string {
  if (value === 1) return 'výborný';
  if (value === 2) return 'veľmi dobrý';
  if (value === 3) return 'priemerný';
  if (value === 4) return 'podpriemerný';
  if (value === 5) return 'hlboko podpriemerný';

  return '-';
}
