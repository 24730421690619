import { gql } from '@apollo/client';
import { Table } from '../../components/Table';
import { useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { Link } from '@everlutionsk/ui-router';
import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { routing } from '../../Routes';
import { ContentHeader } from '../../components/ContentHeader';
import { SearchBar } from '../../components/SearchBar';
import { TableArrowForward } from '../../components/TableArrowForward';
import { Tabs } from '../../components/Tabs';
import { OrganisationOrderBy } from '../../graphql/types';
import { useIdentity } from '../../components/hooks/useIdentity';

export function OrganisationsList() {
  const identity = useIdentity();
  if (identity == null) throw Error('Access Denied! Missing valid identity.');
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [tab, setTabs] = React.useState<'all' | 'requested'>('all');

  return (
    <>
      <ContentHeader
        title="Organizácie"
        breadcrumbs={[{ title: 'Domov' }, { title: 'Organizácie' }]}
      />

      {tab === 'all' && (
        <Box mb={3}>
          <Grid container>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={8}>
              <SearchBar
                withButton
                buttonTitle="+ Pridať organizáciu"
                navigateTo={routing.organisations.add}
                onSearch={value => setSearchTerm(value)}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Tabs
        onChange={setTabs}
        value={tab}
        tabs={[
          {
            label: 'Všetky',
            value: 'all',
            node: <AllOrganisation term={searchTerm} />
          },
          ['admin', 'superAdmin'].includes(identity.role) && {
            label: 'Žiadosti',
            value: 'requested',
            node: <RequestedOrganisation />
          }
        ]}
      />
    </>
  );
}

function AllOrganisation({ term }: { term?: string }) {
  const [orderBy, setOrderBy] = useState<OrganisationOrderBy>(OrganisationOrderBy.nameAsc);

  const { data, pagination, error } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      input: { pagination: { limit, offset }, term },
      orderBy
    })
  });

  return (
    <>
      <Table
        pagination={pagination}
        items={data?.organisationMany.items}
        error={error}
        sort={{ current: orderBy, onChange: setOrderBy }}
        columns={[
          {
            label: 'Názov',
            render: ({ id, name }) => (
              <Link to={routing.organisations.edit(id)} color="secondary">
                {name}
              </Link>
            ),
            sort: {
              asc: OrganisationOrderBy.nameAsc,
              desc: OrganisationOrderBy.nameDesc,
              default: OrganisationOrderBy.nameAsc
            }
          },
          {
            label: 'Kraj',
            render: item => item.region,
            sort: {
              asc: OrganisationOrderBy.regionAsc,
              desc: OrganisationOrderBy.regionDesc,
              default: OrganisationOrderBy.regionAsc
            }
          },
          {
            label: 'Okres',
            render: item => item.district,
            sort: {
              asc: OrganisationOrderBy.districtAsc,
              desc: OrganisationOrderBy.districtDesc,
              default: OrganisationOrderBy.districtAsc
            }
          },
          {
            label: 'Mesto',
            render: item => item.city ?? '-'
          },
          {
            label: 'Ulica',
            render: item => item.line1 ?? '-'
          },
          {
            label: '',
            render: ({ id }) => <TableArrowForward to={routing.organisations.edit(id)} />
          }
        ]}
      />
    </>
  );
}

function RequestedOrganisation() {
  const { data, pagination, error } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      input: { pagination: { limit, offset }, requestedChanges: true }
    })
  });

  return (
    <>
      <Table
        pagination={pagination}
        items={data?.organisationMany.items}
        error={error}
        columns={[
          {
            label: 'Názov',
            render: ({ id, name }) => (
              <Link to={routing.organisations.edit(id)} color="secondary">
                {name}
              </Link>
            )
          },
          {
            label: 'Nový názov',
            render: ({ requestedChanges }) => requestedChanges?.name ?? '-'
          },
          {
            label: 'Nové mesto',
            render: ({ requestedChanges }) => requestedChanges?.city ?? '-'
          },
          {
            label: 'Nová ulica',
            render: ({ requestedChanges }) => requestedChanges?.line1 ?? '-'
          },
          {
            label: 'Nový okres',
            render: ({ requestedChanges }) => requestedChanges?.region ?? '-'
          },
          {
            label: 'Nový kraj',
            render: ({ requestedChanges }) => requestedChanges?.district ?? '-'
          },
          {
            label: '',
            render: ({ id }) => <TableArrowForward to={`organisations/requested-changes/${id}`} />
          }
        ]}
      />
    </>
  );
}

export const query = gql<OrganisationManyQueryGQL>`
  query OrganisationManyQuery($input: OrganisationFilterInput!, $orderBy: OrganisationOrderBy) {
    organisationMany(input: $input, orderBy: $orderBy) {
      total
      items {
        id
        name
        requestedChanges {
          name
          city
          line1
          region
          district
        }
        city
        line1
        region
        district
      }
    }
  }
`;
