import { Table } from '@app/admin/src/components/Table';
import { Box, styled } from '@mui/material';
import React from 'react';
import { T202EvaluationFragment } from '../../../../../graphql/types';

interface Props {
  data: T202EvaluationFragment;
}

export function T202ResultsTable1({ data }: Props) {
  return (
    <TableWrapper mt={2}>
      <Table
        items={[
          {
            id: 'za 1. minútu',
            wordCount: data.firstMinuteWord,
            mistakes: data.mistakesFirstMinute,
            mistakesRating: data.evaluationMistakesFirstMinute,
            hs: data.scoreFirstMinute,
            percentile: data.mistakesPercentageFirstMinute,
            sten: data.sten
          },
          {
            id: 'za 2. minútu ',
            wordCount: data.secondMinuteWord,
            mistakes: data.mistakesSecondMinute,
            hs: data.scoreSecondMinute,
            percentile: data.mistakesPercentageSecondMinute
          },
          {
            id: 'za 3. minútu',
            wordCount: data.thirdMinuteWord,
            mistakes: data.mistakesThirdMinute,
            hs: data.scoreThirdMinute,
            percentile: data.mistakesPercentageThirdMinute
          }
        ]}
        columns={[
          {
            label: '',
            render: ({ id }) => id
          },
          {
            label: 'počet slov',
            render: ({ wordCount }) => wordCount
          },
          {
            label: 'počet chýb',
            render: ({ mistakes }) => mistakes
          },
          {
            label: 'počet chýb za 1. minútu - hodnotenie',
            render: ({ mistakesRating }) => mistakesRating
          },
          {
            label: 'percento chýb za 1. minútu',
            render: ({ percentile }) => (percentile === -1 ? '-' : `${percentile.toFixed(2)}%`)
          },
          {
            label: 'percento chýb za 1. minútu - hodnotenie',
            render: ({ mistakesRating }) => mistakesRating
          },
          {
            label: 'rýchlosť čítania / počet správne prečítaných slov za 1. minútu',
            render: ({ hs }) => hs
          },
          {
            label: 'sten za 1. minútu',
            render: ({ sten }) => (sten === -1 ? '-' : sten)
          }
        ]}
      />
    </TableWrapper>
  );
}

const TableWrapper = styled(Box)`
  thead {
    background-color: #f8f8f8;
  }
`;
