import { gql, useQuery } from '@apollo/client';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { useNavigate, useParams } from '@everlutionsk/ui-router';
import React from 'react';
import { ContentHeader } from '../../components/ContentHeader';
import { FormWrapper } from '../../components/FormWrapper';
import { OrganisationStatus } from '../../graphql/types';
import { routing } from '../../Routes';
import { OrganisationForm } from './Forms/OrganisationForm';

export function EditOrganisation() {
  const { id } = useParams(['id']);
  const navigate = useNavigate();

  const { data } = useQuery(query, {
    variables: {
      id
    }
  });

  const [mutate] = useFlashMutation(mutation, {
    successMsg: 'Zmeny boli ulozené',
    errorMsg: ' Zmeny neboli ulozené',
    onCompleted: () => navigate(routing.organisations.list)
  });

  return (
    <>
      <ContentHeader
        title="Upraviť organizáciu"
        breadcrumbs={[
          { title: 'Domov' },
          { title: 'Organizácie', path: routing.organisations.list },
          { title: 'Upraviť organizáciu' }
        ]}
      />
      <FormWrapper>
        <OrganisationForm
          buttonText="Uložiť zmeny"
          data={data?.organisationOne}
          onSubmit={({ name, status, city, line1, region, district }) =>
            mutate({
              variables: {
                input: {
                  id,
                  name,
                  city,
                  line1,
                  region,
                  district,
                  status: status ?? OrganisationStatus.enable
                }
              },
              update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'organisationMany' });
              }
            })
          }
        />
      </FormWrapper>
    </>
  );
}

const query = gql<OrganisationOneGQL>`
  query OrganisationOne($id: ID!) {
    organisationOne(id: $id) {
      id
      name
      status
      line1
      city
      region
      district
    }
  }
`;

const mutation = gql<EditOrganisationGQL>`
  mutation EditOrganisation($input: EditOrganisationInput!) {
    editOrganisation(input: $input) {
      id
      name
      status
    }
  }
`;
