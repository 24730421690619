import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routingPatterns } from '../../../../Routes';
import { Overview } from './Overview';
import { NewGroupForm } from './NewGroupForm';
import { SelectGroupForm } from './SelectGroupForm';

export function GroupForm() {
  return (
    <>
      <Routes>
        <Route path={routingPatterns.diagnostic.prepare.group.overview} element={<Overview />} />
        <Route path={routingPatterns.diagnostic.prepare.group.new} element={<NewGroupForm />} />
        <Route
          path={routingPatterns.diagnostic.prepare.group.select}
          element={<SelectGroupForm />}
        />
      </Routes>
    </>
  );
}
