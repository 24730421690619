import { Table } from '@app/admin/src/components/Table';
import { Box, styled, Typography } from '@mui/material';
import React from 'react';

type TableItem = {
  __typename: 'T252EvaluationItem';
  id: string;
  label: string;
  roughScore: number;
  degree: number;
  stenScore: string;
  percentile: number;
};

interface Props {
  label: string;
  firstColumnName: string;
  data: TableItem[];
}

export function T252ResultsTable({ label, firstColumnName, data }: Props) {
  return (
    <TableWrapper mt={2}>
      <Typography variant="h6">{label}</Typography>
      <Table
        items={data}
        columns={[
          {
            label: firstColumnName,
            render: ({ label }) => label
          },
          {
            label: 'Hrubé skóre',
            render: ({ roughScore }) => roughScore
          },
          {
            label: 'Percentil',
            render: ({ percentile }) => percentile
          },
          {
            label: 'Sten',
            render: ({ stenScore }) => stenScore
          },
          {
            label: 'Stupeň',
            render: ({ degree }) => degree
          }
        ]}
      />
    </TableWrapper>
  );
}

const TableWrapper = styled(Box)`
  thead {
    background-color: #f8f8f8;
  }
`;
