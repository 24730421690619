import { Box, Typography } from '@mui/material';
import React from 'react';
import { T21EvaluationFragment } from '../../../../../graphql/types';

interface Props {
  data: T21EvaluationFragment;
}

const borderStyle = '1px solid #d6d6d6';

export function T21ResultsTable({ data }: Props) {
  return (
    <>
      <Box display="flex" flexDirection="column" width="100%" boxShadow={2}>
        <Row label="" value={{ p: 'P', e: 'E', n: 'N', l: 'L', s: 'Soc', i: 'Imp' }} header />
        <Row
          label="Hrubé skóre"
          value={{ ...data.score, s: data.score.s ?? '-', i: data.score.i ?? '-' }}
        />
        <Row
          label="Percentil"
          value={{
            p: data.percentile.p ?? '-',
            e: data.percentile.e ?? '-',
            n: data.percentile.n ?? '-',
            l: data.percentile.l ?? '-',
            s: data.percentile.s ?? '-',
            i: data.percentile.i ?? '-'
          }}
        />
      </Box>
    </>
  );
}

function Row({
  label,
  value,
  header
}: {
  label: string;
  value: {
    p: number | string;
    e: number | string;
    n: number | string;
    l: number | string;
    s: number | string;
    i: number | string;
  };
  header?: boolean;
}) {
  return (
    <Box display="flex">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="20%"
        border={borderStyle}
      >
        <Typography variant="body2"> {label}</Typography>
      </Box>

      <Box
        bgcolor={header ? undefined : '#fff'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="20%"
        border={borderStyle}
      >
        <Typography variant="body2"> {value.p}</Typography>
      </Box>

      <Box width="20%" border={borderStyle} bgcolor={header ? undefined : '#fff'}>
        {header && (
          <Box p={1} display="flex" justifyContent="center" borderBottom={borderStyle}>
            <Typography variant="body2"> {value.e}</Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="space-between" style={{ backgroundColor: '#fff' }}>
          <Box p={1} width="33%" display="flex" justifyContent="center">
            {!header && <Typography variant="body2"> {value.e}</Typography>}
          </Box>
          <Box
            p={1}
            width="33%"
            display="flex"
            justifyContent="center"
            borderLeft={borderStyle}
            borderRight={borderStyle}
          >
            <Typography variant="body2"> {value.i}</Typography>
          </Box>
          <Box p={1} width="33%" display="flex" justifyContent="center">
            <Typography variant="body2"> {value.s}</Typography>
          </Box>
        </Box>
      </Box>

      <Box
        bgcolor={header ? undefined : '#fff'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="20%"
        border={borderStyle}
      >
        <Typography variant="body2"> {value.n}</Typography>
      </Box>

      <Box
        bgcolor={header ? undefined : '#fff'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="20%"
        border={borderStyle}
      >
        <Typography variant="body2"> {value.l}</Typography>
      </Box>
    </Box>
  );
}
