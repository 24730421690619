import { gql, useQuery } from '@apollo/client';
import { call, isNotNil, populated } from '@everlutionsk/helpers';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Routes } from 'react-router-dom';
import { routing } from '../../../Routes';
import { SafeRoute } from '../../../components/AuthenticatedRoute';
import { ChangeUserData } from '../../../components/ChangeUserData';
import { ContentHeader } from '../../../components/ContentHeader';
import { CustomChip } from '../../../components/CustomChip';
import { FormWrapper } from '../../../components/FormWrapper';
import { UserStatus } from '../../../graphql/types';
import { prettifyStatus } from '../../../helpers';
import { EditPosition } from '../components/EditPosition';
// import { PasswordChange } from '../passwordChange';
import { Identity, useIdentity } from '../../../components/hooks/useIdentity';
import { Dictionary, NavigationLayer, useNavigationLayer } from '@everlutionsk/ui-admin';

function navigationLayer({ isPsychologist }: { isPsychologist: boolean }): NavigationLayer {
  return {
    title: '',
    items: [
      isPsychologist
        ? {
            title: 'Môj Profil',
            icon: () => <i className="icon-overview" />,
            path: 'my-profile/details',
            exact: true
          }
        : undefined
      // {
      //   title: 'Zmena hesla',
      //   icon: () => <i className="icon-overview" />,
      //   path: 'my-profile/password-change',
      //   exact: true
      // }
    ].filter(isNotNil)
  };
}

export function MyProfile() {
  const identity = useIdentity();
  if (identity == null) throw new Error('Access denied!');

  useNavigationLayer(
    navigationLayer({ isPsychologist: ['owner', 'psychologist'].includes(identity?.role) })
  );

  return (
    <Routes>
      <SafeRoute
        allowedRoles={['owner', 'psychologist']}
        path="details"
        element={<Profile identity={identity} />}
      />
      {/* <Route path="password-change" element={<PasswordChange />} /> */}
    </Routes>
  );
}

function Profile({ identity }: { identity: Identity }) {
  if (identity == null) throw new Error('Access denied!');
  const { data } = useQuery(query, {
    variables: {
      id: identity.id
    }
  });

  const [requestEditMutate] = useFlashMutation(requestEditMutation, {
    successMsg: 'Zmeny boli uložené a čakajú na schválenie riaditeľom organizácie.',
    errorMsg: 'Zmeny neboli uložené.'
  });

  return (
    <>
      {populated(data) && (
        <>
          <ContentHeader
            title={data.userOne.fullName}
            breadcrumbs={[{ title: 'Domov', path: routing.dashboard }, { title: 'Profil' }]}
          />
          <Grid container>
            <Grid item xs={12} sm={12} md={10}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Box>
                  Status:{' '}
                  <CustomChip
                    color={data.userOne.status === UserStatus.active ? 'success' : 'disabled'}
                    label={prettifyStatus(data.userOne.status)}
                  />
                  {call(() => {
                    if (data?.userOne.organisation == null) return;
                    const { name, city, line1 } = data.userOne.organisation;

                    return (
                      <Box mt={1}>
                        <Typography variant="body1">
                          Organizácia: <b>{name}</b>{' '}
                        </Typography>
                        {city && line1 && (
                          <Typography variant="body2">
                            {city} {line1}
                          </Typography>
                        )}
                        {identity.role === 'owner' && (
                          <Typography variant="h6">Riadi a spravuje organizáciu</Typography>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box mb={3}>
            <FormWrapper>
              <EditPosition userId={identity.id} />
            </FormWrapper>
          </Box>
          <FormWrapper>
            <Dictionary>
              {[
                ['Meno', data.userOne.firstName],
                ['Priezvisko', data.userOne.lastName],
                ['Titul', data.userOne.title],
                ['Telefón', data.userOne.phoneNumber]
              ]}
            </Dictionary>

            <ChangeUserData
              data={{
                id: data.userOne.id,
                firstName: data.userOne.requestedChanges?.firstName ?? '',
                lastName: data.userOne.requestedChanges?.lastName ?? '',
                phoneNumber: data.userOne.requestedChanges?.phoneNumber ?? '',
                title: data.userOne.requestedChanges?.title ?? '',
                requestedChanges: data.userOne.requestedChanges != null
              }}
              disableButton={({ firstName, lastName, phoneNumber, title }) =>
                disableButtonHelper({
                  firstName,
                  lastName,
                  phoneNumber,
                  title,
                  data: {
                    id: data.userOne.id,
                    firstName: data.userOne.requestedChanges?.firstName ?? '',
                    lastName: data.userOne.requestedChanges?.lastName ?? '',
                    phoneNumber: data.userOne.requestedChanges?.phoneNumber ?? '',
                    title: data.userOne.requestedChanges?.title ?? ''
                  }
                })
              }
              buttonTitle="Vyžiadať zmeny"
              onSubmit={({ firstName, lastName, title, phoneNumber }) => {
                return requestEditMutate({
                  variables: {
                    input: {
                      id: data.userOne.id,
                      firstName,
                      lastName,
                      title,
                      phoneNumber
                    }
                  }
                });
              }}
            />
          </FormWrapper>
        </>
      )}
    </>
  );
}

function disableButtonHelper({
  data,
  firstName,
  lastName,
  phoneNumber,
  title
}: {
  data:
    | {
        id: string;
        firstName: string | undefined | null;
        lastName: string | undefined | null;
        title: string | undefined | null;
        phoneNumber: string | undefined | null;
        requestedChanges?: boolean | undefined | null;
      }
    | undefined;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  title: string;
}): boolean {
  const {
    firstName: dataFirstName,
    lastName: dataLastName,
    phoneNumber: dataPhoneNumber,
    title: dataTitle
  } = data ?? {};

  if (
    !firstName.localeCompare(dataFirstName ?? '') &&
    !lastName.localeCompare(dataLastName ?? '') &&
    !phoneNumber.localeCompare(dataPhoneNumber ?? '') &&
    !title.localeCompare(dataTitle ?? '')
  )
    return true;

  return false;
}

const userOneFragment = gql`
  fragment MyUserOneFragment on User {
    id
    fullName
    firstName
    lastName
    title
    role
    organisation {
      id
      name
      city
      line1
    }
    position {
      id
      name
    }
    phoneNumber
    email
    status
    requestedChanges {
      firstName
      lastName
      title
      phoneNumber
    }
  }
`;

const query = gql<UserOneMyProfileGQL>`
  query UserOneMyProfile($id: ID!) {
    userOne(id: $id) {
      ...MyUserOneFragment
    }
  }
  ${userOneFragment}
`;

const requestEditMutation = gql<RequestEditUserDataGQL>`
  mutation RequestEditUserData($input: RequestedUserChangesInput!) {
    requestEditUser(input: $input) {
      ...MyUserOneFragment
    }
  }
  ${userOneFragment}
`;
