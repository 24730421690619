import { gql } from '@apollo/client';
import { LocaleDate } from '@everlutionsk/ui';
import { Table } from '../../components/Table';
import { useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { Link } from '@everlutionsk/ui-router';
import { Box, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import { ContentHeader } from '../../components/ContentHeader';
import { Select } from '../../components/Select';
import { TestingStatus } from '../../graphql/types';
import { routing } from '../../Routes';
import { ToStudentOneNameLink } from '../Common/ToStudentOneNameLink';
import { getAge, prettifyGender } from '../Diagnostic/utils';
import { SearchRounded } from '@mui/icons-material';

export function ResultMany() {
  const [code, setCode] = useState<string | undefined>();
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const { data, pagination, error } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      input: {
        pagination: { limit, offset },
        code,
        term: searchTerm,
        status: TestingStatus.readonly
      }
    })
  });

  return (
    <>
      <ContentHeader title="Výsledky testu" />
      <Box mb={3} display="flex" justifyContent="space-between">
        <Box mb={3}>
          <Select
            defaultValue="Všetky typy testov"
            value={code}
            onSelect={value => setCode(value)}
            items={values}
          />
        </Box>
        <Box width="50%">
          <TextField
            size="small"
            name="search"
            label="Hľadať"
            placeholder="Vyhľadávanie"
            variant="outlined"
            onChange={e => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRounded />
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Box>
      <Table
        pagination={pagination}
        items={data?.testingMany.items}
        error={error}
        columns={[
          {
            label: 'Dátum',
            render: item => <LocaleDate date={item.createdAt} variant="dateTime" />
          },
          {
            label: 'Meno žiaka',
            render: item => ToStudentOneNameLink(item.student)
          },
          {
            label: 'Vek',
            render: item => getAge(item.student.dateOfBirth)
          },
          {
            label: 'Pohlavie',
            render: item =>
              item.student && item.student.gender ? prettifyGender(item.student.gender) : '-'
          },
          {
            label: 'Škola',
            render: item => item.student.school
          },
          {
            label: 'Diagnostika',
            render: item => item.student.diagnosis?.content
          },
          {
            label: 'Odborník',
            render: item => item.student.owner?.fullName
          },
          {
            label: 'Výsledky',
            render: item => (
              <Link to={routing.testResults.detail(item.originalToken)} color="secondary">
                Ukáž
              </Link>
            )
          }
        ]}
      />
    </>
  );
}

export const query = gql<TestResultsQueryGQL>`
  query TestResultsQuery($input: TestingFilterInput!) {
    testingMany(input: $input) {
      total
      items {
        id
        code
        originalToken
        student {
          id
          firstName
          lastName
          fullName
          dateOfBirth
          gender
          school
          diagnosis {
            content
          }
          owner {
            id
            fullName
          }
        }
        createdAt
      }
    }
  }
`;

const values = [
  { label: 'T-184', value: 'T-184' },
  { label: 'T-202', value: 'T-202' },
  { label: 'T-21', value: 'T-21' },
  { label: 'T-216', value: 'T-216' },
  { label: 'T-239', value: 'T-239' },
  { label: 'T-252', value: 'T-252' },
  { label: 'T-309', value: 'T-309' },
  { label: 'T-41', value: 'T-41' },
  { label: 'T-8', value: 'T-8' }
];
