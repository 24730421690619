import { gql } from '@apollo/client';
import { call, populated } from '@everlutionsk/helpers';
import { Button } from '@everlutionsk/ui';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { useNavigate, useParams } from '@everlutionsk/ui-router';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Card
} from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { routing } from '../../../Routes';
import { ContentHeader } from '../../../components/ContentHeader';
import { CustomChip } from '../../../components/CustomChip';
import { Role, UserOneProfileQuery, UserStatus } from '../../../graphql/types';
import { prettifyStatus } from '../../../helpers';
import { UserProfileForm } from '../components/UserProfileForm';
import { useIdentity } from '../../../components/hooks/useIdentity';
import { Tabs } from '../../../components/Tabs';
import { Accesses } from '../components/Accesses';

export function Profile({ data }: { data: UserOneProfileQuery | undefined }) {
  const [dialog, setDialog] = useState<{ open: boolean; operation: 'reject' | 'delete' }>({
    open: false,
    operation: 'reject'
  });
  const { id } = useParams(['id']);
  const identity = useIdentity();
  const navigate = useNavigate();

  const [mutate] = useFlashMutation(mutation, {
    successMsg: 'Zmeny boli uložené.',
    errorMsg: 'Zmeny neboli uložené.'
  });
  const [toggle] = useFlashMutation(toggleMutation, {
    successMsg: 'Status bol úspešne zmenený.',
    errorMsg: e => e.message
  });

  const [remove] = useFlashMutation(removeMutation, {
    successMsg: 'Status bol úspešne zmenený',
    errorMsg: e => 'Nepodarilo sa vymazať žiadosť.'
  });

  type Tab = 'detail' | 'accesses';

  const [tab, setTab] = React.useState<Tab>('detail');

  return (
    <>
      {populated(data) && (
        <>
          <ContentHeader
            title={data.userOne.fullName}
            breadcrumbs={[
              { title: 'Domov' },
              { title: 'Zamestnanci', path: routing.users.list },
              {
                title: `${data.userOne.firstName} ${data.userOne.lastName}`,
                path: routing.user.profile(id)
              },
              { title: 'Profil' }
            ]}
          />
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Box>
                  Status:{' '}
                  <CustomChip
                    color={data.userOne.status === UserStatus.active ? 'success' : 'disabled'}
                    label={prettifyStatus(data.userOne.status)}
                  />
                  {call(() => {
                    if (identity?.role === Role.superAdmin || identity?.role === Role.admin) return;
                    if (data?.userOne.organisation == null) return;
                    const { name, city, line1 } = data.userOne.organisation;

                    return (
                      <Box mt={1}>
                        <Typography variant="body1">
                          Organizácia: <b>{name}</b>{' '}
                        </Typography>
                        {city && line1 && (
                          <Typography variant="body2">
                            {city} {line1}
                          </Typography>
                        )}
                      </Box>
                    );
                  })}
                </Box>

                {(identity?.role === Role.superAdmin || identity?.role === Role.admin) && (
                  <RoleChangeButtons
                    status={data.userOne.status}
                    setDialog={setDialog}
                    toggle={({ status }) =>
                      toggle({
                        variables: {
                          id,
                          status
                        },
                        update: cache => {
                          cache.evict({ id: 'ROOT_QUERY', fieldName: 'userMany' });
                        }
                      })
                    }
                  />
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={10}>
            <Tabs
              value={tab}
              onChange={setTab}
              tabs={[
                {
                  label: 'Osobné údaje',
                  value: 'detail',
                  node: (
                    <Card>
                      <Box p={3}>
                        <UserProfileForm
                          type="edit"
                          firstName={data.userOne.firstName}
                          lastName={data.userOne.lastName}
                          organisation={
                            data.userOne.organisation
                              ? {
                                  name: data.userOne.organisation?.name,
                                  id: data.userOne.organisation?.id,
                                  city: data.userOne.organisation.city ?? '',
                                  line1: data.userOne.organisation.line1 ?? ''
                                }
                              : undefined
                          }
                          phoneNumber={data.userOne.phoneNumber ?? undefined}
                          title={data.userOne.title ?? undefined}
                          email={data.userOne.email ?? undefined}
                          buttonTitle="Uložiť zmeny"
                          onSubmit={values => {
                            return mutate({
                              variables: {
                                input: {
                                  ...values,
                                  id: data.userOne.id,
                                  role: undefined
                                }
                              }
                            });
                          }}
                        />
                      </Box>
                    </Card>
                  )
                },
                { label: 'Prístupy', value: 'accesses', node: <Accesses userId={id} /> }
              ]}
            />
          </Grid>
        </>
      )}
      <ConfirmDialog
        open={dialog.open}
        operation={dialog.operation}
        fullName={data?.userOne.fullName}
        onClose={() => setDialog({ ...dialog, open: false })}
        onSubmit={async () => {
          if (dialog.operation === 'delete') {
            await remove({
              variables: { id },
              update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'userMany' });
              }
            });
            navigate('/users', { state: { status: 'requested' } });
          } else
            await toggle({
              variables: {
                id,
                status: UserStatus.rejected
              },
              update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'userMany' });
              }
            });
          setDialog({ ...dialog, open: false });
        }}
      />
    </>
  );
}

function RoleChangeButtons({
  status,
  toggle,
  setDialog
}: {
  status: UserStatus;
  toggle: ({ status }: { status: UserStatus }) => void;
  setDialog: ({ open, operation }: { open: boolean; operation: 'reject' | 'delete' }) => void;
}) {
  const buttons: ReactNode[] = new Array<ReactNode>();

  if (status !== UserStatus.deleted)
    buttons.push(
      <Box key="activate">
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            toggle({
              status: status === UserStatus.active ? UserStatus.inactive : UserStatus.active
            });
          }}
        >
          {status === UserStatus.active ? 'Deaktivovať' : 'Aktivovať'}
        </Button>
      </Box>
    );

  if (
    status !== UserStatus.onHold &&
    status !== UserStatus.rejected &&
    status !== UserStatus.active &&
    status !== UserStatus.deleted &&
    status !== UserStatus.inactive
  )
    buttons.push(
      <Box ml={1} key="onHold">
        <Button
          style={{ color: 'blue', borderColor: 'blue' }}
          variant="outlined"
          onClick={() => {
            toggle({
              status: UserStatus.onHold
            });
          }}
        >
          Pozastaviť žiadosť
        </Button>
      </Box>
    );

  if (
    status !== UserStatus.rejected &&
    status !== UserStatus.active &&
    status !== UserStatus.deleted &&
    status !== UserStatus.inactive
  )
    buttons.push(
      <Box ml={1} key="reject">
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            setDialog({ open: true, operation: 'reject' });
          }}
        >
          Zamietnuť žiadosť
        </Button>
      </Box>
    );

  if (
    status !== UserStatus.deleted &&
    status !== UserStatus.active &&
    status !== UserStatus.inactive
  )
    buttons.push(
      <Box ml={1} key="delete">
        <Button
          key="delete"
          style={{ color: 'red', borderColor: 'red' }}
          variant="outlined"
          onClick={() => {
            setDialog({ open: true, operation: 'delete' });
          }}
        >
          Zmazať žiadosť
        </Button>
      </Box>
    );

  return (
    <Box display="flex" alignItems="right">
      {buttons}
    </Box>
  );
}

function ConfirmDialog({
  open,
  operation,
  fullName,
  onClose,
  onSubmit
}: {
  open: boolean;
  operation: 'reject' | 'delete';
  fullName: string | undefined;
  onClose: () => void;
  onSubmit: () => void;
}) {
  const label = operation === 'reject' ? 'Zamietnuť' : 'Zmazať';

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>{label} žiadosť</DialogTitle>
      <DialogContent>
        Ste si istý, že chcete {label.toLocaleLowerCase()} žiadosť o nové konto pre používateľa{' '}
        <b>{fullName}</b>?
      </DialogContent>

      <DialogActions>
        <Button
          style={{ color: 'white', borderColor: 'red', backgroundColor: 'red' }}
          variant="contained"
          onClick={() => onSubmit()}
        >
          {label} žiadosť
        </Button>
        <Button onClick={() => onClose()}>Zrušiť</Button>
      </DialogActions>
    </Dialog>
  );
}

export const userOneFragment = gql`
  fragment UserOneFragment on User {
    id
    fullName
    firstName
    lastName
    title
    role
    organisation {
      id
      name
      city
      line1
    }
    position {
      id
      name
    }
    phoneNumber
    email
    status
  }
`;

const mutation = gql<EditUserProfileGQL>`
  mutation EditUserProfile($input: EditUserInput!) {
    editUser(input: $input) {
      ...UserOneFragment
    }
  }
  ${userOneFragment}
`;

const toggleMutation = gql<UserStatusMutationGQL>`
  mutation UserStatusMutation($id: ID!, $status: UserStatus!) {
    updateUserStatus(id: $id, status: $status) {
      ...UserOneFragment
    }
  }
  ${userOneFragment}
`;

const removeMutation = gql<RemoveUserMutationGQL>`
  mutation RemoveUserMutation($id: ID!) {
    removeUser(id: $id) {
      ...UserOneFragment
    }
  }
  ${userOneFragment}
`;
