import { useMemo } from 'react';

export const useTotalNumberDescription = (totalProducts: number) => {
  const totalNumberDescription = useMemo(() => {
    const endings = {
      1: 'ok',
      2: 'ky',
      3: 'ky',
      4: 'ky'
    };

    const endingOfWord = endings[totalProducts] || 'kov';
    return `výsled${endingOfWord} podľa Vášho filtra`;
  }, [totalProducts]);

  return {
    totalNumberDescription
  };
};
