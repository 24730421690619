import { call } from '@everlutionsk/helpers';
import { Button, Centered, useBrowserLocation } from '@everlutionsk/ui';
import { useNavigate } from '@everlutionsk/ui-router';
import { Typography } from '@mui/material';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { sso } from '../../sso';

type ErrorPageType = '403' | undefined;

interface ErrorPageContextType {
  errorPage: ErrorPageType;
  setErrorPage: (error: ErrorPageType) => void;
}

const errorPageContext = createContext<ErrorPageContextType | undefined>(undefined);
const { Provider } = errorPageContext;

export function ErrorPageProvider(props: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [errorPage, setErrorPage] = useState<
    { page: ErrorPageType; locationKey: string | undefined } | undefined
  >(undefined);

  const location = useBrowserLocation();
  useEffect(() => {
    setErrorPage(undefined);
  }, [location.key]);

  const children = call(() => {
    if (errorPage?.page === '403')
      return (
        <Centered>
          <Typography variant="h1">
            Na túto stránku nemáte prístup, kontaktujte administrátora.
          </Typography>
          <Button
            onClick={() => {
              sso.signOut();
              navigate('/');
            }}
          >
            Odhlásiť sa
          </Button>
        </Centered>
      );

    return props.children;
  });

  return (
    <Provider
      value={{
        errorPage: errorPage?.page,
        setErrorPage: (page: ErrorPageType) => setErrorPage({ page, locationKey: location.key })
      }}
    >
      {children}
    </Provider>
  );
}

export function useErrorPage() {
  const state = useContext(errorPageContext);
  if (state == null) throw Error('Missing error page provider!');

  return { setErrorPage: state.setErrorPage };
}
