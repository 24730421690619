import { Table } from '@app/admin/src/components/Table';
import { Box, styled } from '@mui/material';
import React from 'react';
import { T202EvaluationFragment } from '../../../../../graphql/types';

interface Props {
  data: T202EvaluationFragment;
}

export function T202ResultsTable3({ data }: Props) {
  return (
    <TableWrapper mt={2}>
      <Table
        items={[
          {
            id: 'table3',
            wayOfReading: data.wayOfReading,
            readingReproduction: data.readingReproduction,
            readingReproductionScale: data.readingReproductionScale
          }
        ]}
        columns={[
          {
            label: 'Spôsob čítania:',
            render: ({ wayOfReading }) => prettifyWayOfReading(wayOfReading)
          },
          {
            label: 'Reprodukcia:',
            render: ({ readingReproduction }) => prettifyReadingReproduction(readingReproduction)
          },
          {
            label: 'Pomocná stupnica k hodnoteniu reprodukcie:',
            render: ({ readingReproductionScale }) =>
              prettifyReadingReproductionScale(readingReproductionScale)
          }
        ]}
      />
    </TableWrapper>
  );
}

function prettifyWayOfReading(value?: number | null): string {
  if (value === 0) return 'Vyberte';
  if (value === 1) return 'skupiny slov, intonácia';
  if (value === 2) return 'slová, bez zarážok';
  if (value === 3) return 'slová, neisté';
  if (value === 4) return 'slabikuje, plynulé';
  if (value === 5) return 'slabikuje, neisté';
  if (value === 6) return 'hláskuje';

  return '';
}

function prettifyReadingReproduction(value?: number | null): string {
  if (value === 0) return 'Vyberte';
  if (value === 1) return 'dokonalá, detaily';
  if (value === 2) return 'dokonalá, stručne';
  if (value === 3) return 'dobrá';
  if (value === 4) return 'podstatné časti';
  if (value === 5) return 'nespoľahlivá';
  if (value === 6) return 'útržky, bez spojitosti';
  if (value === 7) return 'celkom bez porozumenia';

  return '';
}

function prettifyReadingReproductionScale(value?: number | null): string {
  if (value === 0) return 'Vyberte';
  if (value === 1) return 'rozpráva spontánne a samostatne';
  if (value === 2) return 'rozhovorí sa po pomocnej otázke';
  if (value === 3) return 'potrebné častejšie pomáhať otázkami';
  if (value === 4) return 'rozpráva len podľa pomocných otázok, odpovedá vo vetách';
  if (value === 5) return 'rozpráva len podľa pomocných otázok, odpovedá jednoslovne';

  return '';
}

const TableWrapper = styled(Box)`
  thead {
    background-color: #f8f8f8;
  }
`;
