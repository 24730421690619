import { Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { routing } from '../../Routes';

export function ToUserOneNameLink(user: { id: string; fullName: string }) {
  if (user == null) return <></>;

  return (
    <Link to={routing.user.profile(user.id)} component={RouterLink} color="secondary">
      {user.fullName}
    </Link>
  );
}
