import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { T95EvaluationFragment } from '../../../graphql/types';
import { ClientTable } from './components/ClientTable';

export const t95EvaluationFragment = gql`
  fragment T95EvaluationFragment on T95Evaluation {
    take1 {
      correct
      repetitions
      confabulations
    }
    take2 {
      correct
      repetitions
      confabulations
    }
    take3 {
      correct
      repetitions
      confabulations
    }
    take4 {
      correct
      repetitions
      confabulations
    }
    take5 {
      correct
      repetitions
      confabulations
    }
    take6 {
      correct
      repetitions
      confabulations
      percentil
      n
    }
    take7 {
      correct
      repetitions
      confabulations
      percentil
      n
    }
    setA {
      correct
      repetitions
      confabulations
      percentil
      n
    }
    setB {
      correct
      repetitions
      confabulations
    }
    attention
    ciq
    viq
    niq
    hearingDisability
    sightDisability
    leftEye
    rightEye
    notes
  }
`;

interface Props {
  client: {
    fullName: string;
    dateOfBirth: Date;
    gender: string;
    testingDate: Date;
  };
  data: T95EvaluationFragment;
}

export function T95Result({ client, data }: Props) {
  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Klient</Typography>
      </Box>
      <ClientTable
        data={{
          ...client
        }}
      />
      <Box my={2}>
        <Typography variant="h4">Výsledky</Typography>
      </Box>
      <ResultsTable label="Poruchy sluchu" value={data.hearingDisability ? 'áno' : 'nie'} />
      <ResultsTable label="Poruchy zraku" value={data.sightDisability ? 'áno' : 'nie'} />
      <ResultsTable label="Počet dioptrií ľavé oko" value={data.leftEye ?? '-'} />
      <ResultsTable label="Počet dioptrií pravé oko" value={data.rightEye ?? '-'} />
      <ResultsTable label="Koncentrácia pozornosti pri vyšetrení" value={data.attention} />
      <ResultsTable label="Momentálna medikácia CIQ" value={data.ciq ?? '-'} />
      <ResultsTable label="Momentálna medikácia VIQ" value={data.viq ?? '-'} />
      <ResultsTable label="Momentálna medikácia NIQ" value={data.niq ?? '-'} />
      <TakeResult label={'Pokus č. 1'} {...data.take1} />
      <TakeResult label={'Pokus č. 2'} {...data.take2} />
      <TakeResult label={'Pokus č. 3'} {...data.take3} />
      <TakeResult label={'Pokus č. 4'} {...data.take4} />
      <TakeResult label={'Pokus č. 5'} {...data.take5} />
      <TakeResult label={'Sada A celkovo'} {...data.setA} />
      <TakeResult label={'Sada B'} {...data.setB} />
      <TakeResult label={'Pokus č. 6'} {...data.take6} />
      <TakeResult label={'po 30 minútach'} {...data.take7} />
      <ResultsTable label="Poznámky" value={data.notes ?? '-'} />
    </>
  );
}

function TakeResult({
  label,
  confabulations,
  correct,
  repetitions,
  percentil,
  n
}: {
  label: string;
} & Omit<T95EvaluationFragment['take1'], '__typename'> & {
    percentil?: string;
    n?: number | string;
  }) {
  return (
    <>
      <Box my={2}>
        <Typography variant="h4">{label}</Typography>
      </Box>
      <ResultsTable label="Počet správnych odpovedí" value={correct} />
      <ResultsTable label="Počet opakovaní" value={repetitions} />
      <ResultsTable label="Počet konfabulácií" value={confabulations} />

      {percentil != null && <ResultsTable label="Percentil" value={percentil} />}
      {n != null && <ResultsTable label="N" value={n} />}
    </>
  );
}

const borderStyle = '1px solid #d6d6d6';

function ResultsTable({ label, value }: { label: string; value: string | number }) {
  return (
    <Box display="flex" flexDirection="column" width="100%" boxShadow={2}>
      <Box display="flex" sx={{ borderBottom: borderStyle }}>
        <Box
          width="30%"
          textAlign="left"
          sx={{
            bgcolor: '#f8f8f8'
          }}
          p={1}
        >
          <Typography variant="body2">{label}</Typography>
        </Box>
        <Box width="70%" textAlign="right" sx={{ bgcolor: '#ffffff' }} p={1}>
          <Typography variant="body2"> {value}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
