import { useNavigate } from '@everlutionsk/ui-router';
import { ArrowForwardIos } from '@mui/icons-material';
import { styled } from '@mui/material';
import React from 'react';

export function TableArrowForward({ to }: { to: string }) {
  const navigate = useNavigate();
  return (
    <IconWrapper onClick={() => navigate(to)} sx={{ position: 'absolute', right: 15 }}>
      <ArrowForwardIos fontSize="small" />
    </IconWrapper>
  );
}

const IconWrapper = styled('div')`
  color: #b6c0d3;
  cursor: pointer;
`;
