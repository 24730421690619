import { gql } from '@apollo/client';
import { useFlashMutation, useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { AutocompleteField, Fields, createFormSpec } from '@everlutionsk/ui-formik';
import { useNavigate, useParams } from '@everlutionsk/ui-router';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { routing } from '../../../Routes';
import { ContentHeader } from '../../../components/ContentHeader';
import { DateField } from '../../../components/DateFIeld';
import { FormButton } from '../../../components/FormButton';
import { FormWrapper } from '../../../components/FormWrapper';
import { StudentStatus } from '../../../graphql/types';

export function RemoteForm() {
  const { code } = useParams(['code']);
  const [term, setTerm] = useState('');

  const navigate = useNavigate();

  const { data } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      input: { pagination: { limit, offset }, term, status: StudentStatus.active }
    })
  });

  const [start] = useFlashMutation(mutation, {
    successMsg: 'Link bol vygenerovaný',
    errorMsg: 'Link nebol vygenerovaný',
    onCompleted: testing => {
      navigate(routing.diagnostic.sendIndividual(code, testing.remoteTesting.id));
    }
  });

  const options = data?.newStudentMany.items.map(value => ({
    label: value.fullName,
    value: value.id
  }));

  return (
    <>
      <ContentHeader
        title="Vzdialené testovanie"
        breadcrumbs={[
          { title: 'Testy', path: routing.diagnostics.list },
          { title: `Test ${code}`, path: routing.diagnostic.prepare(code) },
          { title: 'Testovať', path: routing.diagnostic.prepare(code) },
          { title: 'Vzdialené testovanie' }
        ]}
      />
      <FormWrapper>
        <Formik
          {...formSpec({ student: null, expiresAt: new Date() })}
          onSubmit={({ student, expiresAt }) =>
            start({
              variables: {
                input: {
                  code,
                  studentId: student.value,
                  expiresAt: new Date(expiresAt).toISOString()
                }
              }
            })
          }
        >
          <Form>
            <Fields>
              <AutocompleteField
                label="Žiak"
                name="student"
                options={options}
                onTermChange={setTerm}
              />
              <DateField
                name="expiresAt"
                label="Platnosť linku do"
                type="date"
                InputProps={{ inputProps: { min: format(new Date(), 'yyyy-MM-dd') } }}
              />
            </Fields>

            <Box my={3}>
              <FormButton label="Vygenerovať link" />
            </Box>
          </Form>
        </Formik>
      </FormWrapper>
    </>
  );
}

const formSpec = createFormSpec({
  student: yup.string().nullable().required(`Meno žiaka je povinný údaj`),
  expiresAt: yup.string().nullable().required(`Platnosť linku je povinný údaj`)
});

const mutation = gql<RemoteTestingMutationGQL>`
  mutation RemoteTestingMutation($input: TestingInput!) {
    remoteTesting(input: $input) {
      id
    }
  }
`;

export const query = gql<RemoteStudentQueryGQL>`
  query RemoteStudentQuery($input: StudentFilterInput!) {
    newStudentMany(input: $input) {
      total
      items {
        id
        fullName
      }
    }
  }
`;
