/* eslint-disable */

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// DO NOT EDIT THIS FILE!
//
// GENERATED BY @everlutionsk/graphql-types-generator package!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const possibleTypes = {
  AbstractDiagnostic: [
    'Diagnostic',
    'T8',
    'T21',
    'T41',
    'T184',
    'T202',
    'T202Page2',
    'T216',
    'T239',
    'T252',
    'T309',
    'Otpr',
    'T95',
    'Smkzd'
  ],
  T95QuestionColumn: ['T95Field', 'T95Label'],
  Evaluation: [
    'T8Evaluation',
    'T184Evaluation',
    'T21Evaluation',
    'T216Evaluation',
    'T252Evaluation',
    'T41Evaluation',
    'T309Evaluation',
    'T239Evaluation',
    'T202Evaluation',
    'SmkzdEvaluation',
    'OtprEvaluation',
    'T95Evaluation',
    'DefaultEvaluation'
  ]
};
