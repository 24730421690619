import { Table } from '@app/admin/src/components/Table';
import { Box, styled } from '@mui/material';
import React from 'react';
import { T216EvaluationFragment } from '../../../../../graphql/types';

interface Props {
  data: T216EvaluationFragment;
}

export function T216ResultsTable({ data }: Props) {
  return (
    <TableWrapper mt={2}>
      <Table
        items={data.data}
        rowKey={item => item.label}
        columns={[
          {
            label: 'Škála',
            render: ({ label }) => label
          },
          {
            label: 'Hrubé skóre',
            render: ({ roughScore }) => roughScore
          },
          {
            label: 'Sten',
            render: ({ sten }) => sten
          }
        ]}
      />
    </TableWrapper>
  );
}

const TableWrapper = styled(Box)`
  thead {
    background-color: #f8f8f8;
  }
`;
