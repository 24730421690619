import { Link, useParams } from '@everlutionsk/ui-router';
import { Box } from '@mui/material';
import React from 'react';
import { ContentHeader } from '../../../../components/ContentHeader';
import { FormWrapper } from '../../../../components/FormWrapper';
import { routing } from '../../../../Routes';

export function Overview() {
  const { code } = useParams(['code']);

  return (
    <>
      <ContentHeader
        title="Skupinové testovanie"
        breadcrumbs={[
          { title: 'Testy', path: routing.diagnostics.list },
          { title: `Test ${code}`, path: routing.diagnostic.prepare(code) },
          { title: 'Testovať', path: routing.diagnostic.prepare(code) },
          { title: 'Skupinové testovanie' }
        ]}
      />
      <FormWrapper>
        <Box my={3}>
          <Link to={routing.diagnostic.prepareGroup(code, 'select')} color="secondary">
            Vybrať skupinu
          </Link>
        </Box>
        <Box my={3}>
          <Link to={routing.diagnostic.prepareGroup(code, 'new')} color="secondary">
            Vytvoriť novú skupinu
          </Link>
        </Box>
      </FormWrapper>
    </>
  );
}
