import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { OtprEvaluationFragment } from '../../../graphql/types';
import { ClientTable } from './components/ClientTable';

export const otprEvaluationFragment = gql`
  fragment OtprEvaluationFragment on OtprEvaluation {
    variant
    s
    roughScore
    practice
    practice2
    generalN
    generalPercentile
    groupN
    groupPercentile
    general1N
    general1Percentile
    group1N
    group1Percentile
    general2N
    general2Percentile
    group2N
    group2Percentile
    result
    interrupted
    interruptionReason
    ended
    endingReason
    downgraded
    communication
    notes
  }
`;

interface Props {
  client: {
    fullName: string;
    dateOfBirth: Date;
    gender: string;
    testingDate: Date;
  };
  data: OtprEvaluationFragment;
}

export function OtprResult({ client, data }: Props) {
  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Klient</Typography>
      </Box>
      <ClientTable
        data={{
          ...client
        }}
      />
      <Box my={2}>
        <Typography variant="h4">Výsledky</Typography>
      </Box>
      <ResultsTable label="variant" value={data.variant} />
      {data.s && <ResultsTable label="počet dosiahnutých bodov v časti slovník" value={data.s} />}
      {data.roughScore && (
        <ResultsTable
          label="počet dosiahnutých bodov v hlavnom testovaní"
          value={data.roughScore}
        />
      )}
      {data.roughScore && (
        <ResultsTable
          label="počet dosiahnutých bodov v hlavnom testovaní"
          value={data.roughScore}
        />
      )}
      {data.practice && <ResultsTable label="zácvik" value={data.practice} />}
      {data.practice2 && <ResultsTable label="zácvik 2" value={data.practice2} />}

      {data.generalN && <ResultsTable label="bežné podmienky n" value={data.generalN} />}
      {data.generalPercentile && (
        <ResultsTable label="bežné podmienky percentil" value={data.generalPercentile} />
      )}
      {data.groupN && <ResultsTable label="celá skupina n" value={data.groupN} />}
      {data.groupPercentile && (
        <ResultsTable label="celá skupina percentil" value={data.groupPercentile} />
      )}
      {data.general1N && <ResultsTable label="C1 bežné podmienky n" value={data.general1N} />}
      {data.general1Percentile && (
        <ResultsTable label="C1 bežné podmienky percentil" value={data.general1Percentile} />
      )}
      {data.group1N && <ResultsTable label="C1 celá skupina n" value={data.group1N} />}
      {data.group1Percentile && (
        <ResultsTable label="C1 celá skupina percentil" value={data.group1Percentile} />
      )}
      {data.general2N && <ResultsTable label="C2 bežné podmienky n" value={data.general2N} />}
      {data.general2Percentile && (
        <ResultsTable label="C2 bežné podmienky percentil" value={data.general2Percentile} />
      )}
      {data.group2N && <ResultsTable label="C2 celá skupina n" value={data.group2N} />}
      {data.group2Percentile && (
        <ResultsTable label="C2 celá skupina percentil" value={data.group2Percentile} />
      )}
      {data.result && <ResultsTable label="orientačné hodnotenie" value={data.result} />}
      {data.interrupted && (
        <ResultsTable label="prerušenie testovania" value={data.interrupted ? 'áno' : 'nie'} />
      )}
      {data.interruptionReason && (
        <ResultsTable label="dôvod prerušenia testovania" value={data.interruptionReason} />
      )}
      {data.ended && (
        <ResultsTable label="ukončenie testovania" value={data.ended ? 'áno' : 'nie'} />
      )}
      {data.endingReason && (
        <ResultsTable label="dôvod ukončenia testovania" value={data.endingReason} />
      )}
      {data.downgraded && (
        <ResultsTable label="prechod na nižší variant" value={data.downgraded ? 'áno' : 'nie'} />
      )}
      {data.communication && (
        <ResultsTable
          label="komunikácia dieťaťa (jazyk, kvalita reči)"
          value={data.communication}
        />
      )}
      {data.notes && (
        <ResultsTable label="ďalšie postrehy z priebehu testovania" value={data.notes} />
      )}
    </>
  );
}

const borderStyle = '1px solid #d6d6d6';

function ResultsTable({ label, value }: { label: string; value: string | number }) {
  return (
    <Box display="flex" flexDirection="column" width="100%" boxShadow={2}>
      <Box display="flex" sx={{ borderBottom: borderStyle }}>
        <Box
          width="30%"
          textAlign="left"
          sx={{
            bgcolor: '#f8f8f8'
          }}
          p={1}
        >
          <Typography variant="body2">{label}</Typography>
        </Box>
        <Box width="70%" textAlign="right" sx={{ bgcolor: '#ffffff' }} p={1}>
          <Typography variant="body2"> {value}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
