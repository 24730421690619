import { gql, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { ConditionalForm, createFormSpec, Fields, TextField } from '@everlutionsk/ui-formik';
import { useParams } from '@everlutionsk/ui-router';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { ContentHeader } from '../../../components/ContentHeader';
import { FormButton } from '../../../components/FormButton';
import { FormWrapper } from '../../../components/FormWrapper';
import { toDiagnosticUrl } from '../utils';

export function SendIndividualForm() {
  const { code, id } = useParams(['code', 'id']);

  const { data } = useQuery(query, { variables: { id } });

  const [send] = useFlashMutation(mutation, {
    successMsg: 'Link bol odoslaný',
    errorMsg: 'Link nebol odoslaný'
  });

  if (data == null) return <Loading />;

  return (
    <>
      <ContentHeader title={'Poslať email'} />
      <FormWrapper>
        <Formik
          {...formSpec({
            from: null,
            to: null,
            message: `Napíš správu sem...

  Link k Tvojmu testu: ${toDiagnosticUrl(id, code)}

  Platnost linku: ${new Date(data.testingOne.expiresAt).toLocaleDateString()}`
          })}
          onSubmit={({ to, from, message }) =>
            send({ variables: { input: { from, to, message } } })
          }
        >
          <ConditionalForm>
            <Fields>
              <TextField name="to" label="Komu" type="email" />
              <TextField name="from" label="Od koho" type="email" />

              <TextField name="message" label="Správa" rows={7} multiline />
            </Fields>

            <Box my={3}>
              <FormButton label="Odoslať" />
            </Box>
          </ConditionalForm>
        </Formik>
      </FormWrapper>
    </>
  );
}

const formSpec = createFormSpec({
  to: yup
    .string()
    .email('Emailová adresa nie je validná')
    .nullable()
    .required(`Email komu je povinný údaj`),
  from: yup
    .string()
    .email('Emailová adresa nie je validná')
    .nullable()
    .required(`Email od koho  je povinný údaj`),
  message: yup.string().nullable().required(`Správa je povinný údaj`)
});

const mutation = gql<SendTestingMutationGQL>`
  mutation SendTestingMutation($input: SendTestingInput!) {
    sendTesting(input: $input)
  }
`;

const query = gql<IndividualTestingQueryGQL>`
  query IndividualTestingQuery($id: ID!) {
    testingOne(id: $id) {
      id
      code
      expiresAt
      student {
        id
        fullName
      }
    }
  }
`;
