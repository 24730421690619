import { gql } from '@apollo/client';
import { call, populated } from '@everlutionsk/helpers';
import { Loading } from '@everlutionsk/ui';
import { useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { Box, Grid } from '@mui/material';
import { uniqBy } from 'ramda';
import React from 'react';
import { ContentHeader } from '../../components/ContentHeader';
import { TestItem } from '../../components/TestItem';
import { testCodes } from '../../helpers';
import { useIdentity } from '../../components/hooks/useIdentity';

export function DiagnosticsList() {
  const identity = useIdentity();
  if (identity == null) throw Error('Access Denied! Missing valid identity.');

  const { data } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      id: identity.id,
      input: { pagination: { limit, offset } }
    })
  });

  const testIds = call(() => {
    if (identity.role === 'superAdmin' || identity.role === 'admin') {
      return testCodes;
    }

    const positionTestIds = data?.userOne.position.flatMap(item => item.testIds) ?? [];

    if (data?.userOne.group != null) {
      const ids = data.userOne.group.testIds;

      const enabledVideos = positionTestIds.filter(item => ids.includes(item));

      return uniqBy(id => id, enabledVideos);
    }

    if (data?.userOne.organisation?.group != null) {
      const ids = data.userOne.organisation.group.testIds;

      const enabledVideos = positionTestIds.filter(item => ids.includes(item));

      return uniqBy(id => id, enabledVideos);
    }

    if (positionTestIds.length > 0) {
      return positionTestIds;
    }

    return [];
  });

  const availableTests = data?.diagnosticMany.items.filter(item => testIds.includes(item.code));

  return (
    <>
      <Box mb={3}>
        <ContentHeader
          title="Zoznam testov"
          breadcrumbs={[{ title: 'Domov' }, { title: `Testy` }]}
        />
      </Box>
      {availableTests?.length === 0 ? (
        <p>Potrebné priradenie ku skupine.</p>
      ) : (
        <Grid container spacing={3}>
          {populated(data?.diagnosticMany.items) ? (
            <>
              {availableTests?.map(item => (
                <Grid item xs={12} md={4} key={item.code}>
                  <TestItem key={item.code} code={item.code} label={item.label} />
                </Grid>
              ))}
            </>
          ) : (
            <Loading />
          )}
        </Grid>
      )}
    </>
  );
}

export const query = gql<DiagnosticManyQueryGQL>`
  query DiagnosticManyQuery($id: ID!, $input: DiagnosticFilterInput!) {
    diagnosticMany(input: $input) {
      total
      items {
        code
        label
      }
    }
    userOne(id: $id) {
      id
      organisation {
        id
        group {
          id
          testIds
        }
      }
      group {
        id
        testIds
      }
      position {
        id
        testIds
      }
    }
  }
`;
