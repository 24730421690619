import { AutocompleteOption, useFieldError } from '@everlutionsk/ui-formik';
import { useField } from 'formik';
import React from 'react';
import { MultiAutocompleteInput, MultiAutocompleteInputProps } from './MultiAutocompleteInput';

export interface MultiAutocompleteFieldPops<Value, AllowCustom extends boolean | undefined>
  extends Omit<
    MultiAutocompleteInputProps<Value, AllowCustom>,
    'error' | 'selected' | 'onSelect' | 'onRemove' | 'onClear'
  > {
  /**
   * Field name.
   */
  readonly name: string;
}

/**
 * Autocomplete field with multi-selection support.
 *
 * To use it without Formik, please use MultiAutocompleteInput
 */
export function MultiAutocompleteField<Value, AllowCustom extends boolean | undefined>(
  props: MultiAutocompleteFieldPops<Value, AllowCustom>
) {
  const { name, ...restProps } = props;
  const [field, , { setValue, setTouched }] = useField<
    Array<AutocompleteOption<Value, AllowCustom>> | undefined
  >(name);
  const error = useFieldError(name);
  const value = field.value ?? [];

  return (
    <MultiAutocompleteInput
      {...restProps}
      error={error}
      selected={field.value}
      onSelect={option => setValue([...value, option])}
      onRemove={option => setValue(value.filter(({ label }) => label !== option.label))}
      onBlur={event => {
        setTouched(true);
        props.onBlur?.(event);
      }}
      onClear={() => setValue([])}
    />
  );
}
