import { gql } from '@apollo/client';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { useNavigate } from '@everlutionsk/ui-router';
import React from 'react';
import { ContentHeader } from '../../components/ContentHeader';
import { FormWrapper } from '../../components/FormWrapper';
import { routing } from '../../Routes';
import { OrganisationForm } from './Forms/OrganisationForm';

export function AddOrganisation() {
  const navigate = useNavigate();

  const [add] = useFlashMutation(mutation, {
    successMsg: 'Organizácia bola úspešne pridaná',
    errorMsg: 'Organizácia nebola pridaná',
    onCompleted: () => navigate(routing.organisations.list)
  });

  return (
    <>
      <ContentHeader
        title={'Pridať organizáciu'}
        breadcrumbs={[
          { title: 'Domov' },
          { title: 'Organizácie', path: routing.organisations.list },
          { title: 'Pridať organizáciu' }
        ]}
      />
      <FormWrapper>
        <OrganisationForm
          buttonText="Pridať organizáciu"
          onSubmit={({ name, city, line1, region, district }) =>
            add({
              variables: {
                input: {
                  name,
                  city,
                  line1,
                  region,
                  district
                }
              },
              update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'organisationMany' });
              }
            })
          }
        />
      </FormWrapper>
    </>
  );
}

const mutation = gql<AddOrganisationGQL>`
  mutation AddOrganisation($input: AddOrganisationInput!) {
    addOrganisation(input: $input) {
      id
      name
    }
  }
`;
