import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routingPatterns } from '../../Routes';
import { AddUser } from './AddUser';
import { UsersList } from './UsersList';

export function Users() {
  return (
    <>
      <Routes>
        <Route path={routingPatterns.users.list} element={<UsersList />} />
        <Route path={routingPatterns.users.add} element={<AddUser />} />
      </Routes>
    </>
  );
}
