import { LinkButton } from '@everlutionsk/ui-router';
import { SearchRounded } from '@mui/icons-material';
import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import React from 'react';

export function SearchBar({
  withButton,
  navigateTo,
  onSearch,
  buttonTitle
}: {
  withButton?: boolean;
  navigateTo?: string;
  onSearch: (value: string) => void;
  buttonTitle?: string;
}) {
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item md={8}>
        <TextField
          size="small"
          name="search"
          label="Hľadať"
          placeholder="Vyhľadávanie"
          variant="outlined"
          onChange={e => {
            onSearch(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      {withButton && (
        <Grid item md={4}>
          <Box display="flex" justifyContent="flex-end">
            {navigateTo && (
              <LinkButton
                variant="contained"
                fullWidth
                color="secondary"
                size="medium"
                to={navigateTo}
              >
                {buttonTitle}
              </LinkButton>
            )}
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
