import { gql } from '@apollo/client';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import React from 'react';
import { ContentHeader } from '../../components/ContentHeader';
import { FormWrapper } from '../../components/FormWrapper';
import { routing } from '../../Routes';
import { UserProfileForm } from './components/UserProfileForm';

export function AddUser() {
  const [mutate] = useFlashMutation(mutation, {
    successMsg: 'Zamestnanec bol úspešne pridaný',
    errorMsg: 'Zamestnanca sa nepodarilo pridať'
  });
  return (
    <>
      <ContentHeader
        title="Pridať zamestnanca"
        breadcrumbs={[
          { title: 'Domov' },
          { title: 'Zamestnanci', path: routing.users.list },
          { title: 'Pridať zamestnanca' }
        ]}
      />
      <FormWrapper>
        <UserProfileForm
          type="add"
          buttonTitle="Pridať zamestnanca"
          onSubmit={async values => {
            await mutate({
              variables: {
                input: {
                  ...values
                }
              },
              update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'userMany' });
              }
            });
            values.resetForm && values.resetForm();
          }}
        />
      </FormWrapper>
    </>
  );
}

const mutation = gql<AddUserGQL>`
  mutation AddUser($input: InviteInput!) {
    addUser(input: $input) {
      id
      firstName
      lastName
      title
      organisation {
        id
      }
      position {
        id
        name
      }
      phoneNumber
      email
      role
    }
  }
`;
