import { Table } from '@app/admin/src/components/Table';
import { Box, styled } from '@mui/material';
import React from 'react';
import { T184EvaluationFragment } from '../../../../../graphql/types';

interface Props {
  data: T184EvaluationFragment;
}

export function T184ResultsTable({ data }: Props) {
  return (
    <TableWrapper mt={2}>
      <Table
        items={data.items}
        rowKey={item => item.label}
        columns={[
          {
            label:
              data.version === 'V2'
                ? 'Kategórie rizikového správania'
                : 'Kategórie školských prejavov správania',

            render: ({ label }) => label
          },
          {
            label: 'CHS',
            render: ({ totalRoughScore }) => totalRoughScore
          },
          {
            label: 'Percentil podľa pohlavia a veku',
            render: ({ percentileByGenderAndAge }) => percentileByGenderAndAge ?? '-'
          }
        ]}
      />
    </TableWrapper>
  );
}

const TableWrapper = styled(Box)`
  thead {
    background-color: #f8f8f8;
  }
`;
