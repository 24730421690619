import { Assignment } from '@mui/icons-material';
import { routing } from '../Routes';

export const analystNavigationLayer = {
  title: '',
  items: [
    {
      title: 'Výsledky',
      icon: Assignment,
      path: routing.testResults.list
    }
  ]
};
