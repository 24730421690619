import { gql, useMutation, useQuery } from '@apollo/client';
import { Table } from '../components/Table';
import { useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import {
  CheckboxesField,
  Fields,
  MultiAutocompleteField,
  SubmitButton,
  TextField,
  createFormSpec
} from '@everlutionsk/ui-formik';
import { Link, LinkButton, useNavigate, useParams } from '@everlutionsk/ui-router';
import { Alert, Box, Button, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { ContentHeader } from '../components/ContentHeader';
import { TableArrowForward } from '../components/TableArrowForward';
import { useTotalNumberDescription } from '../components/hooks/useTotalNumberDescription';
import { testCodes, videoList } from '../helpers';
import { Warning } from '@mui/icons-material';

export function Groups() {
  const { data, pagination, error } = useOffsetPaginatedQuery(userGroupManyQuery, {
    variables: ({ limit, offset }) => ({ input: { pagination: { limit, offset } } })
  });

  const { totalNumberDescription } = useTotalNumberDescription(data?.userGroupMany.total ?? 0);

  return (
    <>
      <ContentHeader title="Používateľské skupiny" />
      <Box mb={3}>
        <LinkButton variant="contained" color="secondary" size="medium" to="/groups/add">
          + Pridať skupinu
        </LinkButton>
      </Box>

      <Box sx={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
        <Box display="flex" flexDirection="row">
          <Typography>
            Počet položiek: {data?.userGroupMany.total ?? 0} {totalNumberDescription}{' '}
          </Typography>
        </Box>
      </Box>

      <Table
        items={data?.userGroupMany.items}
        pagination={pagination}
        error={error}
        columns={[
          {
            label: 'Názov',
            render: ({ id, name }) => (
              <Link to={`./edit/${id}`} color="secondary">
                {name}
              </Link>
            )
          },
          {
            label: '',
            render: ({ id }) => <TableArrowForward to={`./edit/${id}`} />
          }
        ]}
      />
    </>
  );
}

const userGroupManyQuery = gql<UserGroupManyGQL>`
  query UserGroupMany($input: UserGroupManyInput) {
    userGroupMany(input: $input) {
      total
      items {
        id
        name
      }
    }
  }
`;

export function AddGroup() {
  const [organisationTerm, setOrganisationTerm] = useState('');
  const [userTerm, setUserTerm] = useState('');

  const navigate = useNavigate();

  const { data: organisationAutocomplete } = useQuery(organisationAutocompleteQuery, {
    variables: {
      input: {
        pagination: { limit: 100, offset: 0 },
        term: organisationTerm,
        withoutUserGroup: true
      }
    },
    fetchPolicy: 'no-cache'
  });

  const { data: userAutocomplete } = useQuery(userAutocompleteQuery, {
    variables: {
      input: {
        pagination: { limit: 20, offset: 0 },
        term: userTerm,
        withoutUserGroup: true,
        status: 'active'
      }
    },
    fetchPolicy: 'no-cache'
  });

  const [create] = useMutation(createMutation);

  const formSpec = useMemo(() => {
    return createFormSpec({
      name: yup.string().required(),
      videos: yup.array(),
      organisations: yup.array(),
      users: yup.array(),
      tests: yup.array()
    });
  }, []);

  return (
    <>
      <ContentHeader title="Pridať skupinu" />
      <Box mb={3}>
        <Alert color="warning" icon={<Warning />}>
          Všetky zmeny v právach sa prejavia približne do 15 minút.
        </Alert>
      </Box>
      <Formik
        {...formSpec({
          name: '',
          videos: [],
          users: [],
          organisations: [],
          tests: []
        })}
        onSubmit={async values => {
          await create({
            variables: {
              input: {
                name: values.name,
                videoIds: values.videos,
                organisationIds: values.organisations.map(item => item.value),
                userIds: values.users.map(item => item.value),
                testIds: values.tests
              }
            },
            update: cache => {
              cache.evict({ id: 'ROOT_QUERY', fieldName: 'userGroupMany' });
            }
          });

          navigate('/groups');
        }}
      >
        <Form>
          <Fields>
            <TextField name="name" label="Názov" />
            <MultiAutocompleteField
              label="Organizácie"
              name="organisations"
              options={organisationAutocomplete?.organisationMany.items.map(item => ({
                label: `${item.name} ${item.city} ${item.line1}`,
                value: item.id,
                node: (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body1">
                        <b>{item.name}</b>
                      </Typography>
                      {item.city && item.line1 && (
                        <Typography variant="body2">
                          ({item.city} {item.line1})
                        </Typography>
                      )}
                    </Box>
                  </Box>
                )
              }))}
              onTermChange={setOrganisationTerm}
            />
            <p style={{ marginTop: 0 }}>alebo</p>
            <MultiAutocompleteField
              label="Používatelia"
              name="users"
              options={userAutocomplete?.userMany.items.map(({ id, fullName }) => ({
                label: fullName,
                value: id
              }))}
              onTermChange={setUserTerm}
            />
            <CheckboxesField
              label="Videometodiky a prípadové štúdie"
              options={videoList.flatMap(item =>
                item.videos.map(item => ({ value: item.path, label: item.label }))
              )}
              name="videos"
            />
            <CheckboxesField
              label="Testy"
              options={testCodes.map(item => ({ value: item, label: item }))}
              name="tests"
            />
          </Fields>
          <Box my={3}>
            <SubmitButton variant="contained" style={{ width: '200px' }} color="secondary">
              Uložiť zmeny
            </SubmitButton>
          </Box>
        </Form>
      </Formik>
    </>
  );
}

const organisationAutocompleteQuery = gql<OrganisationAutocompleteQueryGQL>`
  query OrganisationAutocompleteQuery($input: OrganisationFilterInput) {
    organisationMany(input: $input) {
      total
      items {
        id
        name
        city
        line1
      }
    }
  }
`;

const userAutocompleteQuery = gql<UserAutocompleteQueryGQL>`
  query UserAutocompleteQuery($input: UserFilterInput) {
    userMany(input: $input) {
      total
      items {
        id
        fullName
      }
    }
  }
`;

const createMutation = gql<UserGroupCreateGQL>`
  mutation UserGroupCreate($input: UserGroupInput!) {
    userGroupCreate(input: $input) {
      id
      name
    }
  }
`;

export function EditGroup() {
  const { id } = useParams(['id']);

  const [organisationTerm, setOrganisationTerm] = useState('');
  const [userTerm, setUserTerm] = useState('');

  const navigate = useNavigate();

  const { data } = useQuery(userGroupQuery, { variables: { id }, fetchPolicy: 'no-cache' });

  const { data: organisationAutocomplete } = useQuery(organisationAutocompleteQuery, {
    variables: {
      input: {
        pagination: { limit: 100, offset: 0 },
        term: organisationTerm,
        withoutUserGroup: true
      }
    },
    fetchPolicy: 'no-cache'
  });

  const { data: userAutocomplete } = useQuery(userAutocompleteQuery, {
    variables: {
      input: {
        pagination: { limit: 20, offset: 0 },
        term: userTerm,
        withoutUserGroup: true,
        status: 'active'
      }
    },
    fetchPolicy: 'no-cache'
  });

  const [create] = useMutation(editMutation);
  const [remove] = useMutation(removeMutation);

  const formSpec = useMemo(() => {
    return createFormSpec({
      name: yup.string().required(),
      videos: yup.array(),
      organisations: yup.array(),
      users: yup.array(),
      tests: yup.array()
    });
  }, []);

  return (
    <>
      <ContentHeader
        title="Upraviť skupinu"
        mainAction={
          <Button
            style={{ backgroundColor: 'red', color: 'white' }}
            variant="contained"
            onClick={async () => {
              await remove({
                variables: { id },
                update: cache => {
                  cache.evict({ id: 'ROOT_QUERY', fieldName: 'userGroupMany' });
                }
              });
              navigate('/groups');
            }}
          >
            Odstrániť skupinu
          </Button>
        }
      />

      <Box mb={3}>
        <Alert color="warning" icon={<Warning />}>
          Všetky zmeny v právach sa prejavia približne do 15 minút.
        </Alert>
      </Box>
      <Formik
        enableReinitialize
        {...formSpec({
          name: data?.userGroupOne.name,
          videos: data?.userGroupOne.videoIds,
          users: data?.userGroupOne.userIds
            ? data?.userGroupOne.userIds.map(item => ({ label: item.fullName, value: item.id }))
            : [],
          organisations: data?.userGroupOne.organisationIds
            ? data?.userGroupOne.organisationIds.map(item => ({
                label: `${item.name} ${item.city} ${item.line1}`,
                value: item.id
              }))
            : [],
          tests: data?.userGroupOne.testIds
        })}
        onSubmit={async values => {
          await create({
            variables: {
              id,
              input: {
                name: values.name,
                videoIds: values.videos,
                organisationIds: values.organisations.map(item => item.value),
                userIds: values.users.map(item => item.value),
                testIds: values.tests
              }
            }
          });

          navigate('/groups');
        }}
      >
        <Form>
          <Fields>
            <TextField name="name" label="Názov" />
            <MultiAutocompleteField
              label="Organizácie"
              name="organisations"
              options={organisationAutocomplete?.organisationMany.items.map(item => ({
                label: `${item.name} ${item.city} ${item.line1}`,
                value: item.id,
                node: (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body1">
                        <b>{item.name}</b>
                      </Typography>
                      {item.city && item.line1 && (
                        <Typography variant="body2">
                          ({item.city} {item.line1})
                        </Typography>
                      )}
                    </Box>
                  </Box>
                )
              }))}
              onTermChange={setOrganisationTerm}
            />
            <p style={{ marginTop: 0 }}>alebo</p>
            <MultiAutocompleteField
              label="Používatelia"
              name="users"
              options={userAutocomplete?.userMany.items.map(({ id, fullName }) => ({
                label: fullName,
                value: id
              }))}
              onTermChange={setUserTerm}
            />
            <CheckboxesField
              label="Videometodiky a prípadové štúdie"
              options={videoList.flatMap(item =>
                item.videos.map(item => ({ value: item.path, label: item.label }))
              )}
              name="videos"
            />
            <CheckboxesField
              label="Testy"
              options={testCodes.map(item => ({ value: item, label: item }))}
              name="tests"
            />
          </Fields>
          <Box my={3}>
            <SubmitButton variant="contained" style={{ width: '200px' }} color="secondary">
              Uložiť zmeny
            </SubmitButton>
          </Box>
        </Form>
      </Formik>
    </>
  );
}

const editMutation = gql<UserGroupEditGQL>`
  mutation UserGroupEdit($input: UserGroupInput!, $id: ID!) {
    userGroupEdit(input: $input, id: $id) {
      id
      name
    }
  }
`;

const removeMutation = gql<UserGroupRemoveGQL>`
  mutation UserGroupRemove($id: ID!) {
    userGroupRemove(id: $id)
  }
`;

const userGroupQuery = gql<UserGroupOneGQL>`
  query UserGroupOne($id: ID!) {
    userGroupOne(id: $id) {
      id
      name
      videoIds
      testIds
      userIds {
        id
        fullName
      }
      organisationIds {
        id
        name
        city
        line1
      }
    }
  }
`;

export function PositionGroups() {
  const { data } = useQuery(positionGroupManyQuery, { fetchPolicy: 'no-cache' });

  return (
    <>
      <ContentHeader title="Pozície" />

      <Table
        items={data?.positionGroupMany}
        columns={[
          {
            label: 'Názov',
            render: ({ name, id }) => (
              <Link to={`./edit/${id}`} color="secondary">
                {name}
              </Link>
            )
          },
          {
            label: '',
            render: ({ id }) => <TableArrowForward to={`./edit/${id}`} />
          }
        ]}
      />
    </>
  );
}

const positionGroupManyQuery = gql<PositionGroupManyGQL>`
  query PositionGroupMany {
    positionGroupMany {
      id
      name
    }
  }
`;

export function EditPositionGroup() {
  const { id } = useParams(['id']);

  const navigate = useNavigate();

  const { data } = useQuery(positionGroupQuery, { variables: { id }, fetchPolicy: 'no-cache' });

  const [edit] = useMutation(editPositionMutation);

  const formSpec = useMemo(() => {
    return createFormSpec({
      videos: yup.array(),
      tests: yup.array()
    });
  }, []);

  return (
    <>
      <ContentHeader title={data?.positionGroupOne.name} />
      <Box mb={3}>
        <Alert color="warning" icon={<Warning />}>
          Všetky zmeny v právach sa prejavia približne do 15 minút.
        </Alert>
      </Box>

      <Formik
        enableReinitialize
        {...formSpec({
          videos: data?.positionGroupOne.videoIds,
          tests: data?.positionGroupOne.testIds
        })}
        onSubmit={async values => {
          await edit({
            variables: {
              input: {
                id,
                videoIds: values.videos,
                testIds: values.tests
              }
            }
          });

          navigate('/positions');
        }}
      >
        <Form>
          <Fields>
            <CheckboxesField
              label="Videometodiky a prípadové štúdie"
              options={videoList.flatMap(item =>
                item.videos.map(item => ({ value: item.path, label: item.label }))
              )}
              name="videos"
            />
            <CheckboxesField
              label="Testy"
              options={testCodes.map(item => ({ value: item, label: item }))}
              name="tests"
            />
          </Fields>
          <Box my={3}>
            <SubmitButton variant="contained" style={{ width: '200px' }} color="secondary">
              Uložiť zmeny
            </SubmitButton>
          </Box>
        </Form>
      </Formik>
    </>
  );
}

const positionGroupQuery = gql<PositionGroupOneGQL>`
  query PositionGroupOne($id: ID!) {
    positionGroupOne(id: $id) {
      id
      name
      videoIds
      testIds
    }
  }
`;

const editPositionMutation = gql<PositionGroupEditGQL>`
  mutation PositionGroupEdit($input: PositionGroupInput!) {
    positionGroupEdit(input: $input) {
      id
      name
      videoIds
      testIds
    }
  }
`;
