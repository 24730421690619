import { gql, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { useParams } from '@everlutionsk/ui-router';
import { Grid } from '@mui/material';
import React from 'react';
import { ContentHeader } from '../../components/ContentHeader';
import { defaultEvaluationFragment, DefaultResult } from '../Diagnostic/Result/DefaultResult';
import { otprEvaluationFragment, OtprResult } from '../Diagnostic/Result/OtprResult';
import { smkzdEvaluationFragment, SmkzdResult } from '../Diagnostic/Result/SmkzdResult';
import { t184EvaluationFragment, T184Result } from '../Diagnostic/Result/T184Result';
import { t202EvaluationFragment, T202Result } from '../Diagnostic/Result/T202Result';
import { t216EvaluationFragment, T216Result } from '../Diagnostic/Result/T216Result';
import { t21EvaluationFragment, T21Result } from '../Diagnostic/Result/T21Result';
import { t239EvaluationFragment, T239Result } from '../Diagnostic/Result/T239Result';
import { t252EvaluationFragment, T252Result } from '../Diagnostic/Result/T252Result';
import { t309EvaluationFragment, T309Result } from '../Diagnostic/Result/T309Result';
import { t41EvaluationFragment, T41Result } from '../Diagnostic/Result/T41Result';
import { t8EvaluationFragment, T8Result } from '../Diagnostic/Result/T8Result';
import { t95EvaluationFragment } from '../Diagnostic/Result/T95Result';

export function ResultOne() {
  const { id } = useParams(['id']);

  const { data } = useQuery(query, { variables: { id } });

  if (data == null) return <Loading />;

  if (data.testingOne.evaluation == null) return <></>;
  const typename = data.testingOne.evaluation.__typename;

  const client = {
    dateOfBirth: new Date(data.testingOne.student.dateOfBirth),
    fullName: data.testingOne.student.fullName ?? '',
    gender: data.testingOne.student.gender ?? '',
    testingDate: new Date(data.testingOne.createdAt)
  };

  return (
    <>
      <ContentHeader title="Výsledok" />
      <Grid item xs={12} md={10} lg={10}>
        {typename === 'T8Evaluation' && (
          <T8Result
            token={data.testingOne.originalToken ?? ''}
            data={data.testingOne.evaluation}
            client={client}
          />
        )}
        {typename === 'T184Evaluation' && (
          <T184Result
            client={{
              ...client,
              details: {
                t184subject: data.testingOne.details.t184subject ?? '-'
              }
            }}
            data={data.testingOne.evaluation}
          />
        )}
        {typename === 'T21Evaluation' && (
          <T21Result data={data.testingOne.evaluation} client={client} />
        )}
        {typename === 'T216Evaluation' && (
          <T216Result
            token={data.testingOne.originalToken ?? ''}
            data={data.testingOne.evaluation}
            client={{
              ...client,
              details: {
                ratingArtEducation: data.testingOne.details.ratingArtEducation?.toString() ?? '-',
                ratingMaths: data.testingOne.details.ratingMaths?.toString() ?? '-',
                ratingSlovakLanguage:
                  data.testingOne.details.ratingSlovakLanguage?.toString() ?? '-',
                ratingTechnicalEducation:
                  data.testingOne.details.ratingTechnicalEducation?.toString() ?? '-'
              }
            }}
          />
        )}
        {typename === 'T252Evaluation' && (
          <T252Result data={data.testingOne.evaluation} client={client} />
        )}
        {typename === 'T41Evaluation' && (
          <T41Result
            data={data.testingOne.evaluation}
            client={client}
            token={data.testingOne.originalToken ?? ''}
          />
        )}
        {typename === 'T309Evaluation' && (
          <T309Result data={data.testingOne.evaluation} client={client} />
        )}
        {typename === 'T239Evaluation' && (
          <T239Result
            data={data.testingOne.evaluation}
            client={{
              ...client,
              details: {
                grade: data.testingOne.details.grade ?? undefined
              }
            }}
          />
        )}
        {typename === 'T202Evaluation' && (
          <T202Result
            data={data.testingOne.evaluation}
            client={{
              ...client,
              details: {
                fatherEducation: data.testingOne.details.fatherEducation ?? undefined,
                grade: data.testingOne.details.grade ?? undefined,
                motherEducation: data.testingOne.details.motherEducation ?? undefined,
                postponement: data.testingOne.details.postponement ?? undefined,
                ratingForeignLanguage: data.testingOne.details.ratingForeignLanguage ?? undefined,
                ratingMaths: data.testingOne.details.ratingMaths ?? undefined,
                ratingSlovakLanguage: data.testingOne.details.ratingSlovakLanguage ?? undefined,
                repeatedGrade: data.testingOne.details.repeatedGrade ?? undefined
              }
            }}
          />
        )}
        {typename === 'SmkzdEvaluation' && (
          <SmkzdResult data={data.testingOne.evaluation} client={client} />
        )}
        {typename === 'OtprEvaluation' && (
          <OtprResult data={data.testingOne.evaluation} client={client} />
        )}
        {typename === 'DefaultEvaluation' && <DefaultResult data={data.testingOne.evaluation} />}
      </Grid>
    </>
  );
}

export const query = gql<ResultOneEvaluationGQL>`
  query ResultOneEvaluation($id: ID!) {
    testingOne(id: $id) {
      id
      code
      createdAt
      originalToken
      student {
        fullName
        dateOfBirth
        gender
      }
      details {
        ratingSlovakLanguage
        ratingMaths
        ratingTechnicalEducation
        ratingArtEducation
        grade
        postponement
        repeatedGrade
        ratingForeignLanguage
        motherEducation
        fatherEducation
        t184subject
        grade
      }
      evaluation {
        ...T8EvaluationFragment
        ...T184EvaluationFragment
        ...T21EvaluationFragment
        ...T216EvaluationFragment
        ...T252EvaluationFragment
        ...T41EvaluationFragment
        ...T309EvaluationFragment
        ...T239EvaluationFragment
        ...T202EvaluationFragment
        ...SmkzdEvaluationFragment
        ...OtprEvaluationFragment
        ...T95EvaluationFragment
        ...DefaultEvaluationFragment
      }
    }
  }
  ${t8EvaluationFragment}
  ${t184EvaluationFragment}
  ${t21EvaluationFragment}
  ${t216EvaluationFragment}
  ${t252EvaluationFragment}
  ${t41EvaluationFragment}
  ${t309EvaluationFragment}
  ${t239EvaluationFragment}
  ${t202EvaluationFragment}
  ${smkzdEvaluationFragment}
  ${otprEvaluationFragment}
  ${t95EvaluationFragment}
  ${defaultEvaluationFragment}
`;
