import { gql, useQuery } from '@apollo/client';
import {
  AutocompleteField,
  CheckboxField,
  ConditionalForm,
  Fields,
  MultiAutocompleteField,
  TextField,
  createFormSpec
} from '@everlutionsk/ui-formik';
import { Box, Typography } from '@mui/material';
import { Formik, FormikState } from 'formik';
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { routing } from '../../../Routes';
import { FormButton } from '../../../components/FormButton';
import { PhoneNumberField, phoneNumberValidation } from '../../../components/PhoneNumberField';
import { OrganisationStatus, Role } from '../../../graphql/types';
import { useIdentity } from '../../../components/hooks/useIdentity';

export function UserProfileForm({
  onSubmit,
  firstName,
  lastName,
  title,
  organisation,

  phoneNumber,
  email,
  buttonTitle,
  role,
  type
}: {
  type: 'add' | 'edit';
  onSubmit: ({
    email,
    firstName,
    lastName,
    phoneNumber,
    organisationId,
    position
  }: {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    organisationId: string;
    position: string[];
    title?: string;
    role: Role;
    resetForm?: (
      nextState?:
        | Partial<
            FormikState<
              Record<
                | 'title'
                | 'firstName'
                | 'lastName'
                | 'organisation'
                | 'position'
                | 'phoneNumber'
                | 'email'
                | 'role',
                any
              >
            >
          >
        | undefined
    ) => void;
  }) => void;
  firstName?: string;
  lastName?: string;
  title?: string;
  organisation?: { name: string; id: string; city: string; line1: string };
  phoneNumber?: string;
  email?: string;
  role?: Role;
  buttonTitle: string;
}) {
  const identity = useIdentity();
  if (identity == null) throw new Error('Invalid identity!');

  const [term, setTerm] = useState<string>('');
  const isSuperAdmin = identity?.role === 'superAdmin' || identity?.role === 'admin';

  const { data } = useQuery(query, {
    variables: {
      input: { pagination: { limit: 100, offset: 0 }, term, status: OrganisationStatus.enable }
    }
  });

  const { data: groupOptions } = useQuery(optionQuery);

  const formSpec = useMemo(() => {
    return createFormSpec({
      firstName: yup.string().nullable().required(`Meno je povinný údaj`),
      lastName: yup.string().nullable().required(`Priezvisko je povinný údaj`),
      title: yup.string().nullable(),
      organisation: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.string()
        })
        .nullable()
        .test('organisation', 'Organizácia zamestnanca je povinný údaj', value => {
          if (isSuperAdmin) {
            if (value == null) return false;
            return true;
          }

          return true;
        }),
      phoneNumber: phoneNumberValidation,
      email: yup
        .string()
        .nullable()
        .required(`Email zamestnanca je povinný údaj`)
        .email('Formát emailu je nesprávny'),
      role: yup.boolean(),
      position: yup.array().test('organisation', 'Pozícia je povinný údaj', value => {
        if (isSuperAdmin && type === 'edit') return true;

        if (type === 'add') {
          if (value == null || value.length === 0) return false;
        }

        return true;
      })
    });
  }, [identity]);

  return (
    <>
      <Formik
        {...formSpec({
          firstName: firstName ?? '',
          lastName: lastName ?? '',
          title: title ?? '',
          organisation: organisation
            ? {
                value: organisation.id,
                label: `${organisation.name} ${organisation.city} ${organisation.line1}`
              }
            : undefined,
          phoneNumber: phoneNumber ? phoneNumber.split('+421')[1] : '',
          email: email ?? '',
          role: role === Role.owner,
          position: []
        })}
        onSubmit={(
          { email, firstName, lastName, phoneNumber, position, organisation, title, role },
          { resetForm }
        ) => {
          return onSubmit({
            email,
            firstName,
            lastName,
            phoneNumber: `+421${phoneNumber}`,
            organisationId: organisation ? organisation.value : undefined,
            title,
            role: role ? Role.owner : Role.psychologist,
            position: position.map(item => item.value),
            resetForm
          });
        }}
      >
        <ConditionalForm>
          <Fields>
            <TextField name="firstName" label="Meno*" />
            <TextField name="lastName" label="Priezvisko*" />
            <TextField name="title" label="Titul*" />
            {isSuperAdmin && (
              <AutocompleteField
                label="Organizácia*"
                name="organisation"
                options={data?.organisationMany.items.map(item => {
                  return {
                    label: `${item.name} ${item.city} ${item.line1}`,
                    value: item.id
                  };
                })}
                onTermChange={setTerm}
                disabled={!isSuperAdmin}
              />
            )}
          </Fields>
          {(isSuperAdmin || identity?.role === 'owner') && type === 'add' && (
            <Box mb={2}>
              <MultiAutocompleteField
                label="Pozícia*"
                name="position"
                options={groupOptions?.positionGroups}
              />

              <CheckboxField name="role" label="Riadi a spravuje organizáciu" />
            </Box>
          )}
          <Fields>
            <PhoneNumberField />
            <TextField
              name="email"
              label="Email*"
              disabled={!isSuperAdmin && identity?.role !== 'owner'}
            />
          </Fields>
          <Box my={3}>
            <FormButton label={buttonTitle} backTo={routing.users.list} />
          </Box>
        </ConditionalForm>
      </Formik>
    </>
  );
}

const query = gql<OrganisationManyAutocompleteQueryGQL>`
  query OrganisationManyAutocompleteQuery($input: OrganisationFilterInput!) {
    organisationMany(input: $input) {
      total
      items {
        id
        name
        line1
        city
      }
    }
  }
`;

const optionQuery = gql<ProfilePositionGroupOptionsGQL>`
  query ProfilePositionGroupOptions {
    positionGroups {
      label
      value
    }
  }
`;
