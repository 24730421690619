import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routingPatterns } from '../../../Routes';
import { SendGroupForm } from './SendGroupForm';
import { SendIndividualForm } from './SendIndividualForm';

export function SendDiagnostic() {
  return (
    <>
      <Routes>
        <Route path={routingPatterns.diagnostic.send.individual} element={<SendIndividualForm />} />
        <Route path={routingPatterns.diagnostic.send.group} element={<SendGroupForm />} />
      </Routes>
    </>
  );
}
