import { Link } from '@everlutionsk/ui-router';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs as BreadcrumbsBase, styled, Typography } from '@mui/material';
import React from 'react';
import { DisabledPrint } from './DisabledPrint';

export interface BreadcrumbItem {
  readonly title: string;
  readonly path?: string;
}

interface Props {
  breadcrumbs?: BreadcrumbItem[];
}

export function Breadcrumbs({ breadcrumbs = [] }: Props) {
  return (
    <DisabledPrint>
      <BreadcrumbsBase
        separator={<NavigateNextIcon color="disabled" fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs.map((item, i) => {
          if (breadcrumbs.length === i + 1 || item.path == null) {
            return (
              <Title variant="caption" key={`breadcrumb${i}`} color="textPrimary">
                {item.title}
              </Title>
            );
          }

          return (
            <Link to={item.path} key={`breadcrumb${i}`}>
              <Title variant="caption" color="textPrimary">
                {item.title}
              </Title>
            </Link>
          );
        })}
      </BreadcrumbsBase>
    </DisabledPrint>
  );
}

const Title = styled(Typography)`
  margin-top: 0px;
  padding-top: 0px;
`;
