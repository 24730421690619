import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routingPatterns } from '../../Routes';
import { DiagnosticsList } from './DiagnosticsList';

export function Diagnostics() {
  return (
    <>
      <Routes>
        <Route path={routingPatterns.diagnostics.list} element={<DiagnosticsList />} />
      </Routes>
    </>
  );
}
