import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import React from 'react';
import { DefaultEvaluationFragment } from '../../../graphql/types';

export const defaultEvaluationFragment = gql`
  fragment DefaultEvaluationFragment on DefaultEvaluation {
    result
  }
`;

interface Props {
  data: DefaultEvaluationFragment;
}

export function DefaultResult({ data }: Props) {
  return (
    <>
      <Box mb={2} component={'pre'}>
        {JSON.stringify(JSON.parse(data.result), null, 2)}
      </Box>
    </>
  );
}
