import { TextField } from '@everlutionsk/ui-formik';
import { InputAdornment } from '@mui/material';
import React from 'react';
import * as yup from 'yup';

export function PhoneNumberField() {
  return (
    <TextField
      name="phoneNumber"
      label="Telefón*"
      inputProps={{ maxLength: 9 }}
      InputProps={{
        startAdornment: <InputAdornment position="start">+421</InputAdornment>
      }}
    />
  );
}

export const phoneNumberValidation = yup
  .string()
  .nullable()
  .min(9, 'Telefónne číslo musí obsahovať 9 číslic')
  .max(9, 'Telefónne číslo musí obsahovať 9 číslic');
