import { Table } from '@app/admin/src/components/Table';
import { Box, styled } from '@mui/material';
import React from 'react';

interface Props {
  questions: Array<{
    id: string;
    task: string;
  }>;
  data: Array<{
    id: string;
    match: boolean;
  }>;
}

export function T8AnswersTable2({ data, questions }: Props) {
  return (
    <TableWrapper mt={2}>
      <Table
        items={data}
        columns={[
          {
            label: 'Úloha',
            render: ({ id }) => {
              const question = questions.find(item => item.id === id);

              return `${question?.task}`;
            }
          },
          {
            label: 'Zhoda',
            render: ({ match }) => (match ? 'Áno' : 'Nie')
          }
        ]}
      />
    </TableWrapper>
  );
}

const TableWrapper = styled(Box)`
  thead {
    background-color: #f8f8f8;
  }
`;
