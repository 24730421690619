import { useConditionalField, useFieldError, useMemoizedField } from '@everlutionsk/ui-formik';
import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import React, { memo, useMemo, useState } from 'react';

export type TextFieldProps = Omit<
  MuiTextFieldProps,
  'name' | 'value' | 'error' | 'select' | 'SelectProps'
> & {
  /**
   * Name attribute of the `input` element.
   */
  readonly name: string;

  /**
   * Allows to format the value before it is pushed into Formik.
   *
   * HTML input value will be replaced with a formatted value on each blur event.
   *
   * Default formatter trims string values and normalizes invisible characters.
   *
   * Default formatter is not used when input type is "password"
   */
  readonly valueFormatter?: (value: any) => any;
};

/**
 * Material-UI TextField with Formik support.
 *
 * Supports text, email, number, date, datetime-local, tel, etc.
 */
export function DateField(props: TextFieldProps) {
  const { name } = props;

  useConditionalField(name, 'TextField');

  const error = useFieldError(name);
  const [field, , { setValue }] = useMemoizedField(name);

  return (
    <DateControl
      {...props}
      fieldValue={field.value}
      fieldOnBlur={field.onBlur}
      setValue={setValue}
      error={error}
    />
  );
}

interface ControlProps extends TextFieldProps {
  readonly fieldValue: any;
  readonly fieldOnBlur: (e: React.FocusEvent<any>) => void;
  readonly setValue: (value: any, shouldValidate?: boolean) => void;
  readonly error: string | undefined;
}

const DateControl = memo((props: ControlProps) => {
  const { fieldValue, fieldOnBlur, setValue, error, valueFormatter, ...muiProps } = props;

  const value = useMemo(() => {
    if (muiProps.type === 'month') {
      return fieldValue ?? '';
    }

    if (muiProps.type === 'week') {
      return fieldValue ?? '';
    }

    if (fieldValue == null) return '';
    if (typeof fieldValue !== 'string' && !(fieldValue instanceof Date)) {
      throw new Error(
        `${muiProps.name}: ${fieldValue} is not supported! Field value needs to be a string or a Date.`
      );
    }

    if (typeof fieldValue === 'string' && fieldValue.trim() === '') return '';

    const date = fieldValue instanceof Date ? fieldValue : new Date(fieldValue);
    if (date.toString() === 'Invalid Date') {
      throw new Error(
        `${muiProps.name}: ${fieldValue} is not a valid date! Field value needs to be a valid date.`
      );
    }

    if (muiProps.type === 'date') {
      const year = date.getUTCFullYear().toString().padStart(4, '0');
      const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
      const day = date.getUTCDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    if (muiProps.type === 'datetime-local') {
      const year = date.getFullYear().toString().padStart(4, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const minute = date.getMinutes().toString().padStart(2, '0');
      return `${year}-${month}-${day}T${hour}:${minute}`;
    }

    throw Error(`${muiProps.name}: Unknown date type`);
  }, [fieldValue, muiProps.type]);

  const [focused, setFocused] = useState(false);

  return (
    <MuiTextField
      {...muiProps}
      value={value}
      type={value === '' && !focused ? 'text' : muiProps.type}
      onChange={event => {
        const { value } = event.target;

        if (muiProps.type === 'month' || muiProps.type === 'week') {
          setValue(value);
        } else if (value === '') {
          setValue(null);
        } else {
          setValue(new Date(value).toISOString());
        }

        props.onChange?.(event);
      }}
      onBlur={event => {
        setFocused(false);
        fieldOnBlur(event);
        props.onBlur?.(event);
      }}
      onFocus={event => {
        setFocused(true);
        props.onFocus?.(event);
      }}
      error={error != null}
      helperText={error ?? props.helperText}
    />
  );
});
