import { gql, useQuery } from '@apollo/client';
import { Loading, LocaleDate } from '@everlutionsk/ui';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { ContentHeader } from '../components/ContentHeader';
import { TestItem } from '../components/TestItem';
import { TestingStatus } from '../graphql/types';

export function Dashboard() {
  const theme = useTheme();
  const { data } = useQuery(query, {
    variables: {
      input: {
        pagination: { limit: 2, offset: 0 },
        status: TestingStatus.readonly
      }
    }
  });

  if (data == null) {
    return <Loading />;
  }
  const noAnnouncements = data.announcementOne.announcements.length <= 0;

  return (
    <>
      <Box mb={10}>
        <Grid container spacing={3}>
          {!noAnnouncements && (
            <Grid item xs={12} md={8}>
              <Box mb={4}>
                <ContentHeader title="Dôležité oznamy" />
              </Box>
              {data?.announcementOne.announcements.map((item, index) => (
                <Box
                  key={index}
                  mb={3}
                  pt={3}
                  pb={1}
                  px={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  border={`1px solid ${theme.palette.primary.main}`}
                  minHeight="250px"
                  style={{
                    backgroundColor: '#fff'
                  }}
                >
                  <Box>
                    <Box mb={1}>
                      <Typography color="primary" variant="h6">
                        <LocaleDate date={item.date} variant="date" />
                      </Typography>
                    </Box>
                    <Box mb={3}>
                      <Typography variant="subtitle2">{item.title}</Typography>
                    </Box>
                    <Box mb={1}>
                      <Typography variant="body2">{item.text}</Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Grid>
          )}
          <Grid item xs={12} md={noAnnouncements ? 12 : 4}>
            <Box mb={4}>
              <ContentHeader title="Posledné testy" />
            </Box>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box mr={3}>
                  {data?.testingMany.items.map(item => (
                    <Box mb={3} key={item.id}>
                      <TestItem code={item.code} label={item.label ?? ''} />
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

const query = gql<LatestDiagnosticResultsQueryGQL>`
  query LatestDiagnosticResultsQuery($input: TestingFilterInput!) {
    testingMany(input: $input) {
      total
      items {
        id
        code
        label
        createdAt
      }
    }
    announcementOne {
      id
      announcements {
        title
        text
        date
      }
    }
  }
`;
