import Assignment from '@mui/icons-material/Assignment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleOutline from '@mui/icons-material/PeopleOutline';
import PersonOutline from '@mui/icons-material/PersonOutline';

import { routing } from '../Routes';

export const psychologistNavigationLayer = {
  title: '',
  items: [
    {
      title: 'Prehľad',
      icon: DashboardIcon,
      path: routing.dashboard,
      exact: true
    },
    {
      title: 'Testy',
      icon: Assignment,
      path: routing.diagnostics.list
    },
    {
      title: 'Videometodiky',
      icon: PersonOutline,
      path: '/video-list'
    },
    {
      title: 'Žiaci',
      icon: PeopleOutline,
      path: routing.students.list
    }
  ]
};

export const ownerPsychologistNavigationLayer = {
  title: '',
  items: [
    {
      title: 'Prehľad',
      icon: DashboardIcon,
      path: routing.dashboard,
      exact: true
    },
    {
      title: 'Testy',
      icon: Assignment,
      path: routing.diagnostics.list
    },
    {
      title: 'Videometodiky',
      icon: PersonOutline,
      path: '/video-list'
    },
    {
      title: 'Zamestnanci',
      icon: PersonOutline,
      path: routing.users.list
    },
    {
      title: 'Žiaci',
      icon: PeopleOutline,
      path: routing.students.list
    },
    {
      title: 'Organizácia',
      icon: PersonOutline,
      path: 'organisation'
    }
  ]
};
