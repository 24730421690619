import { createApolloClient } from '@everlutionsk/helpers-apollo-react';
import { applyYupLocalePreset } from '@everlutionsk/ui-formik';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from '../admin/src/pages/App';
import { environment } from './src/environment';
import { possibleTypes } from './src/graphql/possibleUnionTypes';
import { sso } from './src/sso';

if (!sso.hasLocalSession()) {
  sso.signIn({ redirect: new URL(new URL(window.location.href).origin) });
} else {
  const root = createRoot(document.getElementById('root')!);

  const apollo = createApolloClient({
    url: environment.apiUrl,
    cacheConfig: { possibleTypes },
    onUnauthorized: () => sso.signIn,
    batchInterval: 10
  });

  applyYupLocalePreset('en');

  // if (!environment.isProd) {
  //   document.head.querySelector('#favicon')?.setAttribute('href', '/favicon-dev.png');
  // }

  root.render(<App apollo={apollo} />);
}
