import { gql } from '@apollo/client';
import { populated } from '@everlutionsk/helpers';
import { Loading, LocaleDate } from '@everlutionsk/ui';
import { Table } from '../../components/Table';
import { useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { Link, useParams } from '@everlutionsk/ui-router';
import React from 'react';
import { ContentHeader } from '../../components/ContentHeader';
import { StudentOneDetailQuery } from '../../graphql/types';
import { routing } from '../../Routes';
import { useIdentity } from '../../components/hooks/useIdentity';

export function Diagnostics({
  student
}: {
  student: Pick<StudentOneDetailQuery['studentOne'], 'firstName' | 'lastName'> | undefined;
}) {
  const identity = useIdentity();
  const { id } = useParams(['id']);

  const { data, pagination, error } = useOffsetPaginatedQuery(query, {
    fetchPolicy: 'no-cache',
    variables: ({ limit, offset }) => ({
      input: { pagination: { limit, offset }, studentId: id }
    }),
    total: data => data.testingMany.total
  });

  if (!populated(data) || !populated(student)) return <Loading />;

  return (
    <>
      <ContentHeader
        title="Výsledky žiaka"
        breadcrumbs={[
          { title: `Domov` },
          { title: 'Žiaci', path: routing.students.list },
          {
            title: `${student.firstName} ${student.lastName}`,
            path: routing.student.detail(id)
          },
          { title: `Výsledky žiaka` }
        ]}
      />
      <Table
        pagination={pagination}
        items={data.testingMany.items}
        error={error}
        columns={[
          {
            label: 'Kód testu',
            render: item => (
              <Link to={routing.diagnostic.prepare(item.code)} color="secondary">
                {item.code}
              </Link>
            )
          },
          {
            label: 'Popis testu',
            render: item => item.label
          },
          {
            label: 'Dátum testovania',
            render: item => <LocaleDate date={item.createdAt} variant="dateTime" />
          },
          {
            label: 'Výsledky',
            render: item => {
              if (item.user.id !== identity?.id && identity?.role === 'owner') return '-';

              return (
                <Link
                  to={routing.diagnostic.result(item.code, item.originalToken)}
                  color="secondary"
                >
                  Ukáž
                </Link>
              );
            }
          },
          {
            label: 'Odborník',
            render: item =>
              item.user ? (
                <Link to={routing.user.profile(item.user.id)} color="secondary">
                  {item.user.fullName}
                </Link>
              ) : (
                '-'
              )
          }
        ]}
      />
    </>
  );
}

const query = gql<StudentDiagnosticsQueryGQL>`
  query StudentDiagnosticsQuery($input: TestingFilterInput!) {
    testingMany(input: $input) {
      total
      items {
        id
        code
        label
        originalToken
        student {
          id
          fullName
        }
        user {
          id
          fullName
        }
        createdAt
      }
    }
  }
`;
