import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { T309EvaluationFragment } from '../../../graphql/types';
import { ClientTable } from './components/ClientTable';
import { T309ResultsTable } from './components/T309/T309ResultsTable';

export const t309EvaluationFragment = gql`
  fragment T309EvaluationFragment on T309Evaluation {
    items {
      label
      totalRoughScore
      percentile
    }
  }
`;

interface Props {
  client: {
    fullName: string;
    dateOfBirth: Date;
    gender: string;
    testingDate: Date;
  };
  data: T309EvaluationFragment;
}

export function T309Result({ data, client }: Props) {
  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Klient</Typography>
      </Box>
      <ClientTable data={client} />
      <Box my={2}>
        <Typography variant="h4">Výsledky</Typography>
      </Box>
      <T309ResultsTable data={data} />
    </>
  );
}
