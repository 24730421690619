import { useNavigate } from '@everlutionsk/ui-router';
import { useEffect } from 'react';

export function ExternalRedirect({ url }) {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = url;
    navigate('/', { replace: true });
  }, [url, navigate]);

  return '/';
}
