import { gql, useMutation, useQuery } from '@apollo/client';
import { call } from '@everlutionsk/helpers';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { Link, useParams } from '@everlutionsk/ui-router';
import { Box, Button, Grid, styled, Typography } from '@mui/material';
import React from 'react';
import { routing } from '../../../Routes';
import { ContentHeader } from '../../../components/ContentHeader';
import { DiagnosticForm } from '../../../graphql/types';
import { groupTestCodes, openInNewTab, remoteTestCodes } from '../../../helpers';
import { toAnnotation } from '../../../toDescription';
import group from './group.svg';
import individual from './individual.svg';
import pdfIcon from './pdfIcon.svg';
import remotely from './remotely.svg';
import { ArrowForward, ArrowForwardIos } from '@mui/icons-material';

export function AllForms() {
  const { code } = useParams(['code']);

  const { data } = useQuery(manualManyQuery, {
    variables: { input: { limit: 50, code } }
  });

  const [getManualUrl] = useMutation(manualUrlQuery);
  const [mutate] = useFlashMutation(mutation, {
    successMsg: 'Test bol spustený',
    errorMsg: 'Test nebol spustený',
    onCompleted: data => {
      openInNewTab(data.demoTesting.url);
    }
  });

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Box mb={4}>
          <ContentHeader
            title={toTitle({ code })}
            breadcrumbs={[
              { title: 'Domov' },
              { title: `Testy`, path: '/diagnostics' },
              { title: code }
            ]}
          />
          <Title>
            {call(() => {
              if (code === 'T-309') return `${code}, forma L`;
              if (code === 'T-95') return `${code}/PTU/AVLT`;
              if (code === 'T-41') return `${code}/TMT`;
              return code;
            })}
          </Title>
        </Box>
        <Box
          mb={7}
          style={{
            whiteSpace: 'pre-line'
          }}
        >
          <Typography>{toAnnotation({ code })}</Typography>
        </Box>
        <Box>
          <Box mb={3}>
            <Typography variant="h5">Spustiť testovanie</Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CardItem
                img={individual}
                title="Individuálne"
                to={routing.diagnostic.prepare(code, DiagnosticForm.individual)}
              />
            </Grid>

            {groupTestCodes.includes(code) && (
              <Grid item xs={12} md={4}>
                <CardItem
                  img={group}
                  title="Skupinové"
                  to={routing.diagnostic.prepare(code, DiagnosticForm.group)}
                />
              </Grid>
            )}

            {remoteTestCodes.includes(code) && (
              <Grid item xs={12} md={4}>
                <CardItem
                  img={remotely}
                  title="Vzdialené"
                  to={routing.diagnostic.prepare(code, DiagnosticForm.remote)}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={1}></Grid>
      <Grid item xs={12} md={3}>
        <Box display="flex" mb={2} mt={3}>
          <Button
            fullWidth
            variant="contained"
            style={{ marginRight: '10px' }}
            color="secondary"
            onClick={() =>
              mutate({
                variables: {
                  input: {
                    code
                  }
                }
              })
            }
          >
            Spustiť demo
          </Button>
        </Box>
        <Box display="flex" flexDirection="column" mb={7}>
          <Typography variant="caption">Spustenie demo testu umožnuje:</Typography>
          <Typography variant="caption">- prezretie testu</Typography>
          <Typography variant="caption">- neovplyvnuje výsledky</Typography>
          <Typography variant="caption">- výsledky nebudú uložené</Typography>
        </Box>

        <Box>
          <Box mb={3}>
            <Typography variant="h5">Manuály k testu</Typography>
          </Box>

          <Box display="flex" flexDirection="column">
            {data?.manualMany.map(item => (
              <Box
                mb={1}
                key={item.id}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                style={{
                  border: `1px solid #fff`,
                  backgroundColor: '#fff',
                  boxShadow: '3px 2px 5px #ddd',
                  cursor: 'pointer'
                }}
                onClick={async () => {
                  const response = await getManualUrl({ variables: { path: item.path } });

                  if (response.data == null) return;
                  downloadUrlTarget(response.data.manualUrl);
                }}
              >
                <Box ml={1} display="flex" alignItems="center">
                  <Box mr={2}>
                    <img src={pdfIcon} alt="pdf" />
                  </Box>

                  <Typography style={{ overflowWrap: 'anywhere' }} variant="caption">
                    {item.name}
                  </Typography>
                </Box>
                <Box>
                  <ArrowForwardIos style={{ fontSize: '30px', color: '#ddd' }} />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

const manualManyQuery = gql<ManualManyGQL>`
  query ManualMany($input: ManualManyInput!) {
    manualMany(input: $input) {
      id
      code
      name
      path
    }
  }
`;

const manualUrlQuery = gql<ManualUrlGQL>`
  mutation ManualUrl($path: String!) {
    manualUrl(path: $path)
  }
`;

const mutation = gql<DemoTestingMutationGQL>`
  mutation DemoTestingMutation($input: DemoTestingInput!) {
    demoTesting(input: $input) {
      url
    }
  }
`;

function downloadUrlTarget(url: string) {
  const element = document.createElement('a');
  element.href = url;
  element.target = '_parent';
  element.download = 'export.pdf';
  element.click();
}

function toTitle({ code }: { code: string }) {
  if (code === 'T-8') {
    return 'Obrázkovo-slovníková skúška';
  }
  if (code === 'T-41') {
    return 'Test cesty';
  }
  if (code === 'T-216') {
    return 'Dotazník školského sebahodnotenia dieťaťa(SPAS)';
  }
  if (code === 'T-202') {
    return 'Skúška čítania / Hodnotenie závažnosti porúch čítania';
  }
  if (code === 'T-21') {
    return 'B - J.E.P.I.';
  }
  if (code === 'T-239') {
    return 'Diagnostika špecifických porúch učenia';
  }
  if (code === 'T-184') {
    return 'Škála štandardizovaných dotazníkov';
  }
  if (code === 'T-252') {
    return 'Dotazník na zisťovanie hodnotových orientácií, postojov k hodnotám a motivácie výkonu (HO-PO-MO)';
  }
  if (code === 'T-309') {
    return `Test analýzy štruktúry inteligencie, forma L (ISA-L)`;
  }
  if (code === 'SMKZD') {
    return 'Škála miesta kontroly Zemanová a Dolejš';
  }
  if (code === 'T-95') {
    return `Pamäťový test učenia`;
  }

  return 'Škála rizikového správania žiaka';
}

export const Title = styled('div')`
  font-weight: 400;
  font-size: 40px;
  margin-top: -5px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

function CardItem({ img, title, to }: { img: string; title: string; to: string }) {
  return (
    <HoverBox pb={1} pt={3} display="flex" flexDirection="column">
      <Box
        px={3}
        mb={3}
        justifyContent="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box mb={3}>
          <img src={img} alt="remotely" />
        </Box>
        <Typography
          style={{
            margin: 0
          }}
          variant="h2"
          color="primary"
        >
          {title}
        </Typography>
        <Typography variant="subtitle1" color="primary">
          testovanie
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Link style={{ display: 'flex', alignItems: 'center' }} color="secondary" to={to}>
          <Typography variant="caption">Spustiť testovanie </Typography>
          <ArrowForward />
        </Link>
      </Box>
    </HoverBox>
  );
}

const HoverBox = styled(Box)`
  background-color: #fff;
  border: ${({ theme }) => `1px solid ${theme.palette.primary.main}`};
  &:hover,
  &:focus {
    background-color: #f7f7f78d;
    cursor: pointer;
    transform: scale3d(1.016, 1.016, 1);
  }
`;
