import { Box, Card, Grid } from '@mui/material';
import React, { ReactNode } from 'react';

export function FormWrapper({ children }: { children: ReactNode }) {
  return (
    <Grid item xs={12} md={10}>
      <Card>
        <Box p={3}>{children}</Box>
      </Card>
    </Grid>
  );
}
