import { gql } from '@apollo/client';
import { call } from '@everlutionsk/helpers';
import { Table } from '@app/admin/src/components/Table';
import { useFlashMutation, useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { Link, LinkButton, useNavigate, useParams } from '@everlutionsk/ui-router';
import { AppBar, Box, Button, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { routing } from '../../../Routes';
import { ContentHeader } from '../../../components/ContentHeader';
import { CustomChip } from '../../../components/CustomChip';
import { ReassignmentStudentDialog } from '../../../components/ReassignmentStudentDialog';
import { SearchBar } from '../../../components/SearchBar';
import { TabPanel } from '../../../components/TabPanel';
import { StudentStatus } from '../../../graphql/types';
import { prettifyGender } from '../../Diagnostic/utils';
import { useIdentity } from '../../../components/hooks/useIdentity';

export function Students() {
  const identity = useIdentity();
  if (identity == null) throw Error('Access Denied! Missing valid identity.');
  const { id } = useParams(['id']);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const { data, pagination, error } = useOffsetPaginatedQuery(query, {
    fetchPolicy: 'no-cache', // TODO: use correct approach for caching
    variables: ({ limit, offset }) => ({
      input: {
        pagination: { limit, offset },
        ownerId: id,
        term: searchTerm,
        status: value === 0 ? StudentStatus.active : StudentStatus.inactive
      },
      userId: identity.id
    }),
    total: data => data.newStudentMany.total
  });

  const [reassign] = useFlashMutation(reassignmentMutation, {
    successMsg: 'Preradenie prebehlo úspešne.',
    errorMsg: 'Nepodarilo sa vykonať preradenie.'
  });

  const displayAddStudent = call(() => {
    const unauthorizedPositions = data?.userOne.position.find(position =>
      ['8195e772-1d53-47d7-9e61-e734a6b24518', '1d305633-f752-4581-9548-0cb139377923'].includes(
        position.id
      )
    );

    if (unauthorizedPositions != null) return false;

    if (['superAdmin', 'admin', 'psychologist', 'owner'].includes(identity.role)) return true;

    return false;
  });

  return (
    <>
      <ContentHeader title="Žiaci" />
      <Box mb={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box width="60%">
            <SearchBar onSearch={value => setSearchTerm(value)} />
          </Box>
          <Box display="flex" alignItems="center" width="40%">
            {displayAddStudent && (
              <>
                <LinkButton
                  style={{ marginRight: '12px' }}
                  fullWidth
                  color="secondary"
                  size="medium"
                  to={routing.user.addStudent(id)}
                  variant="contained"
                >
                  + Pridať žiaka
                </LinkButton>
                <Button onClick={() => setOpen(true)} fullWidth color="secondary" size="medium">
                  Preradiť žiakov
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <AppBar
        position="static"
        style={{
          backgroundColor: '#fff',
          borderBottom: '1px solid #ddd'
        }}
      >
        <Tabs
          value={value}
          onChange={(_, newValue) => setValue(newValue as any)}
          aria-label="simple tabs example"
          indicatorColor="primary"
        >
          <Tab
            label={`Aktívni ${value === 0 && data != null ? `(${data.newStudentMany.total})` : ''}`}
          />
          <Tab label="Archivovaní" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Table
          pagination={pagination}
          items={data?.newStudentMany.items}
          error={error}
          columns={[
            {
              label: 'Meno žiaka',
              render: item => (
                <Link to={routing.student.detail(item.id)} color="secondary">
                  {item.fullName}
                </Link>
              )
            },
            {
              label: 'Pohlavie',
              render: item => (item.gender ? prettifyGender(item.gender) : '-')
            },
            {
              label: 'Škola',
              render: item => item.school
            },
            {
              label: 'Status',
              render: ({ status }) => (
                <CustomChip
                  color="success"
                  label={status === StudentStatus.active ? 'Aktívni' : '-'}
                />
              )
            },
            {
              label: 'Odborník',
              render: item =>
                item.owner ? (
                  <Link to={routing.user.profile(item.owner.id)} color="secondary">
                    {item.owner.fullName}
                  </Link>
                ) : (
                  '-'
                )
            }
          ]}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Table
          pagination={pagination}
          items={data?.newStudentMany.items}
          error={error}
          columns={[
            {
              label: 'Meno žiaka',
              render: item => (
                <Link to={routing.student.detail(item.id)} color="secondary">
                  {item.fullName}
                </Link>
              )
            },
            {
              label: 'Pohlavie',
              render: item => (item.gender ? prettifyGender(item.gender) : '-')
            },
            {
              label: 'Škola',
              render: item => item.school
            },
            {
              label: 'Status',
              render: ({ status }) => (
                <CustomChip
                  color="disabled"
                  label={status === StudentStatus.inactive ? 'Archivovaní' : '-'}
                />
              )
            },
            {
              label: 'Odborník',
              render: item =>
                item.owner ? (
                  <Link to={routing.user.profile(item.owner.id)} color="secondary">
                    {item.owner.fullName}
                  </Link>
                ) : (
                  '-'
                )
            }
          ]}
        />
      </TabPanel>
      <ReassignmentStudentDialog
        onSubmit={async ownerId => {
          await reassign({ variables: { ownerId: id, newOwnerId: ownerId } });

          navigate(routing.user.students(ownerId));
        }}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

export const query = gql<UserStudentManyGQL>`
  query UserStudentMany($input: StudentFilterInput!, $userId: ID!) {
    userOne(id: $userId) {
      id
      position {
        id
      }
    }
    newStudentMany(input: $input) {
      total
      items {
        id
        fullName
        gender
        school
        status
        owner {
          id
          fullName
        }
      }
    }
  }
`;

const reassignmentMutation = gql<ReassignmentStudentManyGQL>`
  mutation ReassignmentStudentMany($ownerId: ID!, $newOwnerId: ID!) {
    reassignmentStudentMany(ownerId: $ownerId, newOwnerId: $newOwnerId)
  }
`;
