import { styled } from '@mui/material';
import React, { ReactNode } from 'react';

export function DisabledPrint({ children }: { children: ReactNode }) {
  return <Print>{children}</Print>;
}

const Print = styled('div')`
  @media print {
    display: none;
  }
`;
