export function toShortDescription({ code }: { code: string }) {
  if (code === 'T-8') {
    return `Kondáš, 2010
    Test sa primárne zameriava na zisťovanie školskej zrelosti, t.j. pri hľadaní odpovede na otázku, či dieťa má byť alebo nemá byť zaškolené.`;
  }
  if (code === 'T-216') {
    return `Matejček a Vágnerová, 1992

    SPAS - Dotazník školského sebahodnotenia dieťaťa sa zameriava na to, akú majú žiaci predstavu o svojich schopnostiach a úspechoch súvisiacich so školou.`;
  }
  if (code === 'T-239') {
    return `Diagnostika špecifických porúch učenia

    M. Novák, 2002

    Viacdimenzionálny test zameraný na posudzovanie štruktúry a úrovne jazykových schopností u detí s ťažkosťami s nadobúdaním základných čitateľských, písacích a pravopisných spôsobilostí.`;
  }

  if (code === 'T-184') {
    return `A. Mezera, 2000

    Batéria škálových štandardizovaných dotazníkov využíva metódu sumovaných odhadov a je zameraná na diagnostiku výskytu a intenzity mnohých atypických prejavov v oblasti rizikového sociálneho a školského správania.`;
  }
  if (code === 'T-252') {
    return `J. Vonkomer, 1991
    Dotazník HO - PO - MO sa zameriava na zisťovanie hodnotových orientácii, postojov k hodnotám a motivácie výkonu.`;
  }
  if (code === 'T-202') {
    return `Z. Matějček a kol., 1992

    Test je nástrojom na meranie úrovne čitateľskej zručnosti detí.
`;
  }
  if (code === 'T-21') {
    return `Eysenck, Eysenck, úpr. Senka, 1994
    B - J.E.P.I. je Eysenckov osobnostný dotazník pre deti, ktorý meria 3 základné osobnostné črty: extraverziu, neurotizmus a psychotizmus.`;
  }
  if (code === 'T-309') {
    return `ISA je metodikou diagnostikovania všeobecných intelektových schopností. Forma L predstavuje jeho skrátenú ľahšiu verziu.`;
  }
  if (code === 'T-41') {
    return `M. Preiss a J. Preiss, 2006

    Neuropsychologický test, ktorý sleduje kognitívne procesy a výkonnosť mozgu.`;
  }
  if (code === 'SMKZD') {
    return `Zemanová, Dolejš, 2016; slovenská verzia Kendrová a kol., 2023

    Škála sa zameriava na meranie miesta kontroly u adolescentov. Ide o jednodimenzionálny konštrukt, podľa ktorého možno rozlišovať interné alebo externé miesto kontroly.`;
  }
  if (code === 'OTPR') {
    return `Orientačný test porozumenia reči OTPR je krátka hravá skríningová metóda
    poskytujúca prehľad o špecifických prejavoch dieťaťa v oblasti porozumenia reči. Ide
    o manipulačný test.`;
  }
  if (code === 'T-95') {
    return `M. Preiss, 1999
    Test skúma krátkodobú a dlhodobú verbálnu pamäť, pozornosť a schopnosť učiť sa.`;
  }

  return 'Škála rizikového správania žiaka';
}

export function toAnnotation({ code }: { code: string }) {
  if (code === 'T-8') {
    return `Obrázkovo-slovníková skúška je jednou z psychologických pomôcok na vyšetrovanie určitej dimenzie zrelosti dieťaťa pre školu. Test zisťuje slovnú zásobu a slovnú pohotovosť predovšetkým u detí pred vstupom do školy (vo veku od 5 rokov, 6 mesiacov až do 6 rokov, 6 mesiacov). Je však prístupná aj mladším deťom (v období staršieho predškolského veku), kde ju možno použiť ako skúšku slovnej zásoby a verbálnej pohotovosti. Okrem toho sa dá použiť ako test vizuálnej vštepivosti (pamäťovej recepcie). Test pozostáva z 30 farebných obrázkov (predmetov, zvierat a ľudských činností), jeho administrácia trvá približne 5 minút. K dispozícii sú percentilové normy a vyjadrenie výsledku v stupňoch (1 - 5 a defektný stupeň).`;
  }
  if (code === 'T-216') {
    return `Dotazník školského sebahodnotenia dieťaťa (SPAS) je sebavýpoveďový nástroj, ktorý sa zameriava na to, akú majú žiaci predstavu o svojich schopnostiach a úspechoch súvisiacich so školou. Je určený pre žiakov od 4 do 9 ročníka. Obsahuje 48 položiek, ktoré sú rovnomerne rozdelené do 6 subškál (Všeobecné schopnosti, Matematika, Čítanie, Pravopis, Písanie a Sebadôvera). Výsledky je možné interpretovať na úrovni celkového hrubého skóre, ale aj jednotlivých subškál samostatne. Tieto výsledky je následne možné interpretovať na základe percentilových a stenových noriem, ktoré sú rozdelené na základe pohlavia a veku.`;
  }
  if (code === 'T-239') {
    return `Diagnostika špecifických porúch učenia je viacdimenzionálny test zameraný na posudzovanie štruktúry a čiastočne aj úrovne jazykových schopností detí, u ktorých sa prejavujú zreteľné ťažkosti s nadobúdaním a používaním základných čitateľských, písacích a pravopisných spôsobilostí. test sa zameriava na oblasti: auditívne a vizuálne percepcie, jemnú motoriku, lateralitu, čitateľské, písacie a pravopisné spôsobilosti, audiomotorickú a vizuomotorickú koordináciu a zrakovú pamäť.`;
  }

  if (code === 'T-184') {
    return `Batéria štandardizovaných dotazníkov pozostáva zo Škálového dotazníka školského správania žiaka a zo Škály rizikového správania žiaka. Batéria využíva metódu sumovaných odhadov a je zameraná na diagnostiku výskytu a intenzity mnohých atypických prejavov v oblasti rizikového sociálneho a školského správania. K dispozícii sú percentilové normy pre deti od 7 - 15 rokov podľa veku a pohlavia.`;
  }
  if (code === 'T-252') {
    return `Dotazník HO - PO - MO sa zameriava na zisťovanie troch oblastí, ktorými sú hodnotové orientácie, postoje k hodnotám a motivácia k výkonu. Dotazník pozostáva z 13 otázok a úlohou respondentov je priradiť poradie k alternatívnym odpovediam (napr. od 1 do 10), na základe vnímania ich dôležitosti. Čím je priradená hodnota nižšia, tým je daná alternatívna vnímaná za dôležitejšiu a preferovanejšiu. Dotazník je možné použiť od 14 rokov. K dispozícii sú percentilové a stenové normy pre jednotlivé hodnotové oblasti.`;
  }
  if (code === 'T-202') {
    return `Skúška čítania je nástrojom na meranie úrovne čitateľskej zručnosti detí, pričom výsledky z nej možno ďalej použiť pri odlíšení porúch, prípadne diagnostiky odchýlok v psychickom výkone (napr. pri dyslexii). Využíva sa pri hodnotení vyspelosti, prípadne oneskorenia čítacej zručnosti. Obsahuje 11 textov odstupňovaných podľa čitateľskej náročnosti. Pri čítaní sa hodnotí široká škála ukazovateľov, pričom k najdôležitejším patrí rýchlosť čítania (za prvú minútu), počet chýb, spôsob čítania a reprodukcia textu. K dispozícii sú novovytvorené normy pre 9 textov, v rozdelení podľa pohlavia a podľa navštevovaného ročníka.`;
  }
  if (code === 'T-21') {
    return `B-JEPI je Eysenckov osobnostný dotazník pre deti, ktorý meria 3 základné osobnostné črty: extraverziu, neurotizmus a psychotizmus. Navyše obsahuje aj tzv. lži skóre, ktoré je rovnako dôležité pre spoľahlivú interpretáciu výsledkov.

    Výsledky je možné interpretovať na základe percentilových noriem, ktoré sú rozdelené podľa pohlavia a veku (9 - 15 rokov).`;
  }
  if (code === 'T-309') {
    return `Test analýzy štruktúry inteligencie (ISA) je metodikou diagnostikovania všeobecných intelektových schopností. Teoreticky vychádza z konceptu testu štruktúry inteligencie (IST) Rudolfa Amthauera. Pozostáva z 9 skupín úloh (subtestov) zameraných na preskúmanie intelektu v oblasti pozornosti, vštepovania si informácií, chápania čísel, reči, priestorovej a figurálnej predstavivosti. Okrem odhadu úrovne výkonu v danom subteste podáva analytický pohľad na všeobecné intelektové schopnosti. Forma L (ISA-L) predstavuje jeho skrátenú ľahšiu verziu. Obsahuje 108 úloh s viacnásobnou možnosťou výberu odpovede (tzv. multichoice), určených pre žiakov nad 14 rokov (v špecifických prípadoch od 13 rokov). K dispozícii sú normy v percentiloch, T-skóroch a staninách. Normy sú rozdelené do troch vekových skupín 13, 14 a 15 rokov.`;
  }
  if (code === 'T-41') {
    return `Test cesty, originálne Trail Making Test (TMT), je neuropsychologický test, ktorý sleduje kognitívne procesy a výkonnosť mozgu. Skladá sa z dvoch častí, z toho každá skúma iné kognitívne schopnosti. Časť A sa zameriava na celkové psychomotorické tempo, vizuálnu koordináciu, pozornosť a zrakové vyhľadávanie. Kognitívne náročnejšia časť B je ukazovateľom nielen psychomotorického tempa, ale aj flexibility, kognitívnej pružnosti, rozdelenej pozornosti a vizuomotorickej koordinácie. Skrátená detská verzia testu je určená pre deti vo veku od 9 - 14 rokov, k dispozícii sú novovytvorené percentilové normy podľa veku (z r. 2023, viď Dodatok k príručke). Pre deti staršie ako 15 rokov sa odporúča použiť originálnu verziu testu pre dospelých, k nej sú k dispozícii staršie normy.`;
  }
  if (code === 'SMKZD') {
    return `Škála miesta kontroly Zemanová a Dolejš je určená na meranie miesta kontroly u adolescentov. Miesto kontroly je jednodimenzionálny konštrukt, podľa ktorého možno rozlišovať interné alebo externé miesto kontroly. Interné alebo externé miesto kontroly zodpovedá tomu, do akej miery jednotlivec očakáva, že budú následky jeho správania podmienené jeho vlastným správaním, osobnostnými charakteristikami alebo sú naopak dané mocou iných ľudí, šťastím, náhodou či osudom. Škálu tvorí 12 položiek, z ktorých je 6 zameraných na externé miesto kontroly a 6 na interné miesto kontroly.

    K dispozícií sú percentilové a stenové normy pre adolescentov vo veku od 10 do 15 rokov, ktoré vychádzajú zo štandardizácie formou pero-papier.`;
  }
  if (code === 'OTPR') {
    return `Orientačný test porozumenia reči OTPR je krátka hravá skríningová metóda
    poskytujúca prehľad o špecifických prejavoch dieťaťa v oblasti porozumenia reči. Ide
    o manipulačný test.
    Test je rozdelený na 3 varianty, variant A je určený deťom od 4 rokov do 4 rokov a 11
    mesiacov, variant B je určený deťom od 5 rokov do 5 rokov a 11 mesiacov a variant C je určený
    deťom od 6 rokov do 6 rokov a 11 mesiacov.
    Na základe výsledkov je možné zaradiť dieťa do jednej zo štyroch kategórii (bez
    ťažkostí, mierne ťažkosti, ťažkosti a nezvládnutie testu) a následne odporúčať dodatočné
    psychologické vyšetrenie diagnostickými testami alebo vyšetrenie iného zdravotného
    špecialistu.`;
  }
  if (code === 'T-95') {
    return `Pamäťový test učenia skúma krátkodobú a dlhodobú verbálnu pamäť, pozornosť a taktiež schopnosť učiť sa. Nadväzuje na švajčiarsky originál Rey´s Auditory Verbal Learning Test (RAVLT, prípadne AVLT; Rey, 1958). Ide o reprodukciu slov, kde úlohou jednotlivca je vybaviť si čo najväčší počet počutých slov. Je zameraný na bezprostredné vybavenie počutých slov, oddialené vybavenie po interferencii a oddialené vybavenie po dlhšom časovom intervale. Test v praxi pomáha najmä pri diagnostike ochorení a porúch, ktoré sú špecifické narušenou verbálnou pamäťou, ale taktiež aj porúch učenia alebo emočných porúch. K dispozícii sú novovytvorené percentilové normy pre deti od 9 do 15 rokov na základe veku a pohlavia.`;
  }

  return 'Škála rizikového správania žiaka';
}
