import { Chip, styled } from '@mui/material';
import React from 'react';

interface Props {
  readonly label: string;
  readonly color: string;
}

export function CustomChip({ color, ...props }: Props) {
  return <StyledChip className={color} {...props} />;
}

const StyledChip = styled(Chip)`
  text-transform: uppercase;
  padding: 3px 15px;
  border-radius: 3px;
  height: auto;
  color: #fff;
  .MuiChip-label {
    padding: 0;
  }
  &.warning {
    background-color: ${({ theme }) => theme.palette.error.main};
  }
  &.disabled {
    background-color: #b6c0d3;
  }
  &.success {
    background-color: ${({ theme }) => theme.palette.success.main};
  }
`;
