import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { SmkzdEvaluationFragment } from '../../../graphql/types';
import { ClientTable } from './components/ClientTable';

export const smkzdEvaluationFragment = gql`
  fragment SmkzdEvaluationFragment on SmkzdEvaluation {
    n
    percentil
    sten
    roughScore
    percentilTotal
    stenTotal
  }
`;

interface Props {
  client: {
    fullName: string;
    dateOfBirth: Date;
    gender: string;
    testingDate: Date;
  };
  data: SmkzdEvaluationFragment;
}

export function SmkzdResult({ client, data }: Props) {
  // todo: add answers as per t216
  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Klient</Typography>
      </Box>
      <ClientTable
        data={{
          ...client
        }}
      />
      <Box my={2}>
        <Typography variant="h4">Výsledky</Typography>
      </Box>

      <ResultsTable label="hrubé skóre" value={data.roughScore} />

      <Box mt={2}>
        <Typography variant="h6">Porovnanie s populáciou (N = 1553)</Typography>
      </Box>
      <ResultsTable label="percentil celkom" value={data.percentilTotal} />
      <ResultsTable label="sten celkom" value={data.stenTotal} />

      <Box mt={2}>
        <Typography variant="h6">Porovnanie podľa pohlavia</Typography>
      </Box>
      <ResultsTable label="N" value={data.n} />
      <ResultsTable label="percentil podľa pohlavia" value={data.percentil} />
      <ResultsTable label="sten podľa pohlavia" value={data.sten} />
    </>
  );
}

const borderStyle = '1px solid #d6d6d6';

function ResultsTable({ label, value }: { label: string; value: string | number }) {
  return (
    <Box display="flex" flexDirection="column" width="100%" boxShadow={2}>
      <Box display="flex" sx={{ borderBottom: borderStyle }}>
        <Box
          width="30%"
          textAlign="left"
          sx={{
            bgcolor: '#f8f8f8'
          }}
          p={1}
        >
          <Typography variant="body2">{label}</Typography>
        </Box>
        <Box width="70%" textAlign="right" sx={{ bgcolor: '#ffffff' }} p={1}>
          <Typography variant="body2"> {value}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
