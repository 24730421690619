import { gql } from '@apollo/client';
import { Box, styled, TextField, Typography } from '@mui/material';
import React from 'react';
import { T184EvaluationFragment } from '../../../graphql/types';
import { ClientTable } from './components/ClientTable';
import { T184ResultsTable } from './components/T184/T184ResultsTable';

export const t184EvaluationFragment = gql`
  fragment T184EvaluationFragment on T184Evaluation {
    items {
      label
      totalRoughScore
      percentileByGenderAndAge: percentile
    }
    additionalText
    version
  }
`;

interface Props {
  client: {
    fullName: string;
    dateOfBirth: Date;
    gender: string;
    testingDate: Date;
    details: {
      t184subject: string;
    };
  };
  data: T184EvaluationFragment;
}

export function T184Result({ client, data }: Props) {
  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Klient</Typography>
      </Box>
      <ClientTable data={client} />
      <Box my={2}>
        <Typography variant="h4">Výsledky</Typography>
      </Box>
      <T184ResultsTable data={data} />
      <TextAreaWrapper my={3}>
        <Box mb={1}>
          <Typography>
            {data.version === 'V1'
              ? 'Ďalšie charakteristiky prejavov správania žiaka'
              : 'Ďalšie charakteristiky sociálneho správania žiaka'}
          </Typography>
        </Box>
        <TextField
          style={{ width: '100%' }}
          id={'additionalText'}
          name={'additionalText'}
          type="text"
          variant="outlined"
          multiline
          value={data.additionalText}
          disabled
        ></TextField>
      </TextAreaWrapper>
    </>
  );
}

const TextAreaWrapper = styled(Box)`
  textarea {
    min-height: 77px;
    max-height: 77px;
  }
`;
