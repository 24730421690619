import { Table } from '@app/admin/src/components/Table';
import { Box } from '@mui/material';
import React from 'react';
import { T202EvaluationFragment } from '../../../../../graphql/types';
import { styled } from '@mui/system';

interface Props {
  data: T202EvaluationFragment;
}

export function T202ResultsTable2({ data }: Props) {
  return (
    <TableWrapper mt={2}>
      <Table
        items={[
          {
            id: 'table2',
            mistakesTwoLetterWords: data.mistakesTwoLetterWords,
            mistakesThreeLetterWords: data.mistakesThreeLetterWords,
            sten: data.sten
          }
        ]}
        columns={[
          {
            label: 'počet chýb',
            render: ({ mistakesTwoLetterWords, mistakesThreeLetterWords }) =>
              mistakesTwoLetterWords + mistakesThreeLetterWords
          },
          {
            label: 'jednoslabičné slová o dvoch písmenách',
            render: ({ mistakesTwoLetterWords }) => mistakesTwoLetterWords
          },
          {
            label: 'slová najmenej o troch písmenách',
            render: ({ mistakesThreeLetterWords }) => mistakesThreeLetterWords
          }
        ]}
      />
    </TableWrapper>
  );
}

const TableWrapper = styled(Box)`
  thead {
    background-color: #f8f8f8;
  }
`;
