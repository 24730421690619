import { Link } from '@everlutionsk/ui-router';
import { Box, styled } from '@mui/material';
import React from 'react';
import eporadentsvoLogo from './eporadenstvo-logo.png';
import vudpapLogo from './vudpap-logo.png';

interface Props {
  homePath?: any;
}

export function Logo({ homePath }: Props) {
  const alt = 'ePoradenstvo';

  return (
    <Box>
      <Box>
        <Link to={homePath || '/'}>
          <StyledImg src={eporadentsvoLogo} alt={alt} />
        </Link>
      </Box>
      <Box textAlign={'center'}>
        <a href="https://www.vudpap.sk" target="_blank" rel="noopener noreferrer">
          <StyledImg src={vudpapLogo} alt="https://www.vudpap.sk" />
        </a>
      </Box>
    </Box>
  );
}

const StyledImg = styled('img')`
  && {
    height: auto;
    max-width: 100%;
  }
`;
