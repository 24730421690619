import { gql, useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { T41EvaluationFragment } from '../../../graphql/types';
import { ClientTable } from './components/ClientTable';
import { T41ResultsTable } from './components/T41/T41ResultsTable';

export const t41EvaluationFragment = gql`
  fragment T41EvaluationFragment on T41Evaluation {
    duration
    oldSten
    resultPercentile
    testN: n
  }
`;

const answersQuery = gql<T41AnswersResultsGQL>`
  query T41AnswersResults($token: String!) {
    t41Diagnostics(token: $token) {
      id: originalToken
      answers {
        duration
        version
      }
    }
  }
`;

interface Props {
  token: string;
  client: {
    fullName: string;
    dateOfBirth: Date;
    gender: string;
    testingDate: Date;
  };
  data: T41EvaluationFragment;
}

export function T41Result({ token, client, data }: Props) {
  const { data: answersData } = useQuery(answersQuery, { variables: { token } });

  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Klient</Typography>
      </Box>
      <ClientTable
        data={{
          ...client,
          details: {
            t41Variant: prettifyVersion(answersData?.t41Diagnostics?.answers?.version),
            t41Time: answersData?.t41Diagnostics?.answers?.duration ?? data.duration ?? undefined
          }
        }}
      />
      <Box my={2}>
        <Typography variant="h4">Výsledky</Typography>
      </Box>
      {data.oldSten && <T41ResultsTable label="oldSten" value={data.oldSten} />}
      {answersData?.t41Diagnostics?.answers?.duration && (
        <T41ResultsTable label="Dosiahnutý výsledok (s):" value={data.duration} />
      )}
      {data.resultPercentile && (
        <T41ResultsTable label="Percentil:" value={data.resultPercentile} />
      )}
      {data.testN && <T41ResultsTable label="N" value={data.testN} />}
    </>
  );
}

function prettifyVersion(version?: string): string | undefined {
  if (version === 'childrenA') return 'Pre deti, Časť A';
  if (version === 'childrenB') return 'Pre deti, Časť B';
  if (version === 'adultsA') return 'Pre dospelých, Časť A';
  if (version === 'adultsB') return 'Pre dospelých, časť B';

  return undefined;
}
