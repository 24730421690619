import { Table } from '@app/admin/src/components/Table';
import { Box, styled } from '@mui/material';
import React from 'react';
import { T309EvaluationFragment } from '../../../../../graphql/types';

interface Props {
  data: T309EvaluationFragment;
}

export function T309ResultsTable({ data }: Props) {
  const lastItem = data.items[data.items.length - 1];

  return (
    <TableWrapper mt={2}>
      <Table
        items={data.items}
        rowKey={item => item.label}
        columns={[
          {
            label: 'Subtest',
            render: ({ label }) => label
          },
          {
            label: 'Hrubé skóre',
            render: ({ totalRoughScore }) => totalRoughScore
          },
          {
            label: 'Percentil',
            render: ({ percentile, label }) => {
              if (percentile === lastItem.percentile && label === lastItem.label) return '-';
              return percentile;
            }
          }
        ]}
      />
    </TableWrapper>
  );
}

const TableWrapper = styled(Box)`
  thead {
    background-color: #f8f8f8;
  }
`;
