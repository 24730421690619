import { Box, Typography } from '@mui/material';
import React from 'react';

const borderStyle = '1px solid #d6d6d6';

export function T41ResultsTable({ label, value }: { label: string; value: string | number }) {
  return (
    <Box display="flex" flexDirection="column" width="100%" boxShadow={2}>
      <Box display="flex" sx={{ borderBottom: borderStyle }}>
        <Box
          width="30%"
          textAlign="left"
          sx={{
            bgcolor: '#f8f8f8'
          }}
          p={1}
        >
          <Typography variant="body2">{label}</Typography>
        </Box>
        <Box width="70%" textAlign="right" sx={{ bgcolor: '#ffffff' }} p={1}>
          <Typography variant="body2"> {value}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
