import { Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { routing } from '../../Routes';

export function ToStudentOneNameLink(student: { id: string; fullName: string }) {
  if (student == null) return <></>;

  return (
    <Link to={routing.student.diagnostics(student.id)} component={RouterLink} color="secondary">
      {student.fullName}
    </Link>
  );
}
