import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routingPatterns } from '../../Routes';
import { AddStudent } from './AddStudent';
import { StudentsList } from './StudentsList';

export function Students() {
  return (
    <>
      <Routes>
        <Route path={routingPatterns.students.list} element={<StudentsList />} />
        <Route path={routingPatterns.students.add} element={<AddStudent />} />
      </Routes>
    </>
  );
}
