import { gql, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { useParams } from '@everlutionsk/ui-router';
import PrintIcon from '@mui/icons-material/Print';
import { Box, Grid, IconButton, styled } from '@mui/material';
import React from 'react';
import { ContentHeader } from '../../components/ContentHeader';
import { DisabledPrint } from '../../components/DisabledPrint';
import { routing } from '../../Routes';
import { defaultEvaluationFragment, DefaultResult } from './Result/DefaultResult';
import { otprEvaluationFragment, OtprResult } from './Result/OtprResult';
import { smkzdEvaluationFragment, SmkzdResult } from './Result/SmkzdResult';
import { t184EvaluationFragment, T184Result } from './Result/T184Result';
import { t202EvaluationFragment, T202Result } from './Result/T202Result';
import { t216EvaluationFragment, T216Result } from './Result/T216Result';
import { t21EvaluationFragment, T21Result } from './Result/T21Result';
import { t239EvaluationFragment, T239Result } from './Result/T239Result';
import { t252EvaluationFragment, T252Result } from './Result/T252Result';
import { t309EvaluationFragment, T309Result } from './Result/T309Result';
import { t41EvaluationFragment, T41Result } from './Result/T41Result';
import { t8EvaluationFragment, T8Result } from './Result/T8Result';
import { t95EvaluationFragment, T95Result } from './Result/T95Result';

export function DiagnosticResultOne() {
  const { id } = useParams(['id']);

  const { data, error } = useQuery(query, {
    variables: { id }
  });

  if (error) return <>Nemáte prístup k výsledkom testu.</>;

  if (data == null) return <Loading />;

  if (data?.testingOne.evaluation == null) return <></>;
  const typename = data.testingOne.evaluation.__typename;
  const { code } = data.testingOne;

  const client = {
    dateOfBirth: new Date(data.testingOne.student.dateOfBirth),
    fullName: data.testingOne.student.fullName ?? '',
    gender: data.testingOne.student.gender ?? '',
    testingDate: new Date(data.testingOne.testingDate ?? data.testingOne.createdAt)
  };

  return (
    <Wrapper mt={2}>
      <ContentHeader
        title={data.testingOne.label ?? 'Výsledok'}
        breadcrumbs={[
          { title: 'Testy', path: routing.diagnostics.list },
          { title: `Test ${code}`, path: routing.diagnostic.results(code) },
          { title: 'Výsledky testu', path: routing.diagnostic.results(code) },
          { title: 'Výsledok testovania' }
        ]}
      />
      <Box display="flex" justifyContent="flex-end">
        <DisabledPrint>
          <IconButton onClick={() => window.print()}>
            <PrintIcon style={{ fontSize: '25px' }} color="secondary" />
          </IconButton>
        </DisabledPrint>
      </Box>
      <Grid item xs={12} md={12} lg={12}>
        {typename === 'T8Evaluation' && (
          <T8Result token={data.testingOne.id} data={data.testingOne.evaluation} client={client} />
        )}
        {typename === 'T184Evaluation' && (
          <T184Result
            client={{
              ...client,
              details: {
                t184subject: data.testingOne.details.t184subject ?? '-'
              }
            }}
            data={data.testingOne.evaluation}
          />
        )}
        {typename === 'T21Evaluation' && (
          <T21Result data={data.testingOne.evaluation} client={client} />
        )}
        {typename === 'T216Evaluation' && (
          <T216Result
            token={data.testingOne.id}
            data={data.testingOne.evaluation}
            client={{
              ...client,
              details: {
                ratingArtEducation: data.testingOne.details.ratingArtEducation?.toString() ?? '-',
                ratingMaths: data.testingOne.details.ratingMaths?.toString() ?? '-',
                ratingSlovakLanguage:
                  data.testingOne.details.ratingSlovakLanguage?.toString() ?? '-',
                ratingTechnicalEducation:
                  data.testingOne.details.ratingTechnicalEducation?.toString() ?? '-'
              }
            }}
          />
        )}
        {typename === 'T252Evaluation' && (
          <T252Result data={data.testingOne.evaluation} client={client} />
        )}
        {typename === 'T41Evaluation' && (
          <T41Result data={data.testingOne.evaluation} client={client} token={data.testingOne.id} />
        )}
        {typename === 'T309Evaluation' && (
          <T309Result data={data.testingOne.evaluation} client={client} />
        )}
        {typename === 'T239Evaluation' && (
          <T239Result
            data={data.testingOne.evaluation}
            client={{
              ...client,
              details: {
                grade: data.testingOne.details.grade ?? undefined
              }
            }}
          />
        )}
        {typename === 'T202Evaluation' && (
          <T202Result
            data={data.testingOne.evaluation}
            client={{
              ...client,
              details: {
                fatherEducation: data.testingOne.details.fatherEducation ?? undefined,
                grade: data.testingOne.details.grade ?? undefined,
                motherEducation: data.testingOne.details.motherEducation ?? undefined,
                postponement: data.testingOne.details.postponement ?? undefined,
                ratingForeignLanguage: data.testingOne.details.ratingForeignLanguage ?? undefined,
                ratingMaths: data.testingOne.details.ratingMaths ?? undefined,
                ratingSlovakLanguage: data.testingOne.details.ratingSlovakLanguage ?? undefined,
                repeatedGrade: data.testingOne.details.repeatedGrade ?? undefined,
                readingEvaluation: data.testingOne.details.readingEvaluation ?? undefined
              }
            }}
          />
        )}
        {typename === 'SmkzdEvaluation' && (
          <SmkzdResult
            data={data.testingOne.evaluation}
            client={{
              ...client
            }}
          />
        )}
        {typename === 'T95Evaluation' && (
          <T95Result
            data={data.testingOne.evaluation}
            client={{
              ...client
            }}
          />
        )}
        {typename === 'DefaultEvaluation' && <DefaultResult data={data.testingOne.evaluation} />}
        {typename === 'OtprEvaluation' && (
          <OtprResult data={data.testingOne.evaluation} client={client} />
        )}
      </Grid>
    </Wrapper>
  );
}

export const query = gql<TestingOneEvaluationGQL>`
  query TestingOneEvaluation($id: ID!) {
    testingOne(id: $id) {
      id
      code
      createdAt
      originalToken
      label
      testingDate
      student {
        fullName
        dateOfBirth
        gender
      }
      details {
        ratingSlovakLanguage
        ratingMaths
        ratingTechnicalEducation
        ratingArtEducation
        grade
        postponement
        repeatedGrade
        ratingForeignLanguage
        motherEducation
        fatherEducation
        t184subject
        readingEvaluation
      }
      evaluation {
        ...T8EvaluationFragment
        ...T184EvaluationFragment
        ...T21EvaluationFragment
        ...T216EvaluationFragment
        ...T252EvaluationFragment
        ...T41EvaluationFragment
        ...T309EvaluationFragment
        ...T239EvaluationFragment
        ...T202EvaluationFragment
        ...T95EvaluationFragment
        ...SmkzdEvaluationFragment
        ...DefaultEvaluationFragment
        ...OtprEvaluationFragment
      }
    }
  }
  ${t8EvaluationFragment}
  ${t184EvaluationFragment}
  ${t21EvaluationFragment}
  ${t216EvaluationFragment}
  ${t252EvaluationFragment}
  ${t41EvaluationFragment}
  ${t309EvaluationFragment}
  ${t239EvaluationFragment}
  ${t202EvaluationFragment}
  ${smkzdEvaluationFragment}
  ${otprEvaluationFragment}
  ${t95EvaluationFragment}
  ${defaultEvaluationFragment}
`;

const Wrapper = styled(Box)`
  ::-webkit-scrollbar {
    display: 'none';
  }
`;
