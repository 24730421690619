import { OneOf } from '@everlutionsk/helpers';
import { Tab as MuiTab, TabProps as MuiTabProps, Tabs as MuiTabs } from '@mui/material';
import React, { ReactNode } from 'react';

export interface TabsProps<T> extends Omit<MuiTabProps, 'children' | 'onChange' | 'value'> {
  readonly tabs: ReadonlyArray<Tab<T> | false | undefined | null>;
  readonly value: T;
  readonly onChange: (value: T) => void;
}

export interface Tab<T> {
  readonly value: OneOf<T>;
  readonly label: ReactNode;
  readonly node: ReactNode;
}

/**
 * Material UI tabs with strongly typed values.
 */
export function Tabs<T>(props: TabsProps<T>) {
  const tabs = props.tabs.filter(isTab);
  const active = tabs.find(tab => tab.value === props.value);

  return (
    <div>
      <MuiTabs
        style={{ backgroundColor: '#fff', borderBottom: '1px solid #ddd' }}
        indicatorColor="primary"
        value={props.value}
        onChange={(_, value) => props.onChange(value)}
      >
        {tabs.map((tab, index) => (
          <MuiTab key={index} value={tab.value} label={tab.label} />
        ))}
      </MuiTabs>
      {active?.node}
    </div>
  );
}

function isTab<T>(value: T | null | undefined | false): value is T {
  return value != null && value !== false;
}
