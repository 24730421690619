import { gql, useQuery } from '@apollo/client';
import { call } from '@everlutionsk/helpers';
import { Button } from '@everlutionsk/ui';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { CheckboxField, SubmitButton, createFormSpec } from '@everlutionsk/ui-formik';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { Role } from '../../../graphql/types';
import { MultiAutocompleteField } from '../../components/MultiAutocompleteField';
import { useIdentity } from '../../../components/hooks/useIdentity';

export function EditPosition({ userId }: { userId: string }) {
  const identity = useIdentity();
  const { data, refetch } = useQuery(query, { variables: { id: userId } });
  const { data: groupOptions } = useQuery(optionQuery);

  const [update] = useFlashMutation(mutation, {
    successMsg:
      identity?.role === 'admin' || identity?.role === 'superAdmin'
        ? 'Žiadosť o zmenu pozície je schválená.'
        : 'Žiadosť o zmenu pozície úspešne odoslaná.',
    errorMsg: ''
  });

  const [reject] = useFlashMutation(rejectPositionMutation, {
    successMsg: 'Zmena pozícií bola uspešne zamietnutá',
    errorMsg: 'Nie je možné zamietnuť pozíciu'
  });

  const formSpec = useMemo(() => {
    return createFormSpec({
      position: yup.array(),
      role: yup.boolean()
    });
  }, []);

  if (data == null) {
    return (
      <>
        {[...new Array(3)].map((_, index) => (
          <Grid key={index} container>
            <Grid item xs={12}>
              <Skeleton height={40} />
            </Grid>
          </Grid>
        ))}
      </>
    );
  }

  const positions = call(() => {
    if (data.userOne.requestedPosition.length > 0) {
      return data.userOne.requestedPosition;
    }

    return data.userOne.position;
  });

  const isSuperAdmin = identity?.role === 'admin' || identity?.role === 'superAdmin';

  const currentIds = data.userOne.position.map(({ id }) => id) ?? [];
  const requestedIds = positions.map(({ id }) => id) ?? [];

  const currentRemoved = currentIds.filter(id => !requestedIds.includes(id));
  const requestedAdded = requestedIds.filter(id => !currentIds.includes(id));

  return (
    <>
      {data.userOne.position.length === 0 ? (
        <Box mb={1}>
          <Typography color="error" variant="body1">
            Pre pokračovanie je nutné vybrať aspoň jednu pozíciu!
          </Typography>
        </Box>
      ) : (
        <Box mb={1}>
          <Typography variant="body1">
            <b>Aktuálne pozície:</b>{' '}
          </Typography>
          {data.userOne.position.map(({ id, name }, index) => (
            <Box key={id} display="inline">
              <Typography display="inline">{index !== 0 ? ', ' : ' '}</Typography>
              <Typography
                display="inline"
                color={currentRemoved.includes(id) ? 'error' : undefined}
              >
                {name}
              </Typography>
            </Box>
          ))}
        </Box>
      )}

      <Formik
        enableReinitialize
        {...formSpec({
          position: positions.map(item => ({ value: item.id, label: item.name })),
          role: data.userOne.role === 'owner'
        })}
        onSubmit={async ({ position, role }) => {
          await update({
            variables: {
              userId,
              positionIds: position.map(item => item.value),
              role: isSuperAdmin ? (role ? Role.owner : Role.psychologist) : undefined
            }
          });

          refetch();
        }}
      >
        {({ values, initialValues }) => {
          const requestedPositions = JSON.stringify(
            values.position.map(item => item.value).sort((a, b) => a.localeCompare(b))
          );
          const currentPositions = JSON.stringify(
            data.userOne.position.map(item => item.id).sort((a, b) => a.localeCompare(b))
          );

          const disabled =
            requestedPositions === currentPositions && values.role === initialValues.role;

          return (
            <Form>
              <MultiAutocompleteField
                label="Zmena pozície*"
                name="position"
                options={groupOptions?.positionGroups.map(item => ({
                  value: item.value,
                  label: item.label
                }))}
                coloredValues={requestedAdded}
              />
              {isSuperAdmin && <CheckboxField name="role" label="Riadi a spravuje organizáciu" />}

              <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={1}>
                {!isSuperAdmin ? (
                  <SubmitButton disabled={disabled} loading={false}>
                    Uložiť
                  </SubmitButton>
                ) : (
                  <>
                    <SubmitButton disabled={disabled} loading={false}>
                      Schváliť
                    </SubmitButton>
                    <Button
                      disabled={disabled}
                      style={{ marginLeft: '10px' }}
                      variant="contained"
                      color="secondary"
                      onClick={async () => {
                        await reject({ variables: { userId } });
                      }}
                    >
                      Zamietnuť
                    </Button>
                  </>
                )}
              </Box>
            </Form>
          );
        }}
      </Formik>

      {data.userOne.requestedPosition.length > 0 && (
        <Typography variant="body1">Čaká na schválenie adminom.</Typography>
      )}
    </>
  );
}

const query = gql<UserEditPositionGQL>`
  query UserEditPosition($id: ID!) {
    userOne(id: $id) {
      id
      position {
        id
        name
      }
      requestedPosition {
        id
        name
      }
      role
    }
  }
`;

const optionQuery = gql<PositionGroupOptionsGQL>`
  query PositionGroupOptions {
    positionGroups {
      label
      value
    }
  }
`;

const mutation = gql<PositionChangeGQL>`
  mutation PositionChange($userId: ID!, $positionIds: [String!]!, $role: Role) {
    positionChange(userId: $userId, positionIds: $positionIds, role: $role) {
      id
      position {
        id
        name
      }
      requestedPosition {
        id
        name
      }
      role
    }
  }
`;

const rejectPositionMutation = gql<RejectPositionChangeUserPageGQL>`
  mutation RejectPositionChangeUserPage($userId: ID!) {
    rejectPositionChange(userId: $userId) {
      id
      fullName
      organisation {
        id
        name
      }
      position {
        id
        name
      }
      requestedPosition {
        id
        name
      }
      status
    }
  }
`;
