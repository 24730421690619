import { gql } from '@apollo/client';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { useNavigate } from '@everlutionsk/ui-router';
import React from 'react';
import { routing } from '../../Routes';
import { ContentHeader } from '../../components/ContentHeader';
import { FormWrapper } from '../../components/FormWrapper';
import { StudentStatus } from '../../graphql/types';
import { AddStudentForm } from './AddStudentForm';

export function AddStudent() {
  const navigate = useNavigate();

  const [add] = useFlashMutation(mutation, {
    successMsg: 'Žiak bol úspešne pridaný',
    errorMsg: 'Žiak nebol pridaný',
    onCompleted: data => navigate(routing.student.detail(data.addStudent.id)),
    refetchQueries: [
      {
        query,
        variables: {
          input: {
            status: StudentStatus.active,
            term: undefined,
            pagination: { limit: 20, offset: 0 }
          }
        }
      }
    ]
  });

  return (
    <>
      <ContentHeader
        title={'Nový žiak'}
        breadcrumbs={[
          { title: 'Domov' },
          { title: 'Žiaci', path: routing.students.list },
          { title: `Pridať žiaka` }
        ]}
      />
      <FormWrapper>
        <AddStudentForm
          returnUrl={routing.students.list}
          onSubmit={({
            lastName,
            firstName,
            gender,
            birthIdentificationNumber,
            dateOfBirth,
            school,
            line1,
            city,
            postCode,
            fullName,
            email,
            phoneNumber,
            language
          }) => {
            return add({
              variables: {
                input: {
                  firstName,
                  lastName,
                  gender,
                  birthIdentificationNumber,
                  dateOfBirth,
                  school,
                  line1,
                  city,
                  postCode,
                  fullName,
                  email,
                  phoneNumber,
                  language
                }
              }
            });
          }}
        />
      </FormWrapper>
    </>
  );
}

const mutation = gql<AddStudentMutationGQL>`
  mutation AddStudentMutation($input: AddStudentInput!) {
    addStudent(input: $input) {
      id
    }
  }
`;

const query = gql<StudentManyOnAddQueryGQL>`
  query StudentManyOnAddQuery($input: StudentFilterInput!) {
    newStudentMany(input: $input) {
      total
      items {
        id
        fullName
        gender
        school
        status
        owner {
          id
          fullName
        }
      }
    }
  }
`;
