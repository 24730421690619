import { gql, useQuery } from '@apollo/client';
import { AutocompleteField, Fields, SubmitButton, createFormSpec } from '@everlutionsk/ui-formik';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';

export function ReassignmentStudentDialog({
  open,
  onClose,
  onSubmit
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (ownerId: string) => Promise<void>;
}) {
  const [term, setTerm] = useState<string>('');

  const formSpec = useMemo(() => {
    return createFormSpec({ user: yup.mixed().required('Psychológ je povinný údaj.') });
  }, []);

  const { data: userAutocomplete } = useQuery(userAutocompleteQuery, {
    variables: { term },
    skip: !open
  });

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>Preradenie žiaka</DialogTitle>
      <Formik
        {...formSpec({ user: undefined })}
        onSubmit={async ({ user }) => {
          await onSubmit(user.value);
          onClose();
        }}
      >
        <Form>
          <DialogContent>
            <Fields>
              <AutocompleteField
                label="Nový psychológ"
                name="user"
                options={userAutocomplete?.userMany.items.map(user => ({
                  label: user.fullName,
                  value: user.id,
                  node: (
                    <Box py={1} display="flex" flexDirection="column">
                      <Typography>
                        <b>{user.fullName}</b> ({user.organisation?.name})
                      </Typography>
                      <Divider />
                      <Typography>{user.position.map(item => item.name).join(', ')}</Typography>
                    </Box>
                  )
                }))}
                onTermChange={setTerm}
              />
            </Fields>
          </DialogContent>

          <DialogActions>
            <SubmitButton>Uložiť</SubmitButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
}

const userAutocompleteQuery = gql<UserAutocompleteReassignmentGQL>`
  query UserAutocompleteReassignment($term: String) {
    userMany(input: { term: $term, pagination: { limit: 20, offset: 0 } }) {
      total
      items {
        id
        fullName
        organisation {
          id
          name
        }
        position {
          id
          name
        }
      }
    }
  }
`;
