import { Table } from '@app/admin/src/components/Table';
import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { T202EvaluationFragment } from '../../../../../graphql/types';

interface Props {
  data: T202EvaluationFragment;
}

export function T202ResultsTable4({ data }: Props) {
  return (
    <TableWrapper mt={2}>
      <Typography variant="h5">Lokalizácia</Typography>
      <Table
        items={[
          {
            id: 'table4',
            one: data.readingLocalizationOne,
            two: data.readingLocalizationTwo,
            three: data.readingLocalizationThree,
            four: data.readingLocalizationFour,
            five: data.readingLocalizationFive,
            six: data.readingLocalizationSix,
            seven: data.readingLocalizationSeven
          }
        ]}
        columns={[
          {
            label: 'A',
            render: ({ one }) => one
          },
          {
            label: 'B',
            render: ({ two }) => two
          },
          {
            label: 'C',
            render: ({ three }) => three
          },
          {
            label: 'konc. +',
            render: ({ four }) => four
          },
          {
            label: 'stred. +',
            render: ({ five }) => five
          },
          {
            label: 'zač. +',
            render: ({ six }) => six
          },
          {
            label: 'iné',
            render: ({ seven }) => seven
          }
        ]}
      />
    </TableWrapper>
  );
}

const TableWrapper = styled(Box)`
  thead {
    background-color: #f8f8f8;
  }
`;
