import { Table } from '@app/admin/src/components/Table';
import { Box, styled } from '@mui/material';
import React from 'react';

interface Props {
  label: string;
  value: string;
}

export function T202ResultsTable5({ label, value }: Props) {
  return (
    <TableWrapper mt={2}>
      <Table
        items={[
          {
            id: 'table5',
            value
          }
        ]}
        columns={[
          {
            label,
            render: ({ value }) => value
          }
        ]}
      />
    </TableWrapper>
  );
}

const TableWrapper = styled(Box)`
  thead {
    background-color: #f8f8f8;
  }
`;
