import { gql } from '@apollo/client';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { useNavigate, useParams } from '@everlutionsk/ui-router';
import React from 'react';
import { ContentHeader } from '../../../components/ContentHeader';
import { FormWrapper } from '../../../components/FormWrapper';
import { routing } from '../../../Routes';
import { AddStudentForm } from '../../Students/AddStudentForm';

export function AddStudent() {
  const { id } = useParams(['id']);
  const navigate = useNavigate();

  const [add] = useFlashMutation(mutation, {
    successMsg: 'Žiak bol úspešne pridaný',
    errorMsg: 'Žiak nebol pridaný',
    onCompleted: data => navigate(routing.student.detail(data.addStudent.id))
  });

  return (
    <>
      <ContentHeader title="Nový žiak" />
      <FormWrapper>
        <AddStudentForm
          returnUrl={routing.user.students(id)}
          onSubmit={({
            lastName,
            firstName,
            gender,
            birthIdentificationNumber,
            dateOfBirth,
            school,
            line1,
            city,
            postCode,
            fullName,
            email,
            phoneNumber,
            language
          }) => {
            add({
              variables: {
                input: {
                  firstName,
                  lastName,
                  gender,
                  birthIdentificationNumber,
                  dateOfBirth,
                  school,
                  line1,
                  city,
                  postCode,
                  fullName,
                  email,
                  phoneNumber,
                  userId: id,
                  language
                }
              },
              update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'studentMany' });
              }
            });
          }}
        />
      </FormWrapper>
    </>
  );
}

const mutation = gql<AddUserStudentMutationGQL>`
  mutation AddUserStudentMutation($input: AddStudentInput!) {
    addStudent(input: $input) {
      id
    }
  }
`;
