import { gql, useQuery } from '@apollo/client';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { Fields, SubmitButton, TextField, createFormSpec } from '@everlutionsk/ui-formik';
import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { ContentHeader } from '../components/ContentHeader';
import { FormWrapper } from '../components/FormWrapper';
import { useIdentity } from '../components/hooks/useIdentity';
import { Dictionary } from '@everlutionsk/ui-admin';

export function Organisation() {
  const identity = useIdentity();
  if (identity == null) throw Error('Access Denied! Missing valid identity.');

  const { data } = useQuery(query, { variables: { id: identity.id } });

  const [requestOrgName] = useFlashMutation(editOrganisationName, {
    successMsg: 'Žiadosť bola úspešne odoslaná.',
    errorMsg: 'Žiadosť sa nepodarilo odoslať.'
  });

  const formSpec = useMemo(() => {
    return createFormSpec({
      name: yup.string().nullable(),
      line1: yup.string().nullable(),
      city: yup.string().nullable(),
      region: yup.string().nullable(),
      district: yup.string().nullable()
    });
  }, []);

  return (
    <>
      <ContentHeader
        title="Upraviť organizáciu"
        breadcrumbs={[{ title: 'Domov' }, { title: 'Upraviť organizáciu' }]}
      />
      <Dictionary>
        {[
          ['Názov organizácie', data?.userOne.organisation?.name],
          ['Mesto', data?.userOne.organisation?.city],
          ['Ulica', data?.userOne.organisation?.line1],
          ['Kraj', data?.userOne.organisation?.region],
          ['Okres', data?.userOne.organisation?.district]
        ]}
      </Dictionary>
      <FormWrapper>
        <Formik
          enableReinitialize
          {...formSpec({
            name: data?.userOne.organisation?.requestedChanges?.name ?? '',
            city: data?.userOne.organisation?.requestedChanges?.city ?? '',
            line1: data?.userOne.organisation?.requestedChanges?.line1 ?? '',
            region: data?.userOne.organisation?.requestedChanges?.region ?? '',
            district: data?.userOne.organisation?.requestedChanges?.district ?? ''
          })}
          onSubmit={({ name, line1, city, region, district }) => {
            if (data?.userOne.organisation?.id == null) return;

            requestOrgName({
              variables: {
                input: {
                  id: data.userOne.organisation.id,
                  name,
                  city,
                  district,
                  line1,
                  region
                }
              }
            });
          }}
        >
          <Form>
            <Fields>
              <TextField name="name" label="Názov organizácie*" />
              <TextField name="city" label="Mesto*" />
              <TextField name="line1" label="Ulica*" />
              <TextField name="region" label="Kraj*" />
              <TextField name="district" label="Okres*" />
            </Fields>

            <Box
              my={3}
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              alignItems="center"
            >
              {data?.userOne.organisation?.requestedChanges != null && (
                <Typography variant="body1">Čaká na schválenie adminom.</Typography>
              )}
              <SubmitButton
                disabled={data?.userOne.organisation?.requestedChanges != null}
                loading={false}
              >
                Vyžiadať zmeny
              </SubmitButton>
            </Box>
          </Form>
        </Formik>
      </FormWrapper>
    </>
  );
}

const query = gql<OwnerOrganisationOneGQL>`
  query OwnerOrganisationOne($id: ID!) {
    userOne(id: $id) {
      organisation {
        id
        name
        line1
        city
        region
        district
        requestedChanges {
          name
          line1
          city
          region
          district
        }
      }
    }
  }
`;

const editOrganisationName = gql<RequestOrganisationNameMyProfileGQL>`
  mutation RequestOrganisationNameMyProfile($input: RequestedOrganisationChangesInput!) {
    requestOrganisationName(input: $input) {
      id
      organisation {
        id
        name
        line1
        city
        region
        district
        requestedChanges {
          name
          line1
          city
          region
          district
        }
      }
    }
  }
`;
