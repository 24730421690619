import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { T239EvaluationFragment } from '../../../graphql/types';
import { ClientTable } from './components/ClientTable';
import { T239ResultsTable } from './components/T239/T239ResultsTable';

export const t239EvaluationFragment = gql`
  fragment T239EvaluationFragment on T239Evaluation {
    ad
    aa
    aas
    vd
    verticalSymetryErrors
    identityErrors
    otherSymetryErrors
    plo
    amk
    m
    stenItems {
      label
      value
    }
    percentileItems {
      label
      value
    }
    answerItems {
      label
      value
    }
    conclusion
  }
`;

interface Props {
  client: {
    fullName: string;
    dateOfBirth: Date;
    gender: string;
    testingDate: Date;
    details: { grade: number | undefined };
  };
  data: T239EvaluationFragment;
}

export function T239Result({ client, data }: Props) {
  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Klient</Typography>
      </Box>
      <ClientTable data={client} />
      <Box mt={2}>
        <Typography variant="h4">Výsledky</Typography>
      </Box>
      <T239ResultsTable
        label="Individuálna úspešnosť"
        data={[
          { label: 'Sluchová diferenciácia', value: data.ad },
          { label: 'Sluchová analýza', value: data.aa },
          { label: 'Sluchová syntéza', value: data.aas },
          { label: 'Vizuálna diferenciácia', value: data.vd },
          { label: '- vertikálna symetria (Σ chýb)', value: data.verticalSymetryErrors },
          { label: '- identita (Σ chýb)', value: data.identityErrors },
          { label: '- ostatné symetrie (Σ chýb)', value: data.otherSymetryErrors },
          { label: 'Vizuálna orientácia v priestore', value: data.plo },
          { label: 'Audiomotorická koordinácia', value: data.amk },
          { label: 'Jemná motorika', value: data.m }
        ]}
      />
      <T239ResultsTable
        label="Sten"
        data={data.stenItems.map(({ label, value }) => ({ label, value: value ?? '' }))}
      />
      <T239ResultsTable
        label="Percentil"
        data={data.percentileItems.map(({ label, value }) => ({ label, value: value ?? '' }))}
      />

      <Box mt={2}>
        <Typography variant="h6">Čiastkový záver</Typography>
      </Box>

      {data.answerItems.map(item => (
        <Box key={item.label} mb={2}>
          <Box>
            <Typography variant="body2">
              <b>{item.label}</b>
            </Typography>
            <Typography variant="body2">{item.value}</Typography>
          </Box>
        </Box>
      ))}
    </>
  );
}
