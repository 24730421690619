import { Box, Grid, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import { BreadcrumbItem, Breadcrumbs } from './Breadcrumbs';

interface Props {
  readonly title?: string;
  readonly breadcrumbs?: BreadcrumbItem[];
  readonly mainAction?: ReactNode;
}

export function ContentHeader({ breadcrumbs, title, mainAction }: Props) {
  return (
    <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
      <Grid item>
        <UpperCase my={1}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </UpperCase>
        <StyledTitle>{title}</StyledTitle>
      </Grid>
      <Grid item>
        <Action>{mainAction && mainAction}</Action>
      </Grid>
    </Grid>
  );
}

export const StyledTitle = styled('div')`
  font-weight: 400;
  font-size: 34px;
  margin-top: -5px;
  margin-bottom: 24px;
`;

const UpperCase = styled(Box)`
  text-transform: uppercase;
`;

const Action = styled('div')`
  display: flex;
  flex-direction: row;
`;
