import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

interface Props {
  data: {
    fullName: string;
    dateOfBirth: Date;
    gender: string;
    testingDate: Date;
    details?: {
      grade?: number;
      ratingSlovakLanguage?: string;
      ratingMaths?: string;
      ratingTechnicalEducation?: string;
      ratingArtEducation?: string;
      t184subject?: string;
      t41Variant?: string;
      t41Time?: number;
    };
  };
}

const borderStyle = '1px solid #d6d6d6';

export function ClientTable({ data }: Props) {
  return (
    <Box display="flex" flexDirection="column" width="100%" boxShadow={2}>
      <Row label="Identifikátor klienta:" value={data.fullName} />
      <Row label="Narodený:" value={formatToDateOfBirth(data.dateOfBirth)} />
      <Row label="Pohlavie:" value={data.gender === 'male' ? 'Muž' : 'Žena'} />
      {data.details?.grade != null && <Row label="Ročník" value={data.details.grade} />}
      <Row label="Dátum testovania:" value={formatDate(data.testingDate)} />
      {data.details?.ratingSlovakLanguage != null && (
        <Row
          label="Slovenský jazyk"
          value={
            data.details.ratingSlovakLanguage === '0' ? 'neviem' : data.details.ratingSlovakLanguage
          }
        />
      )}
      {data.details?.ratingMaths != null && (
        <Row
          label="Matematika"
          value={data.details.ratingMaths === '0' ? 'neviem' : data.details.ratingMaths}
        />
      )}
      {data.details?.ratingTechnicalEducation != null && (
        <Row
          label="Pracovné vyučovanie"
          value={
            data.details.ratingTechnicalEducation === '0'
              ? 'neviem'
              : data.details.ratingTechnicalEducation
          }
        />
      )}
      {data.details?.ratingArtEducation != null && (
        <Row
          label="Výtvarná výchova"
          value={
            data.details.ratingArtEducation === '0' ? 'neviem' : data.details.ratingArtEducation
          }
        />
      )}
      {data.details?.t41Variant != null && (
        <Row label="Verzia testu:" value={data.details.t41Variant} />
      )}
      {data.details?.t184subject != null && (
        <Row label="Vyučovací predmet" value={data.details.t184subject} />
      )}
    </Box>
  );
}

function Row({ label, value }: { label: string; value: string | number }) {
  return (
    <Box>
      <Box display="flex" sx={{ borderBottom: borderStyle }}>
        <Box
          width="30%"
          textAlign="left"
          sx={{
            bgcolor: '#f8f8f8'
          }}
          p={1}
        >
          <Typography variant="body2">{label}</Typography>
        </Box>
        <Box width="70%" textAlign="right" sx={{ bgcolor: '#ffffff' }} p={1}>
          <Typography variant="body2"> {value}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

function formatToDateOfBirth(value: Date) {
  return format(value, 'LLLL yyyy');
}

function formatDate(value: Date) {
  return format(value, 'dd. MM. yyyy');
}
