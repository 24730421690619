import { gql } from '@apollo/client';
import { useFlashMutation, useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { AutocompleteField, Fields, createFormSpec } from '@everlutionsk/ui-formik';
import { useParams } from '@everlutionsk/ui-router';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { routing } from '../../../Routes';
import { ContentHeader } from '../../../components/ContentHeader';
import { FormButton } from '../../../components/FormButton';
import { FormWrapper } from '../../../components/FormWrapper';
import { StudentStatus } from '../../../graphql/types';
import { openInNewTab } from '../../../helpers';
import { toDiagnosticUrl } from '../utils';

export function IndividualForm() {
  const { code } = useParams(['code']);
  const [term, setTerm] = useState('');

  const { data } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      input: { pagination: { limit, offset }, term, status: StudentStatus.active }
    })
  });

  const [start] = useFlashMutation(mutation, {
    successMsg: 'Test bol spustený',
    errorMsg: 'Test nebol spustený',
    onCompleted: testing => {
      openInNewTab(toDiagnosticUrl(testing.individualTesting.id, code));
    }
  });

  const options = data?.newStudentMany.items.map(value => ({
    label: value.fullName,
    value: value.id
  }));

  return (
    <>
      <ContentHeader
        title={'Individuálne testovanie'}
        breadcrumbs={[
          { title: 'Testy', path: routing.diagnostics.list },
          { title: `Test ${code}`, path: routing.diagnostic.prepare(code) },
          { title: 'Testovať', path: routing.diagnostic.prepare(code) },
          { title: 'Individuálne testovanie' }
        ]}
      />
      <FormWrapper>
        <Formik
          {...formSpec({ student: null })}
          onSubmit={({ student }) =>
            start({ variables: { input: { code, studentId: student.value } } })
          }
        >
          <Form>
            <Fields>
              <AutocompleteField
                label="Žiak"
                name="student"
                options={options}
                onTermChange={setTerm}
              />
            </Fields>

            <Box my={3}>
              <FormButton label="Spustiť test" />
            </Box>
          </Form>
        </Formik>
      </FormWrapper>
    </>
  );
}

const formSpec = createFormSpec({
  student: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string()
    })
    .nullable()
    .required(`Meno žiaka je povinný údaj`)
});

const mutation = gql<IndividualTestingMutationGQL>`
  mutation IndividualTestingMutation($input: TestingInput!) {
    individualTesting(input: $input) {
      id
    }
  }
`;

export const query = gql<IndividualStudentQueryGQL>`
  query IndividualStudentQuery($input: StudentFilterInput!) {
    newStudentMany(input: $input) {
      total
      items {
        id
        fullName
        status
      }
    }
  }
`;
