import { Table } from '@app/admin/src/components/Table';
import { Box, styled } from '@mui/material';
import React from 'react';

interface Props {
  version: string;
  time?: number;
  score?: number;
  percentile?: number;
  degree?: string;
}

export function T8ResultsTable({ version, time, degree, percentile, score }: Props) {
  return (
    <TableWrapper mt={2}>
      <Table
        items={[{ id: version, time, degree, percentile, score }]}
        columns={[
          {
            label: 'Skóre',
            render: ({ score }) => score ?? '-'
          },
          {
            label: 'Percentil',
            render: ({ percentile }) => percentile ?? '-'
          },
          {
            label: 'Stupeň',
            render: ({ degree }) => degree ?? '-'
          }
        ]}
      />
    </TableWrapper>
  );
}

const TableWrapper = styled(Box)`
  thead {
    background-color: #f8f8f8;
  }
`;
