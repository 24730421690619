import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routingPatterns } from '../../Routes';
import { AddOrganisation } from './AddOrganisation';
import { EditOrganisation } from './EditOrganisation';
import { OrganisationsList } from './OrganisationsList';
import { AcceptOrganisationRequest } from './AcceptOrganisationRequest';

export function Organisations() {
  return (
    <>
      <Routes>
        <Route path={routingPatterns.organisations.list} element={<OrganisationsList />} />
        <Route path={routingPatterns.organisations.add} element={<AddOrganisation />} />
        <Route path={routingPatterns.organisations.edit} element={<EditOrganisation />} />
        <Route path="requested-changes/:id" element={<AcceptOrganisationRequest />} />
      </Routes>
    </>
  );
}
