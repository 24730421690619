import { Typography } from '@mui/material';
import React from 'react';

export function OrganisationRequest() {
  return (
    <Typography>
      Pre úspešné spárovanie konta s organizácou je potrebné schválenie adminom. O procese
      spracovania Vašej požiadavky Vás budeme kontaktovať e-mailom.
    </Typography>
  );
}
