import { createAppRouting, prefixRouting as prefix } from '@everlutionsk/ui-router';

export const routing = createAppRouting(() => ({
  dashboard: '',
  // auth: prefix('auth', {
  //   signIn: 'sign-in',
  //   invitation: (token: string) => `invitation/${token}`,
  //   forgottenPassword: 'reset-password',
  //   createAccount: 'create-account'
  // }),
  students: prefix('students', {
    list: '',
    add: 'add'
  }),
  student: prefix('student', {
    detail: id => `${id}/detail`,
    diagnosis: id => `${id}/diagnosis`,
    diagnostics: id => `${id}/diagnostics`
  }),
  users: prefix('users', {
    list: '',
    add: 'add'
  }),
  diagnostics: prefix('diagnostics', {
    list: ''
  }),
  diagnostic: prefix('diagnostic', {
    prepare: (code, form = '') => `${code}/prepare/${form}`,
    prepareGroup: (code, type = '') => `${code}/prepare/group/${type}`,
    sendIndividual: (code, id) => `${code}/send/individual/${id}`,
    sendGroup: (code, id) => `${code}/send/group/${id}`,
    results: code => `${code}/results`,
    result: (code, id) => `${code}/result/${id}`
  }),
  user: prefix('user', {
    profile: userId => `${userId}/profile`,
    changeData: userId => `${userId}/change-data`,
    students: userId => `${userId}/students`,
    addStudent: userId => `${userId}/students/add`
  }),
  organisations: prefix('organisations', {
    list: '',
    add: 'add',
    edit: organisationId => `edit/${organisationId}`
  }),
  testResults: prefix('test-results', {
    list: '',
    detail: id => id
  })
}));

export const routingPatterns = createAppRouting(() => ({
  dashboard: '',
  // auth: {
  //   pattern: 'auth/*',
  //   signIn: 'sign-in',
  //   invitation: 'invitation/:token',
  //   forgottenPassword: 'reset-password',
  //   createAccount: 'create-account',
  //   resetPasswordConfirmation: 'reset-password-new/:token'
  // },
  students: {
    pattern: 'students/*',
    list: '',
    add: 'add'
  },
  student: {
    pattern: 'student/:id/*',
    detail: 'detail',
    diagnosis: 'diagnosis',
    diagnostics: 'diagnostics'
  },
  users: {
    pattern: 'users/*',
    list: '',
    add: 'add'
  },
  diagnostics: {
    pattern: 'diagnostics/*',
    list: ''
  },
  diagnostic: {
    pattern: 'diagnostic/:code/*',
    prepare: {
      pattern: 'prepare/*',
      all: '',
      individual: 'individual',
      remote: 'remote',
      group: {
        pattern: 'group/*',
        overview: '',
        select: 'select',
        new: 'new'
      }
    },
    send: {
      pattern: 'send/*',
      individual: 'individual/:id',
      group: 'group/:id'
    },
    results: 'results',
    result: 'result/:id'
  },
  user: {
    pattern: 'user/:id/*',
    profile: 'profile',
    students: 'students',
    addStudent: 'students/add',
    changeData: 'change-data'
  },
  organisations: {
    pattern: 'organisations/*',
    list: '',
    add: 'add',
    edit: 'edit/:id'
  },
  testResults: {
    pattern: 'test-results/*',
    list: '',
    detail: ':id'
  }
}));
