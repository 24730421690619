import { projection } from '@everlutionsk/helpers';
import { UserStatus } from './graphql/types';

export const openInNewTab = url => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const prettifyStatus = projection({
  [UserStatus.active]: 'Aktívny',
  [UserStatus.inactive]: 'Neaktívny',
  [UserStatus.requested]: 'Žiadosť',
  [UserStatus.deleted]: 'Vymazaný',
  [UserStatus.onHold]: 'Pozastavený',
  [UserStatus.rejected]: 'Zamietnutý'
});

export const testCodes = [
  'T-8',
  'T-21',
  'T-252',
  'T-309',
  'T-239',
  'T-216',
  'T-184',
  'T-202',
  'T-41',
  'T-95',
  'SMKZD',
  'OTPR'
];

export const groupTestCodes = [''];
export const remoteTestCodes = [''];

export const videoList = [
  {
    section: '1',
    label: '1. Statický vs. dynamický prístup v diagnostike',
    description:
      'Videometodika charakterizuje a porovnáva statický a dynamický prístup v diagnostike. Poukazuje na odlišnosti medzi oboma prístupmi, na rozdielne situácie kedy je vhodné statický alebo dynamický prístup zvoliť a aká je úloha examinátora pri zvolenom prístupe.',
    videos: [{ label: 'Statický vs. dynamický prístup v diagnostike', path: '/01/01.m3u8' }]
  },
  {
    section: '2',
    label: '2. Školská spôsobilosť',
    description:
      'Videometodika ozrejmuje základné pojmy súvisiace so školskou spôsobilosťou, charakterizuje dané vývinové obdobie, popisuje proces skupinovej depistáže iindividuálne vyšetrenie školskej spôsobilosti. Odporúča vhodné skríningové a diagnostické nástroje a interpretuje možné závery.',
    videos: [{ label: 'Školská spôsobilosť', path: '/02/01.m3u8' }]
  },
  {
    section: '3',
    label: '3. Diagnostika detí zo SZP',
    description:
      'Videometodika poukazuje na špecifiká diagnostiky detí zo SZP, opisuje bariéry vstupujúce do diagnostického procesu, odporúča vhodné diagnostické nástroje a upozorňuje na okolnosti, ktoré je potrebné zohľadňovať pri formulácii diagnostických záverov a interpretácii výsledkov.',
    videos: [{ label: 'Diagnostika detí zo SZP', path: '/03/01.m3u8' }]
  },
  {
    section: '4',
    label: '4. Vývinové poruchy učenia',
    description:
      'Videometodika ponúka základný prehľad a charakteristiku vývinových porúch učenia. Zameriava sa na vedenie diagnostického procesu s dôrazom na interpretáciu výsledkov. Poukazuje na dôležitosť multidisciplinárneho prístupu v diagnostike vývinových porúch učenia, špeciálne na kooperáciu psychológa, špeciálneho pedagóga príp. logopéda v diagnostickom procese.',
    videos: [{ label: 'Vývinové poruchy učenia', path: '/04/01.m3u8' }]
  },
  {
    section: '5',
    label: '5. Dieťa s rizikovým vývinom',
    description:
      'Videometodika ozrejmuje princípy diagnostiky a odbornej činnosti s dieťaťom s rizikovým vývinom, pričom veľký dôraz je kladený na prácu s celou rodinou. V rámci diagnostického procesu vysvetľuje opodstatnenosť dynamického prístupu a potrebu multidisciplinárnej spolupráce. Poukazuje na súvislosť výchovných štýlov uplatňovaných v rodine a rizikový vývin dieťaťa.',
    videos: [{ label: 'Dieťa s rizikovým vývinom', path: '/05/01.m3u8' }]
  },
  {
    section: '6',
    label: '6. Diagnostika osobnosti dieťaťa',
    description:
      'Videometodika sa zaoberá možnosťami ako uchopiť diagnostiku osobnosti, charakterizuje indikátory pre diagnostiku osobnosti a ozrejmuje kľúčové momenty pre tvorbu predbežnej hypotézy a následný výber diagnostických nástrojov s dôrazom na projektívne techniky.',
    videos: [{ label: 'Diagnostika osobnosti dieťaťa', path: '/06/01.m3u8' }]
  },
  {
    section: '7',
    label: '7. Správa z diagnostického vyšetrenia',
    description:
      'Videometodika popisuje náležitosti, ktoré má obsahovať správa z diagnostického vyšetrenia, upozorňuje na úskalia pri písaní správ, na korektnú interpretáciu výsledkov a formuláciu odporúčaní a záverov. Ozrejmuje kedy je potrebné zvolať konzílium a kto je koordinátorom klienta a aká je jeho úloha v procese diagnostiky.',
    videos: [{ label: 'Správa z diagnostického vyšetrenia', path: '/07/01.m3u8' }]
  },
  {
    section: '8',
    label: '8. Prípadová štúdia dieťaťa s narušenou komunikačnou schopnosťou',
    description:
      'Prípadová štúdia obsahuje časozberné video z odborných intervencií dieťaťa s NKS zaznamenaných počas jedného kalendárneho roka. Dokumentuje reálne intervenčné situácie, mapuje postupný a reálny progres dieťaťa.',
    videos: [
      { label: 'EMA 1', path: '/08/01.m3u8' },
      { label: 'EMA 2', path: '/08/02.m3u8' },
      { label: 'EMA 3', path: '/08/03.m3u8' },
      { label: 'EMA 4', path: '/08/04.m3u8' },
      { label: 'EMA 5', path: '/08/05.m3u8' },
      { label: 'EMA 6', path: '/08/06.m3u8' },
      { label: 'EMA 7', path: '/08/07.m3u8' },
      { label: 'EMA 8', path: '/08/08.m3u8' },
      { label: 'EMA 9', path: '/08/09.m3u8' },
      { label: 'EMA 10', path: '/08/10.m3u8' },
      { label: 'EMA 11', path: '/08/11.m3u8' },
      { label: 'EMA 12', path: '/08/12.m3u8' },
      { label: 'EMA 13', path: '/08/13.m3u8' },
      { label: 'EMA 14 S MAMOU DOMA NA DVORE', path: '/08/14.m3u8' },
      { label: 'Ema po roku', path: '/08/21.m3u8' },
      { label: 'Ema - logopedické zhrnutie', path: '/08/20.m3u8' },
      {
        label:
          'Videometodika multidisciplinárnej podpory dieťaťa s narušenou komunikačnou schopnosťou č. 1',
        path: '/08/15.m3u8'
      },
      {
        label:
          'Videometodika multidisciplinárnej podpory dieťaťa s narušenou komunikačnou schopnosťou č. 2',
        path: '/08/16.m3u8'
      },
      {
        label:
          'Videometodika multidisciplinárnej podpory dieťaťa s narušenou komunikačnou schopnosťou č. 3',
        path: '/08/17.m3u8'
      },
      {
        label:
          'Videometodika multidisciplinárnej podpory dieťaťa s narušenou komunikačnou schopnosťou č. 4',
        path: '/08/18.m3u8'
      },
      {
        label:
          'Videometodika multidisciplinárnej podpory dieťaťa s narušenou komunikačnou schopnosťou č. 5',
        path: '/08/19.m3u8'
      }
    ]
  },
  {
    section: '9',
    label: '9. Prípadová štúdia dieťaťa s vývinovou jazykovou poruchou (vývinovou dysfáziou)',
    description:
      'Prípadová štúdia ilustruje multidisciplinárny prístup v starostlivosti o dieťa s vývinovou jazykovou poruchou. Odborní zamestnanci multidisciplinárneho tímu opisujú vlastnú intervenčnú činnosť s dieťaťom, vzájomnú kooperáciu a reálny priebeh odbornej starostlivosti o dieťa v Detskom centre pre vedu a výskum pri VÚDPaP.',
    videos: [
      { label: 'Prípadová štúdia dieťaťa s vývinovou jazykovou poruchou', path: '/09/01.m3u8' }
    ]
  },
  {
    section: '10',
    label: '10. Prípadová štúdia dieťaťa s extrémne nízkou pôrodnou váhou',
    description:
      'Prípadová štúdia ilustruje multidisciplinárny prístup v starostlivosti o dieťa s extrémne nízkou pôrodnou váhou. Odborní zamestnanci multidisciplinárneho tímu opisujú vlastnú intervenčnú činnosť s dieťaťom, vzájomnú kooperáciu a reálny priebeh odbornej starostlivosti o dieťa v Detskom centre pre vedu a výskum pri VÚDPaP.',
    videos: [
      {
        label: 'Prípadová štúdia dieťaťa s extrémne nízkou pôrodnou váhou',
        path: '/10/01.m3u8'
      }
    ]
  }
];
